import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";

// Redux Actions
import { clearAuthError, signup } from "../../actions/auth";

// Form
import { useForm, ErrorMessage } from "react-hook-form";

function Signup(props) {
  let { zipcode } = useParams();

  // Loading animation
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  // Get history object for passing as a prop for action redirects
  let history = useHistory();

  if (props.auth.authenticated) {
    history.push("/app/order");
  }

  const dispatch = useDispatch();

  const [showZipForm, setShowZipForm] = useState(true);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [zipAvailable, setZipAvailable] = useState(null);
  const [validZips, setValidZips] = useState([]);

  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      zip: zipcode,
    },
  });

  const zip = watch("zip");

  useEffect(() => {
    if (props.zips && props.zips.zips) {
      setValidZips(props.zips.zips.flatMap((zip) => zip.zip));
    }
  }, [props.zips]);

  useEffect(() => {
    if (zip && zip.toString().length === 5) {
      // Check if zip is in area

      // let availableZips = [
      //   "10034",
      //   "10040",
      //   "10033",
      //   "10032",
      //   "10039",
      //   "10031",
      //   "10030",
      //   "10037",
      //   "10027",
      //   "10026",
      //   "10029",
      //   "10025",
      //   "10128",
      //   "10024",
      //   "10028",
      //   "10075",
      //   "10021",
      //   "10023",
      //   "10065",
      //   "10022",
      //   "10019",
      //   "10020",
      //   "10036",
      //   "10017",
      //   "10018",
      //   "10016",
      //   "10001",
      //   "10010",
      //   "10003",
      //   "10009",
      //   "10014",
      //   "10012",
      //   "10002",
      //   "10013",
      //   "10282",
      //   "10007",
      //   "10038",
      //   "10006",
      //   "10280",
      //   "10005",
      //   "10004",
      //   "10463",
      //   "10468",
      //   "10471",
      //   "10454",
      //   "11201",
      //   "11231",
      //   "11215",
      //   "11218",
      //   "11217",
      //   "11211",
      //   "11206",
      //   "11222",
      //   "11225",
      //   "11226",
      //   "11235",
      //   "11229",
      //   "11223",
      //   "11224",
      //   "11230",
      //   "11210",
      //   "10035",
      // ];

      if (validZips.includes(zip.toString())) {
        setZipAvailable(true);
        setShowZipForm(false);
        setShowSignupForm(true);
      } else {
        setZipAvailable(false);
      }
    }
  }, [zip, validZips]);

  // Dispatch action
  const onSubmit = (formData) => {
    dispatch(signup(formData, () => history.push("/app/order")));
  };

  const clearError = () => {
    dispatch(clearAuthError());
  };

  return (
    <div
      id="signup"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <form id="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <h2>Sign up for an account.</h2>

        {zipAvailable === false && (
          <h3>Sorry, we're not available in your area yet.</h3>
        )}

        {showZipForm && (
          <>
            <h3>What is your zip code?</h3>
            <div className="form-line">
              <input
                type="tel"
                maxLength="5"
                name="zip"
                placeholder="Zip Code"
                ref={register({
                  required: "Please enter your email zip code.",
                })}
              />
              <ErrorMessage errors={errors} name="zip">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
          </>
        )}

        {showSignupForm && (
          <>
            <h3>
              We're available in your area!
              <br />
              Sign up below.
            </h3>
            <div className="form-line">
              <input
                type="text"
                name="email"
                placeholder="Email"
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Please enter a valid email address.",
                  },
                })}
              />
              <ErrorMessage errors={errors} name="email">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>

            {props.auth.error && <p>{props.auth.error}</p>}
            <div className="form-line">
              <div className="form-input">
                <input
                  placeholder="New password"
                  type="password"
                  name="password"
                  ref={register}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="form-input">
                <input
                  placeholder="Confirm new password"
                  type="password"
                  name="password_confirm"
                  ref={register({
                    validate: (value) => {
                      return value === watch("password");
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="password_confirm">
                  {() => (
                    <p className="login-form-error-message">
                      Passwords must match.
                    </p>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value="Sign Up"
              />
            </div>
          </>
        )}

        <div className="login-container">
          <Link to="/login" onClick={clearError}>
            Already have an account? Log in
          </Link>
        </div>

        <div className="login-container">
          <Link to="/app/order/address" onClick={clearError}>
            Continue as a Guest
          </Link>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    zips: state.zips,
  };
};

export default connect(mapStateToProps)(Signup);
