import React from "react";

import "./LocationsImages.scss";

export default function LocationsImages() {
  return (
    <div id="locations-images">
      <div className="flex-container">
        <div
          className="location-image"
          style={{ backgroundImage: `url('/images/location-1.jpg'})` }}
        ></div>
        <div
          className="location-image"
          style={{ backgroundImage: `url('/images/location-2.jpg')` }}
        ></div>
        <div
          className="location-image"
          style={{ backgroundImage: `url('/images/location-3.jpg'})` }}
        ></div>
      </div>
    </div>
  );
}
