

import React from "react";
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addTailoring } from "../../../actions/settings";


function NewTailoringForm(props) {


    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(addTailoring(formData, () => {
            props.close();
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="product tailoring-product new">

                <div>
                    <input type="text" name="item" ref={register} placeholder="Item" />

                    <p style={{ fontSize: "12px" }}>Leave line blank if service is not available for the new item.</p>
                </div>


                <div className="tailoring-options">


                    <div className="tailoring-option">

                        <input type="text" name="service.hemprice" ref={register} placeholder="Hem Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.taperprice" ref={register} placeholder="Taper Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.zipperprice" ref={register} placeholder="Zipper Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.liningprice" ref={register} placeholder="Lining Price" />
                    </div>


                    <div className="tailoring-option">

                        <input type="text" name="service.sleevesprice" ref={register} placeholder="Sleeves Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.waistprice" ref={register} placeholder="Waist Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.patchprice" ref={register} placeholder="Patch Price" />
                    </div>

                    <div className="tailoring-option">

                        <input type="text" name="service.fixprice" ref={register} placeholder="Fix Price" />
                    </div>

                </div>

                <input className="add submit-button" type="submit" value="Add" />
            </div>


        </form>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(NewTailoringForm);