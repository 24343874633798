import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { refundPayment } from '../../../actions/payment';

// Form
import { useForm } from 'react-hook-form';


function RefundForm(props) {

    const dispatch = useDispatch();

    const [error, setError] = useState(null);

    const { handleSubmit, control, setValue, register } = useForm({
        mode: "onChange"
    });


    // Dispatch action 
    const onSubmit = (formData) => {


        if (formData.amount > props.order.finalOrderTotalPrice) {

            setError("Error: You are attempting to refund more than the amount paid.")


        } else {

            setError(null);
            formData.orderId = props.order._id;
            formData.paymentIntentId = props.order.stripePaymentIntentId;
            dispatch(refundPayment(formData, () => {
                console.log("done");
            }));
        }
    }

    return (
        <div className="order-detail-form-container">

            {error &&
                <p>{error}</p>
            }


            <form className="refund-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <div className="form-input flex-container">
                        <label className="label" htmlFor="amount">Amount to Refund:</label>
                        <span>$</span><input name="amount" pattern="^\d*(\.\d{0,2})?$" ref={register} />

                    </div>
                </div>
                <div className="form-row">
                    <div className="form-input flex-container">
                        <label className="label" htmlFor="notes">Notes for Customer:</label>
                        <textarea name="notes" ref={register} />
                    </div>
                </div>

                <div className="action-button-container">
                    <input className="submit-button bg-green" type="submit" value="Refund Customer" />
                </div>

                {props.payment && props.payment.error &&
                    <p>{props.payment.error}</p>}

            </form>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(RefundForm);
