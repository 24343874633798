import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import calculateDryClean from "../../../../../utils/calculateDryClean";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import Select from "react-select";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
library.add([faTrash, faPlus, faTimes]);

function DryClean(props) {
  const [order, setOrder] = useState();
  const [dryCleanItems, setDryCleanItems] = useState();
  const [dryCleanSelectOptions, setDryCleanSelectOptions] = useState();
  const [defaultItems, setDefaultItems] = useState();
  const [dryCleanMinDelivery, setDryCleanMinDelivery] = useState(32);
  const [isFlorida, setIsFlorida] = useState(false);

  // Refactor later
  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    } else {
      setOrder(props.localOrder);
      if (props.localOrder?.address?.state?.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  const {
    control,
    watch,
    errors,
    register,
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    if (isFlorida) {
      if (props.settingsFlorida) {
        setDryCleanItems(props.settingsFlorida.dryCleanServices);

        if (props.settingsFlorida.dryCleanServices) {
          // Create Select/dropdown option values for dry cleaning
          let options = props.settingsFlorida.dryCleanServices.map(
            (dryCleanItem) => {
              // Press Only, Crease, or Starch
              let availableServices = {
                press:
                  dryCleanItem.options.press &&
                  dryCleanItem.options.press.pressOnly,
                crease: dryCleanItem.options.crease,
                starch: dryCleanItem.options.starch,
              };

              return {
                label: dryCleanItem.item,
                value: dryCleanItem.item,
                availableServices,
              };
            }
          );

          setDryCleanSelectOptions(options);
        }

        if (props.settingsFlorida.settings) {
          setDryCleanMinDelivery(
            props.settingsFlorida.settings.dryCleanMinDelivery
          );
        }
      }
    } else {
      if (props.settings) {
        setDryCleanItems(props.settings.dryCleanServices);

        if (props.settings.dryCleanServices) {
          // Create Select/dropdown option values for dry cleaning
          let options = props.settings.dryCleanServices.map((dryCleanItem) => {
            // Press Only, Crease, or Starch
            let availableServices = {
              press:
                dryCleanItem.options.press &&
                dryCleanItem.options.press.pressOnly,
              crease: dryCleanItem.options.crease,
              starch: dryCleanItem.options.starch,
            };

            return {
              label: dryCleanItem.item,
              value: dryCleanItem.item,
              availableServices,
            };
          });

          setDryCleanSelectOptions(options);
        }

        if (props.settings.settings) {
          setDryCleanMinDelivery(props.settings.settings.dryCleanMinDelivery);
        }
      }
    }
  }, [props.settings, props.settingsFlorida]);

  // Default values from local order or edit order
  useEffect(() => {
    if (order && dryCleanSelectOptions && order.dryCleanOrder) {
      let items = order.dryCleanOrder.items.map((dryCleanItem) => {
        let foundOption = dryCleanSelectOptions.find((option) => {
          return option.value === dryCleanItem.type;
        });

        let quantity, existingItem;

        // If coming from an existing order in database
        if (typeof dryCleanItem.quantity === "number") {
          if (dryCleanItem.quantity) {
            if (dryCleanItem.quantity.value) {
              quantity = dryCleanItem.quantity.value;
            } else {
              quantity = dryCleanItem.quantity;
            }
          } else {
            quantity = 1;
          }

          existingItem = {
            label: dryCleanItem.type ? dryCleanItem.type : dryCleanItem.value,
            value: dryCleanItem.type ? dryCleanItem.type : dryCleanItem.value,
            quantity: {
              label: quantity,
              value: quantity,
            },
            availableServices: dryCleanItem.availableServices
              ? dryCleanItem.availableServices
              : foundOption.availableServices,
          };

          if (dryCleanItem.pressOnly) {
            existingItem.press = {
              label: "Press Only",
              value: "press",
            };
          }

          if (dryCleanItem.crease) {
            existingItem.crease = {
              label: "Crease",
              value: "crease",
            };
          }

          if (dryCleanItem.starch) {
            existingItem.starch = {
              label: dryCleanItem.starch.label
                ? dryCleanItem.starch.label
                : "No Starch",
              value: dryCleanItem.starch.value
                ? dryCleanItem.starch.label
                : "noStarch",
            };
          }

          // Else recently updated values - will already be formatted to populate react-hool-form default values
        } else {
          if (dryCleanItem.quantity) {
            quantity = dryCleanItem.quantity.value;
          } else {
            quantity = 1;
          }

          existingItem = {
            label: dryCleanItem.label,
            value: dryCleanItem.label,
            quantity: {
              label: quantity,
              value: quantity,
            },
            press: dryCleanItem.press,
            crease: dryCleanItem.crease,
            starch: dryCleanItem.starch,
            availableServices: dryCleanItem.availableServices,
          };
        }

        return existingItem;
      });

      reset({
        items,
      });
    }
  }, [order, dryCleanSelectOptions]);

  // Create Select/dropdown option values for Dry Clean
  // let dryCleanSelectOptions = DryCleanItems.map(dryCleanItem => {
  //     return { label: dryCleanItem.item, value: dryCleanItem.item, availableOptions: dryCleanItem.options }
  // });

  let quantityOptions = [];
  for (let i = 1; i < 21; i++) {
    quantityOptions.push({ label: i, value: i });
  }

  let history = useHistory();

  const onSubmit = (data) => {
    let { price, items } = calculateDryClean({
      items: data.items,
      settings: isFlorida ? props.settingsFlorida : props.settings,
    });

    data.quote = price.toFixed(2);
    data.items = items;

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "dryCleanOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "dryCleanOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="dry-clean-step" className="order-step">
      <h4 className="step-header">
        Please select items and options for dry cleaning below.
      </h4>

      {/* <p className="form-error-message">
        For pick up / delivery orders under {dryCleanMinDelivery} lbs, you will
        be charged the minimum price of ${dryCleanMinDelivery}.
      </p> */}

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((i, index) => {
          let selectedDryCleanItem;
          selectedDryCleanItem = watch(`items[${index}]`);

          return (
            <div
              key={i.id}
              className="dryclean-repeater-module repeater-module"
            >
              <div className="repeater-item">
                <div className="item-name">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={dryCleanSelectOptions}
                          placeholder="Item"
                        />
                      }
                      control={control}
                      name={`items[${index}]`}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />

                    <ErrorMessage errors={errors} name={`items[${index}]`}>
                      {() => (
                        <p className="form-error-message">
                          Item cannot be empty.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="item-options">
                  {selectedDryCleanItem &&
                    selectedDryCleanItem.availableServices &&
                    selectedDryCleanItem.availableServices.press && (
                      <div className="select">
                        <Controller
                          as={
                            <Select
                              options={[
                                { label: "Dry Clean", value: "dryClean" },
                                { label: "Press Only", value: "pressOnly" },
                              ]}
                              defaultValue={{
                                label: "Dry Clean",
                                value: "dryClean",
                              }}
                            />
                          }
                          control={control}
                          name={`items[${index}].press`}
                        />

                        <ErrorMessage
                          errors={errors}
                          name={`items[${index}].press`}
                        >
                          {() => (
                            <p className="form-error-message">
                              Please select an item.
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                    )}

                  {selectedDryCleanItem &&
                    selectedDryCleanItem.availableServices &&
                    selectedDryCleanItem.availableServices.crease && (
                      <div className="select">
                        <Controller
                          as={
                            <Select
                              options={[
                                { label: "No Crease", value: "noCrease" },
                                { label: "Crease", value: "crease" },
                              ]}
                              defaultValue={{
                                label: "No Crease",
                                value: "noCrease",
                              }}
                            />
                          }
                          control={control}
                          name={`items[${index}].crease`}
                        />

                        <ErrorMessage
                          errors={errors}
                          name={`items[${index}].crease`}
                        >
                          {() => (
                            <p className="form-error-message">
                              Please select an item.
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                    )}

                  {selectedDryCleanItem &&
                    selectedDryCleanItem.availableServices &&
                    selectedDryCleanItem.availableServices.starch && (
                      <div className="select">
                        <Controller
                          as={
                            <Select
                              options={[
                                { label: "No Starch", value: "noStarch" },
                                { label: "Light Starch", value: "lightStarch" },
                                { label: "Heavy Starch", value: "heavyStarch" },
                              ]}
                              defaultValue={{
                                label: "No Starch",
                                value: "noStarch",
                              }}
                            />
                          }
                          control={control}
                          name={`items[${index}].starch`}
                        />

                        <ErrorMessage
                          errors={errors}
                          name={`items[${index}].starch`}
                        >
                          {() => (
                            <p className="form-error-message">
                              Please select an item.
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                    )}
                </div>

                <div className="item-quantity">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={quantityOptions}
                          defaultValue={{ label: 1, value: 1 }}
                          placeholder="Quantity"
                        />
                      }
                      control={control}
                      name={`items[${index}].quantity`}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`items[${index}].quantity`}
                    >
                      {() => (
                        <p className="form-error-message">
                          Please select a quantity.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="cancel item-cancel">
                    <button
                      className="form-input-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="action-button-container">
          <button
            className="form-add bg-orange"
            type="button"
            onClick={() => {
              append({ type: "Select Item" });
            }}
          >
            <FontAwesomeIcon icon="plus" /> Item
          </button>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.dryCleanOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
  };
};

export default connect(mapStateToProps)(DryClean);
