import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';
import MaskedInput from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray, Controller, ErrorMessage } from 'react-hook-form';

import { getUser, createUser, updateUser } from '../../../../actions/users';

function NewUser(props) {

    const [editMode, setEditMode] = useState(false);

    let defaultFirstName = "";
    let defaultLastName = "";
    let defaultEmail = "";
    let defaultRole = "";
    let defaultLocation = "";

    if (props.user) {
        defaultFirstName = props.user.firstName;
        defaultLastName = props.user.lastName;
        defaultEmail = props.user.email;
        defaultRole = { label: props.user.role, value: props.user.role };
        defaultLocation = { label: props.user.locationName, value: props.user.location };
    }

    const { register, handleSubmit, errors, control } = useForm({
        mode: "onChange",
        defaultValues: {
            firstName: defaultFirstName,
            lastName: defaultLastName,
            email: defaultEmail,
            role: defaultRole,
            location: defaultLocation
        }
    });

    // Format locations for Select input, excluding current location
    let locationsOptions;
    if (props.locations) {
        locationsOptions = props.locations.locations.map(location => {
            return {
                label: location.address,
                value: location._id
            }
        })
    }

    const handleCancel = () => {
        props.handleCancelEditUser();
    }

    // Dispatch action 
    const onSubmit = (formData) => {
        props.dispatch(createUser(formData, () => {
            props.handleCancelEditUser();
        }));
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-panel-container">

                <div className="action-links-container">
                    {!editMode && props.user && <button onClick={() => setEditMode(true)}>Edit</button>}
                    {editMode || !props.user && <button onClick={() => handleCancel()}>Cancel</button>}
                </div>

                <div id="app-user">
                    <form className="user-form" onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" name="firstName" ref={register} />
                            </div>

                            <div className="form-input">
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" name="lastName" ref={register} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    ref={
                                        register({
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Please enter a valid email address.'
                                            }
                                        })}

                                />
                                <ErrorMessage errors={errors} name="email">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>

                            <div className="form-input">
                                <label htmlFor="password">Temporary Password</label>
                                <input type="password" name="password" ref={register} />
                            </div>
                        </div>

                        <div className="form-input">
                            <label htmlFor="password">Role</label>
                            <Controller
                                as={<Select
                                    placeholder="User Role"
                                    options={[
                                        {
                                            label: "Super Admin",
                                            value: "SuperAdmin"
                                        },
                                        {
                                            label: "Admin",
                                            value: "Admin"
                                        }
                                    ]}
                                />
                                }
                                control={control}
                                name="role"
                                rules={{ required: true, validate: value => value !== "Select a user role." }}
                            />
                        </div>
                        <div className="form-input">
                            <label htmlFor="password">Location</label>
                            <Controller
                                as={<Select
                                    placeholder="Location"
                                    options={locationsOptions}
                                />
                                }
                                control={control}
                                name="location"
                                rules={{ required: true, validate: value => value !== "Select a location." }}
                            />
                        </div>

                        <div className="action-button-container">
                            <input className="submit-button bg-green" type="submit" value="Save" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(NewUser);