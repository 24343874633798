import axios from "axios";
import {
  GET_ZIPS,
  CREATE_ZIP,
  DELETE_ZIP,
  ZIP_ERROR,
  CLEAR_ZIP_ERROR,
} from "./types";

// Get all specials
export const getZips = () => {
  return (dispatch) => {
    return axios
      .get(`/api/zips`)
      .then((response) => {
        dispatch(getZipsSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getZipsSuccess = (data) => ({
  type: GET_ZIPS,
  zips: data,
});

// Update a single special
export const createZip = (zip, callback) => {
  return (dispatch) => {
    return axios
      .post(
        `/api/admin/zip`,
        { zip },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(zipError(response.data.error));
        } else {
          dispatch(createZipSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createZipSuccess = (data) => ({
  type: CREATE_ZIP,
  zip: data,
});

// Delete special
export const deleteZip = (id, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/zip/${id}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch(deleteZipSuccess(response.data));
        callback();
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteZipSuccess = (data) => ({
  type: DELETE_ZIP,
  id: data,
});

export function zipError(error) {
  return {
    type: ZIP_ERROR,
    error: error,
  };
}

export function clearZipError() {
  return {
    type: CLEAR_ZIP_ERROR,
  };
}
