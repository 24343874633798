import React, { useEffect, useState } from "react";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Switch, Route } from "react-router-dom";

import config from "../../config/keys";

// Import Components
import Sidebar from "../../global/components/Sidebar/Sidebar";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import OrderSummary from "./components/OrderSummary/OrderSummary";
import AddressStep from "./components/AddressStep/AddressStep";
import ServicesStep from "./components/ServicesStep/ServicesStep";
import ScheduleStep from "./components/ScheduleStep/ScheduleStep";
import CheckoutStep from "./components/CheckoutStep/CheckoutStep";
import AppOrderDetail from "../../views/AppOrderDetail/AppOrderDetail";

// Service Sub-Components
import WashFold from "./components/ServicesStep/components/WashFold.js";
import OrganicWashFold from "./components/ServicesStep/components/OrganicWashFold.js";
import DryClean from "./components/ServicesStep/components/DryClean.js";
import Tailoring from "./components/ServicesStep/components/Tailoring.js";
import WashPress from "./components/ServicesStep/components/WashPress.js";
import Comforter from "./components/ServicesStep/components/Comforter.js";

// Redux actions
import { getLocations } from "../../actions/locations";
import { getSettings } from "../../actions/settings";
import { getZips } from "../../actions/zips";
import { getSettingsFlorida } from "../../actions/settings_florida";

const stripePromise = loadStripe(config.stripeKey);

function AppOrder(props) {
  const dispatch = useDispatch();
  const [orderComplete, setOrderComplete] = useState(false);
  const [editMode, setEditMode] = useState(props.edit);

  const [order, setOrder] = useState();

  useEffect(() => {
    if (props.editOrder && props.editOrder.orderId) {
      setOrder(props.editOrder);
      setEditMode(true);
    } else {
      setOrder(props.localOrder);
      setEditMode(false);
    }
  }, [props.editOrder, props.localOrder]);

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getSettingsFlorida());
    dispatch(getZips());

    let limit = 100;
    let skip = 0;

    props.dispatch(getLocations({ key: "address", desc: false }, limit, skip));
  }, []);

  return (
    <div className="dashboard-container">
      <Elements stripe={stripePromise}>
        {props.auth.user && <Sidebar />}
        <div className="dashboard-panel-container">
          {!props.auth.user && (
            <div className="login-link">
              <NavLink to="/login">Log in to your account</NavLink>
            </div>
          )}

          {props.settings && props.settings.disableOrdering && (
            <div className="ordering-disabled-banner">
              {props.settings.disableOrderingMessage}
            </div>
          )}

          {props.settings && !props.settings.disableOrdering && (
            <div id="app-order">
              <ProgressBar />
              <div className="content">
                <Switch>
                  <Route path="/app/order/services/washfold">
                    <WashFold edit={editMode} />
                  </Route>
                  <Route path="/app/order/services/organicwashfold">
                    <OrganicWashFold edit={editMode} />
                  </Route>
                  <Route path="/app/order/services/dryclean">
                    <DryClean edit={editMode} />
                  </Route>
                  <Route path="/app/order/services/washpress">
                    <WashPress edit={editMode} />
                  </Route>
                  <Route path="/app/order/services/tailoring">
                    <Tailoring edit={editMode} />
                  </Route>
                  <Route path="/app/order/services/comforter">
                    <Comforter edit={editMode} />
                  </Route>
                  <Route path="/app/order/services">
                    <ServicesStep edit={editMode} />{" "}
                  </Route>

                  {!editMode && (
                    <Route path="/app/order/schedule">
                      <ScheduleStep edit={editMode} />
                    </Route>
                  )}

                  <Route path="/app/order/checkout">
                    <CheckoutStep
                      edit={editMode}
                      setOrderComplete={setOrderComplete}
                    />
                  </Route>
                  <Route path="/app/order/address">
                    <AddressStep edit={editMode} />
                  </Route>
                  <Route path="/app/order/id/:orderId">
                    <AppOrderDetail edit={editMode} />
                  </Route>
                  <Route path="/app/order">
                    {<Redirect to="/app/order/address" edit={editMode} />}
                  </Route>
                </Switch>
                {!orderComplete &&
                  order &&
                  (order.washFoldOrder ||
                    order.organicWashFoldOrder ||
                    order.dryCleanOrder ||
                    order.tailoringOrder ||
                    order.comforterOrder ||
                    order.washPressOrder) && <OrderSummary />}
              </div>
            </div>
          )}
        </div>
      </Elements>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
    settings: state.settings.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(AppOrder);
