

import React from "react";
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';

import { addDryClean } from "../../../actions/settings";


function NewDryCleanForm(props) {

    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });

    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(addDryClean(formData, () => {
            props.close();
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="new-product">
                <input type="text" name="item" ref={register} />
                <div>
                    <label>Price:</label>
                    <input className="price-field" type="text" name="price" ref={register} />
                </div>
                <div>
                    <label>Press Only:</label>
                    <input className="price-field" type="text" name="pressOnly" ref={register} />
                </div>

                <div className="form-checkbox-group">
                    <div className="option-item">
                        <input
                            type="checkbox"
                            name="options.crease"
                            ref={register}
                        />
                        <div htmlFor="options.crease">Crease</div>
                    </div>
                    <div className="option-item">
                        <input
                            type="checkbox"
                            name="options.starch"
                            ref={register}
                        />
                        <div htmlFor="options.starch">Starch</div>
                    </div>
                </div>
                <input className="add submit-button" type="submit" value="Add" />
            </div>
        </form>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(NewDryCleanForm);