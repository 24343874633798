import React from "react";
import ZipForm from "../ZipForm/ZipForm";

import "./BannerFooter.scss";

export default function BannerFooter() {
  return (
    <div
      className="banner-footer"
      style={{ backgroundImage: `url($'/images/promise-hanging-scrubs.jpg')` }}
    >
      <div className="sticker">
        <span>
          Current
          <br />
          Special
        </span>
        <span>Free</span>
        <span>Pick Up & Delivery</span>
      </div>
      <div className="content">
        <h3>Your time is priceless. Let us do your laundry.</h3>
        <div className="banner-zip-form-container">
          <ZipForm />
        </div>
      </div>
    </div>
  );
}
