import {
  GET_LOCATIONS,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  LOCATION_ERROR,
  CLEAR_LOCATION_ERROR,
} from "../actions/types";

export default (state = { error: null }, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return { ...state, locations: action.locations };
    case CREATE_LOCATION:
      return { ...state, locations: [action.location, ...state.locations] };
    case UPDATE_LOCATION:
      let locationIndex = state.locations.findIndex(
        (location) => location.locationId === action.location.locationId
      );

      let updatedLocations = state.locations.map((item, index) => {
        if (index !== locationIndex) {
          return item;
        }
        return action.location;
      });
      return {
        ...state,
        locations: updatedLocations,
        newLocation: action.location,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          (location) => location.address !== action.location.address
        ),
      };
    case LOCATION_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_LOCATION_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
