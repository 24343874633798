import axios from "axios";

const getPaymentIntent = async ({ user, amount, order }) => {
  try {
    const body = {
      user: user || null,
      customer: user?.stripeId ? user?.stripeId : null,
      amount,
      receipt_email: order.address.email,
      description: order.orderId,
    };

    let response = await axios.post(`/api/payment/intent`, body, {
      headers: { Authorization: "JWT " + localStorage.getItem("token") },
    });

    if (response.data) {
      return response.data.clientSecret;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

// module.exports = getPaymentIntent;

export default getPaymentIntent;
