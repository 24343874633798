import React, { useState, useEffect } from "react";

import BannerFooter from "../../global/components/BannerFooter/BannerFooter.js";
import Triangle from "../../global/components/Triangle/Triangle";

import "./BusinessServices.scss";

function BusinessServices(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div
      id="business-services"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <Triangle />

      <div id="business-services-hero" className="flex-container section">
        <div className="half centered">
          <div className="content-container">
            <h1 className="green-text">
              A consistent look and consistent quality
              <br />
              <span className="orange-text">
                are the hallmarks of your business.
              </span>
            </h1>
            <p>
              There's no better way to put your best foot forward than with
              professional cleaning that delivers on the consistent quality you
              seek to burnish your image.
            </p>
          </div>
        </div>
        <div className="half image-container half centered">
          <img src="/images/business-hero.png" />
        </div>
      </div>

      <div id="business-services-main">
        <div className="content">
          <div className="content-container">
            <p>
              What's more you save time and money - because time is money - by
              delegating a mundane but necessary task like laundering to us,
              while your employees do the important work.
            </p>

            <p>We offer up to a 50% discount for wholesale accounts.</p>

            <div className="business-services-list">
              <p className="orange-text" style={{ fontWeight: "bold" }}>
                Our wholesale and business services include:
              </p>
              <ul>
                <li>Dry Cleaning</li>
                <li>Pick Up and Delivery Services</li>
                <li>Laundered Dress Shirts</li>
                <li>Wash and Fold</li>
                <li>Tailoring and Alterations</li>
              </ul>
            </div>

            <div className="business-services-list">
              <p className="orange-text" style={{ fontWeight: "bold" }}>
                We serve:
              </p>
              <ul>
                <li>Gyms</li>
                <li>Spas and Salons</li>
                <li>Hotels</li>
                <li>Bars and Restaurants</li>
                <li>Religious Facilities</li>
                <li>Medical Offices</li>
                <li>Department Stores</li>
                <li>Other Laundromats and Dry Cleaning Stores</li>
              </ul>
            </div>

            <p className="green-text" style={{ fontWeight: "bold" }}>
              For contracts and pricing please call us at (212) 928-WASH.
            </p>
          </div>
        </div>
      </div>

      <div
        id="business-services-illustration"
        style={{ backgroundImage: `url('/images/business-background.png')` }}
      ></div>

      <BannerFooter />
    </div>
  );
}

export default BusinessServices;
