import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

// Redux actions

import { getLocations } from '../../actions/locations';
import { getSpecials } from '../../actions/specials';
import { getCurrentUser } from '../../actions/auth';

function AppDashboard(props) {

    const history = useHistory();

    useEffect(() => {
        if (props.auth.user) {
            if (props.auth.user.role === "SuperAdmin" || props.auth.user.role === "Admin") {
                history.push("/app/orders");
            } else {
                history.push("/app/order");
            }
        }
    }, [props.auth.user, history]);

    useEffect(() => {
        if (props.auth.user) {
            let limit = 100;
            let skip = 0;
            props.dispatch(getLocations({ key: 'address', desc: false }, limit, skip));
            props.dispatch(getSpecials({ key: 'name', desc: false }, limit, skip));
        }

        if (!props.auth.user) {
            props.dispatch(getCurrentUser(
                () => {
                    if (props.auth.user.role === "SuperAdmin" || props.auth.user.role === "Admin") {
                        history.push("/app/orders");
                    } else {
                        history.push("/app/order");
                    }

                }
            ));
        }

    }, []);



    return (
        <div className="dashboard-container">


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        orders: state.orders.orders
    }
}


export default connect(mapStateToProps)(AppDashboard);

