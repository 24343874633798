import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";

// Components
import MapContainer from "./components/MapContainer.js";
import LocationsImages from "../../global/components/LocationsImages/LocationsImages";

import { getLocations } from "../../actions/locations";

import config from "../../config/keys";

import "./Locations.scss";

const GoogleAPIKey = config.googleMaps;

function Locations(props) {
  const [location, setLocation] = useState({
    address: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    hours: null,
  });
  const [closestLocation, setClosestLocation] = useState("");
  const [zip, setZip] = useState(null);
  const [coords, setCoords] = useState(null);
  const [error, setError] = useState(null);
  const [limit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [sortBy, setSortBy] = useState({ key: "address", desc: false });
  const [destinations, setDestinations] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations(sortBy, limit, skip));
  }, [dispatch, sortBy, limit, skip]);

  useEffect(() => {
    if (props.locations) {
      let foundDestinations = props.locations.map((location) => {
        return `${location.address}, ${location.city} ${location.state} ${location.zip}`;
      });
      setDestinations(foundDestinations);
    }
  }, [props.locations]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const chunkArray = (array, size = 25) => {
    const chunkedArray = [];
    let index = 0;
    while (index < array.length) {
      chunkedArray.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArray;
  };

  const matrix = new props.google.maps.DistanceMatrixService();

  // Helper function to make the API call and return a Promise
  const getDistances = (dests) => {
    return new Promise((resolve, reject) => {
      matrix.getDistanceMatrix(
        {
          origins: [zip],
          destinations: dests,
          travelMode: props.google.maps.TravelMode.WALKING,
        },
        function(response, status) {
          if (response) {
            resolve(response);
          } else {
            reject(new Error("Failed to get distances"));
          }
        }
      );
    });
  };

  // Function to process the API response and update the state
  const processApiResponse = (response) => {
    const results = response.rows[0].elements;
    if (results[0].distance) {
      let closestDistance = results[0].distance.value;
      let closestDistanceIndex;
      for (let i = 0; i < results.length; i++) {
        if (results[i].distance.value <= closestDistance) {
          closestDistance = results[i].distance.value;
          closestDistanceIndex = i;
        }
      }
      let location = props.locations[closestDistanceIndex];
      let locationText = `${location.address}, ${location.city} ${location.state} ${location.zip}`;
      setClosestLocation(locationText);
      setCoords({
        lat: parseFloat(location.lat),
        long: parseFloat(location.long),
      });
      setLocation({
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
        phone: location.phone,
        hours: location.hours,
      });
    } else {
      setError("Sorry, this location is not valid. Please try again.");
    }
  };

  // Get distances for each chunk and process the results
  const getClosestLocation = async (e) => {
    e.preventDefault();
    try {
      const chunkedArray = chunkArray(destinations); // Assuming 'props.locations' is an array of your locations
      const responses = await Promise.all(chunkedArray.map(getDistances));

      // Flatten the results and find the closest location
      const allResults = responses.flatMap(
        (response) => response.rows[0].elements
      );
      processApiResponse({ rows: [{ elements: allResults }] });
    } catch (error) {
      setError("Sorry, this location is not valid. Please try again.");
    }
  };

  return (
    <div id="locations">
      <div id="locations-hero">
        <h2 className="green-text">
          Wherever you are, New York's best laundry service is{" "}
          <span className="orange-text">just a tap away.</span>
        </h2>
      </div>

      <div id="locations-finder" className="">
        <div className="content-container">
          <form onSubmit={getClosestLocation}>
            <p>Find a self-service location</p>
            <input
              onChange={(e) => {
                setZip(e.target.value);
              }}
              placeholder="ZIP CODE"
              type="text"
              name="zip"
            ></input>
            <button type="submit">Self-Service</button>
          </form>
          {error && <p>{error}</p>}

          {coords && (
            <div id="locations-results">
              <h3 className="green-text">Your closest location is:</h3>
              <p>{closestLocation}</p>
            </div>
          )}
        </div>
      </div>

      <div id="locations-browser" className="flex-container">
        <div className="half">
          <div className="location-info">
            <h3 className="green-text">Click on the map to view a location:</h3>
            {location.address && (
              <>
                <p>Miss Bubble Laundromat</p>
                <p>{location.address}</p>
                <p>
                  {location.city}, {location.state} {location.zip}
                </p>
                <p>Open: {location.hours}</p>
                <p>
                  Call Us:{" "}
                  <a
                    className="green-text"
                    href={`tel:${location.phone.replace("-", "")}`}
                  >
                    {location.phone}
                  </a>
                </p>
              </>
            )}
          </div>
        </div>

        <div className="half locations-map">
          <MapContainer
            coords={coords}
            setLocation={setLocation}
            locations={props.locations}
          />
        </div>
      </div>

      <LocationsImages />

      <div id="locations-list">
        <div className="locations-container">
          {props.locations &&
            props.locations.map((location) => {
              return (
                <div className="locations-list-item" key={location.address}>
                  <p className="address">
                    {location.address}, {location.city}, {location.zip}{" "}
                  </p>
                  <p className="hours">{location.hours}</p>
                  <p className="phone">{location.phone}</p>
                </div>
              );
            })}
        </div>

        <h2>Your time is priceless. Let us do your laundry.</h2>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
  };
};

const GoogleWrappedContainer = GoogleApiWrapper({
  apiKey: GoogleAPIKey,
})(Locations);

export default connect(mapStateToProps)(GoogleWrappedContainer);

// export default GoogleApiWrapper({
//     apiKey: GoogleAPIKey
// })(Locations);
