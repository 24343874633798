

import React from "react";
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateTailoring, deleteService } from "../../../actions/settings";

function TailoringForm(props) {

    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(updateTailoring(formData, () => {
            props.close();
        }));
    }

    const onDelete = (e) => {
        e.preventDefault();
        props.dispatch(deleteService(props.product._id, () => {
            props.close();
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="product tailoring-product">
                <button className="delete" onClick={onDelete}>X</button>
                <div>
                    <input type="text" name="item" ref={register} defaultValue={props.product.item} />
                    <input type="hidden" name="id" ref={register} defaultValue={props.product._id} />

                </div>

                <div className="tailoring-options">

                    <div className="tailoring-option">
                        <p>Hem</p>
                        <input type="text" name="hem" ref={register} defaultValue={props.product.options["hem"]} />
                    </div>

                    <div className="tailoring-option">
                        <p>Taper</p>
                        <input type="text" name="taper" ref={register} defaultValue={props.product.options.taper} />
                    </div>

                    <div className="tailoring-option">
                        <p>Zipper</p>
                        <input type="text" name="zipper" ref={register} defaultValue={props.product.options.zipper} />
                    </div>

                    <div className="tailoring-option">
                        <p>Sleeves</p>
                        <input type="text" name="sleeves" ref={register} defaultValue={props.product.options.sleeves} />
                    </div>

                    <div className="tailoring-option">
                        <p>Patch</p>
                        <input type="text" name="patch" ref={register} defaultValue={props.product.options.patch} />
                    </div>

                    <div className="tailoring-option">
                        <p>Fix</p>
                        <input type="text" name="fix" ref={register} defaultValue={props.product.options.fix} />
                    </div>

                    <div className="tailoring-option">
                        <p>Waist</p>
                        <input type="text" name="waist" ref={register} defaultValue={props.product.options.waist} />
                    </div>

                    <div className="tailoring-option">
                        <p>Lining</p>
                        <input type="text" name="lining" ref={register} defaultValue={props.product.options.lining} />
                    </div>

                </div>

                <div className="action-button-container">
                    <input className="submit-button update" type="submit" value="Update" />
                </div>
            </div>
        </form>

    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(TailoringForm);