import React from "react";
import { NavLink } from "react-router-dom";

function ServicesStep(props) {
  return (
    <div id="services-step" className="order-step">
      <div className="services-grid">
        <NavLink to="/app/order/services/washfold" className="service-block">
          <img
            src="/images/services-washing-machine.svg"
            alt="Washing Machine Icon"
          />
          <label>Wash & Fold</label>
        </NavLink>
        <NavLink
          to="/app/order/services/organicwashfold"
          className="service-block"
        >
          <img src="/images/services-leaf.svg" alt="Leaf" />
          <label>Organic Wash & Fold</label>
        </NavLink>
        <NavLink to="/app/order/services/dryclean" className="service-block">
          <img
            src="/images/services-clothes-hanger.svg"
            alt="Clothes Hanger Icon"
          />
          <label>Dry Clean</label>
        </NavLink>
        <NavLink to="/app/order/services/washpress" className="service-block">
          <img src="/images/services-iron.svg" alt="Iron" />
          <label>Wash and Press</label>
        </NavLink>
        <NavLink to="/app/order/services/tailoring" className="service-block">
          <img src="/images/services-tailor.svg" alt="Tailoring Icon" />
          <label>Tailoring</label>
        </NavLink>
        <NavLink to="/app/order/services/comforter" className="service-block">
          <img src="/images/services-duvet.svg" alt="Comforter Icon" />
          <label>Comforter</label>
        </NavLink>
      </div>
    </div>
  );
}

export default ServicesStep;
