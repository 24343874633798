import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

// Redux
import {
  forgotPassword,
  authMessage,
  clearAuthError,
} from "../../actions/auth";

// Form
import { useForm, ErrorMessage } from "react-hook-form";

function ForgotPassword(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  let history = useHistory();

  if (props.auth.authenticated) {
    history.push("/app/order");
  }

  const clearError = () => {
    props.dispatch(clearAuthError());
  };

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      forgotPassword(formData, () => {
        props.dispatch(
          authMessage(
            "Please check your email for a link to reset your password."
          )
        );
        history.push("/login");
      })
    );
  };

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  return (
    <div
      id="login"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <h2>Forgot your password?</h2>

      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        {props.auth.error && <p>{props.auth.error}</p>}
        <div className="form-line">
          <input
            name="email"
            placeholder="Email"
            ref={register({ required: "Please enter your email address." })}
          />
          <ErrorMessage errors={errors} name="email">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value="Email Reset Link"
          />
        </div>

        <div className="forgot-password-container">
          <Link to="/login" onClick={clearError}>
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
