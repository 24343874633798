import {
  ADD_SERVICE_FLORIDA,
  UPDATE_SERVICE_FLORIDA,
  DELETE_SERVICE_FLORIDA,
  UPDATE_SETTINGS_FLORIDA,
  UPDATE_PRODUCT_FLORIDA,
  DELETE_PRODUCT_FLORIDA,
  GET_SETTINGS_FLORIDA,
  SETTINGS_ERROR_FLORIDA,
  CLEAR_SETTINGS_ERROR_FLORIDA,
  ADD_PRODUCT_FLORIDA,
  SET_FLORIDA_STATUS,
} from "../actions/types";

export default (state = { floridaSession: false }, action) => {
  switch (action.type) {
    case GET_SETTINGS_FLORIDA:
      return {
        ...state,
        settings: action.payload.settings[1],
        comforterServices: action.payload.comforterServices,
        dryCleanServices: action.payload.dryCleanServices,
        tailoringServices: action.payload.tailoringServices,
        washPressServices: action.payload.washPressServices,
        products: action.payload.products,
      };
    case SET_FLORIDA_STATUS:
      return {
        ...state,
        floridaSession: action.payload,
      };
    case UPDATE_SETTINGS_FLORIDA:
      return {
        ...state,
        settings: action.settings,
      };

    case UPDATE_PRODUCT_FLORIDA:
      let productIndex = state.products.findIndex(
        (product) => product._id === action.product._id
      );

      let updatedProducts = state.products.map((item, index) => {
        if (index !== productIndex) {
          return item;
        }
        return action.product;
      });
      return { ...state, products: updatedProducts };

    case ADD_PRODUCT_FLORIDA:
      return { ...state, products: [...state.products, action.product] };

    case DELETE_PRODUCT_FLORIDA:
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.productId
        ),
      };

    case ADD_SERVICE_FLORIDA:
      if (action.service.parentService === "washPress") {
        return {
          ...state,
          washPressServices: [...state.washPressServices, action.service],
        };
      } else if (action.service.parentService === "dryClean") {
        return {
          ...state,
          dryCleanServices: [...state.dryCleanServices, action.service],
        };
      } else if (action.service.parentService === "tailoring") {
        return {
          ...state,
          tailoringServices: [...state.tailoringServices, action.service],
        };
      } else if (action.service.parentService === "comforter") {
        return {
          ...state,
          comforterServices: [...state.comforterServices, action.service],
        };
      }

    case DELETE_SERVICE_FLORIDA:
      if (action.service.parentService === "washPress") {
        return {
          ...state,
          washPressServices: state.washPressServices.filter(
            (service) => service._id !== action.service.id
          ),
        };
      } else if (action.service.parentService === "dryClean") {
        return {
          ...state,
          dryCleanServices: state.dryCleanServices.filter(
            (service) => service._id !== action.service.id
          ),
        };
      } else if (action.service.parentService === "tailoring") {
        return {
          ...state,
          tailoringServices: state.tailoringServices.filter(
            (service) => service._id !== action.service.id
          ),
        };
      } else if (action.service.parentService === "comforter") {
        return {
          ...state,
          comforterServices: state.comforterServices.filter(
            (service) => service._id !== action.service.id
          ),
        };
      }

    case UPDATE_SERVICE_FLORIDA:
      let serviceIndex;
      let updatedServices;

      if (action.service.parentService === "washPress") {
        serviceIndex = state.washPressServices.findIndex(
          (washPressService) => washPressService._id === action.service._id
        );

        updatedServices = state.washPressServices.map((item, index) => {
          if (index !== serviceIndex) {
            return item;
          }
          return action.service;
        });

        return { ...state, washPressServices: updatedServices };
      } else if (action.service.parentService === "dryClean") {
        serviceIndex = state.dryCleanServices.findIndex(
          (dryCleanService) => dryCleanService._id === action.service._id
        );

        updatedServices = state.dryCleanServices.map((item, index) => {
          if (index !== serviceIndex) {
            return item;
          }
          return action.service;
        });

        return { ...state, dryCleanServices: updatedServices };
      } else if (action.service.parentService === "tailoring") {
        serviceIndex = state.tailoringServices.findIndex(
          (tailoringService) => tailoringService._id === action.service._id
        );

        updatedServices = state.tailoringServices.map((item, index) => {
          if (index !== serviceIndex) {
            return item;
          }
          return action.service;
        });

        return { ...state, tailoringServices: updatedServices };
      } else if (action.service.parentService === "comforter") {
        serviceIndex = state.comforterServices.findIndex(
          (comforterService) => comforterService._id === action.service._id
        );

        updatedServices = state.comforterServices.map((item, index) => {
          if (index !== serviceIndex) {
            return item;
          }
          return action.service;
        });

        return { ...state, comforterServices: updatedServices };
      }

    default:
      return state;
  }
};
