

import React from "react";
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateOrganicWashFoldSettings } from "../../../actions/settings";

function OrganicWashFoldForm(props) {

    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(updateOrganicWashFoldSettings(formData, () => {
            props.close();
        }));
    }

    return (
        <form className="organic-wash-fold-settings-section" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
                <div className="form-input">
                    <label>Min Delivery Up to _ Lbs</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.organicWashFoldUpToXLbs}</p>}

                    {props.editMode && <input type="text" name="organicWashFoldUpToXLbs" ref={register} defaultValue={props.settings.organicWashFoldUpToXLbs} />}
                </div>
                <div className="form-input">
                    <label>Price Per Lb After Minimum ($)</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.organicWashFoldPerLb}</p>}

                    {props.editMode && <input type="text" name="organicWashFoldPerLb" ref={register} defaultValue={props.settings.organicWashFoldPerLb} />}
                </div>
            </div>

            {props.editMode &&
                <div className="action-button-container">
                    <input className="submit-button update" type="submit" value="Update" />
                </div>
            }
        </form>

    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(OrganicWashFoldForm);