import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  updateProduct,
  deleteProduct,
} from "../../../actions/settings_florida";

function ProductForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateProduct(formData, () => {
        props.close();
      })
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    props.dispatch(
      deleteProduct(props.product._id, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="product">
        <button className="delete" onClick={onDelete}>
          X
        </button>
        <input
          type="hidden"
          ref={register}
          value={props.product._id}
          name="id"
        />
        <input
          type="text"
          name="itemName"
          ref={register}
          defaultValue={props.product.item}
        />
        <input
          type="text"
          name="itemPrice"
          ref={register}
          defaultValue={props.product.price}
        />
        <button className="submit-button update" type="submit">
          Update
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(ProductForm);
