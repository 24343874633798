import axios from "axios";
import {
  GET_SETTINGS,
  SETTINGS_ERROR,
  CLEAR_SETTINGS_ERROR,
  ADD_PRODUCT,
  ADD_SERVICE,
  UPDATE_SETTINGS,
  UPDATE_PRODUCT,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  DELETE_PRODUCT,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getSettings = () => {
  return (dispatch) => {
    return axios
      .get(`/api/settings`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(getSettingsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error getting settings."));
        }
      });
  };
};

export const updateDisableOrdering = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/disableordering`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateTimeWindows = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/timewindows`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating times."));
        }
      });
  };
};

export const updateMinDelivery = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/mindelivery`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating minimum delivery."));
        }
      });
  };
};

export const updateMinDeliveryDays = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/mindeliverydays`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating minimum delivery days."));
        }
      });
  };
};

export const updateWashFoldSettings = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/washfoldsettings`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateOrganicWashFoldSettings = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/organicwashfoldsettings`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateSettingsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateProduct = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/product`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateProductSuccess(data));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const addProduct = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/product/new`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(addProductSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error adding product."));
        }
      });
  };
};

export const deleteProduct = (id, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/settings/product/${id}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(deleteProductSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error deleting product."));
        }
      });
  };
};

export const updateWashPress = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/washpress`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const addWashPress = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/washpress/new`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(addServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateDryClean = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/dryclean`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const addDryClean = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/dryclean/new`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(addServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateTailoring = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/tailoring`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const addTailoring = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/tailoring/new`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(addServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const deleteService = (id, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/settings/service/${id}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(deleteServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const updateComforter = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings/comforter`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSettingsError());
        dispatch(updateServiceSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(settingsError(err.response.data.message));
        } else {
          dispatch(settingsError("Error updating settings."));
        }
      });
  };
};

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS,
  payload: data,
});

export const addProductSuccess = (data) => ({
  type: ADD_PRODUCT,
  product: data,
});

export const addServiceSuccess = (data) => ({
  type: ADD_SERVICE,
  service: data,
});

export const updateProductSuccess = (data) => ({
  type: UPDATE_PRODUCT,
  product: data,
});

export const updateServiceSuccess = (data) => ({
  type: UPDATE_SERVICE,
  service: data,
});

export const deleteServiceSuccess = (data) => ({
  type: DELETE_SERVICE,
  service: data,
});

export const deleteProductSuccess = (data) => ({
  type: DELETE_PRODUCT,
  productId: data,
});

export const updateSettingsSuccess = (data) => ({
  type: UPDATE_SETTINGS,
  settings: data,
});

export const settingsError = (data) => ({
  type: SETTINGS_ERROR,
  payload: data,
});

export const clearSettingsError = (data) => ({
  type: CLEAR_SETTINGS_ERROR,
  payload: data,
});
