import { GET_ZIPS, CREATE_ZIP, DELETE_ZIP, ZIP_ERROR, CLEAR_ZIP_ERROR } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_ZIP:
            return { ...state, zips: [action.zip, ...state.zips] };
        case GET_ZIPS:
            return { ...state, zips: action.zips };
        case DELETE_ZIP:
            return { ...state, zips: state.zips.filter(zipCode => zipCode._id !== action.id) };
        case ZIP_ERROR:
            return { ...state, error: action.error };
        case CLEAR_ZIP_ERROR:
            return { ...state, error: '' };
        default:
            return state;
    }
};

