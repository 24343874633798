import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MaskedInput from "react-input-mask";

import { useForm, Controller, ErrorMessage } from "react-hook-form";

import { getUser, updateCustomer } from "../../../../actions/users";

function Customer({ customer, user, handleCancelEditCustomer }) {
  const dispatch = useDispatch();

  const [c, setC] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (customer) {
      dispatch(getUser(customer._id));
    }
  }, [customer]);

  useEffect(() => {
    if (user) {
      setC(user);
    }
  }, [user]);

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
  });

  const handleCancel = () => {
    if (editMode && c) {
      setEditMode(false);
    } else {
      handleCancelEditCustomer();
    }
  };

  const onSubmit = (formData) => {
    dispatch(
      updateCustomer(c._id, formData, () => {
        dispatch(getUser(c._id));
        setEditMode(false);
      })
    );
  };

  return (
    <div className="dashboard-container">
      {c && (
        <div className="dashboard-panel-container">
          <div className="action-links-container">
            {!editMode && c && (
              <button onClick={() => setEditMode(true)}>Edit</button>
            )}
            {editMode && <button onClick={() => handleCancel()}>Cancel</button>}
          </div>

          <div id="app-user">
            <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-input">
                  <label htmlFor="firstName">First Name</label>
                  {!editMode && <p className="current-value">{c.firstName}</p>}
                  {editMode && (
                    <input
                      type="text"
                      name="firstName"
                      ref={register}
                      defaultValue={c.firstName}
                    />
                  )}
                </div>

                <div className="form-input">
                  <label htmlFor="lastName">Last Name</label>
                  {!editMode && <p className="current-value">{c.lastName}</p>}
                  {editMode && (
                    <input
                      type="text"
                      name="lastName"
                      ref={register}
                      defaultValue={c.lastName}
                    />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-input">
                  <label htmlFor="email">Email</label>
                  {!editMode && <p className="current-value">{c.email}</p>}
                  {editMode && (
                    <input
                      defaultValue={c.email}
                      type="text"
                      name="email"
                      ref={register({
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Please enter a valid email address.",
                        },
                      })}
                    />
                  )}
                  <ErrorMessage errors={errors} name="email">
                    {({ message }) => (
                      <p className="form-error-message">{message}</p>
                    )}
                  </ErrorMessage>
                </div>

                <div className="form-input">
                  <label htmlFor="phone">Phone</label>
                  {!editMode && <p className="current-value">{c.phone}</p>}
                  {editMode && (
                    <Controller
                      as={
                        <MaskedInput
                          mask="(999) 999-9999"
                          maskplaceholder={"_"}
                          alwaysShowMask
                        ></MaskedInput>
                      }
                      name="phone"
                      defaultValue={c.phone}
                      control={control}
                    />
                  )}
                </div>

                {editMode && (
                  <div className="form-input">
                    <label htmlFor="password">Temporary Password</label>
                    <input type="password" name="password" ref={register} />
                  </div>
                )}

                <div className="form-row">
                  <div className="form-input">
                    <label htmlFor="address">Address</label>
                    {!editMode && <p className="current-value">{c.address}</p>}

                    {editMode && (
                      <input
                        type="text"
                        name="address"
                        defaultValue={c.address}
                        ref={register}
                      />
                    )}
                  </div>

                  <div className="form-input">
                    <label htmlFor="addressLine2">Address Line 2</label>
                    {!editMode && (
                      <p className="current-value">{c.addressLine2}</p>
                    )}
                    {editMode && (
                      <input
                        type="text"
                        name="addressLine2"
                        defaultValue={c.addressLine2}
                        ref={register}
                      />
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-input">
                    <label htmlFor="city">City</label>
                    {!editMode && <p className="current-value">{c.city}</p>}
                    {editMode && (
                      <input
                        type="text"
                        name="city"
                        ref={register}
                        defaultValue={c.city}
                      />
                    )}
                  </div>

                  <div className="form-input">
                    <label htmlFor="state">State</label>
                    {!editMode && <p className="current-value">{c.state}</p>}
                    {editMode && (
                      <input
                        type="text"
                        name="state"
                        defaultValue={c.state || "NY"}
                        ref={register}
                      />
                    )}
                  </div>

                  <div className="form-input">
                    <label htmlFor="zip">Zip</label>
                    {!editMode && <p className="current-value">{c.zip}</p>}
                    {editMode && (
                      <input
                        type="text"
                        name="zip"
                        ref={register}
                        defaultValue={c.zip}
                      />
                    )}
                  </div>
                </div>

                <div className="form-row">
                  {!editMode && (
                    <p className="current-value">
                      {c.sms
                        ? "Text Notifications: Enabled"
                        : "Text Notifications: Disabled"}
                    </p>
                  )}
                  {editMode && (
                    <div className="form-input checkbox-input">
                      <input
                        defaultChecked={c.sms}
                        type="checkbox"
                        name="sms"
                        ref={register}
                      />
                      <label htmlFor="sms">
                        Send text notifications with updates about orders.
                      </label>
                    </div>
                  )}
                </div>
              </div>

              {editMode && (
                <div className="action-button-container">
                  <input
                    className="submit-button bg-green"
                    type="submit"
                    value="Save"
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.users.user,
  };
};

export default connect(mapStateToProps)(Customer);
