import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { createLocation } from "../../../../actions/locations";

function NewLocation(props) {
  const [editMode, setEditMode] = useState(false);

  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleCancel = () => {
    props.handleCancelEditLocation();
  };
  console.log(errors);

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      createLocation(formData, () => {
        props.handleCancelEditLocation();
      })
    );
  };
  const onErrors = () => {
    console.log(errors);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-panel-container">
        <div className="action-links-container">
          {!editMode && <button onClick={() => setEditMode(true)}>Edit</button>}
          {editMode && <button onClick={() => handleCancel()}>Cancel</button>}
        </div>

        <div id="app-user">
          <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-input">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="address">Address</label>
                <input type="text" name="address" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="city">City</label>
                <input type="text" name="city" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="state">State</label>
                <input type="text" name="state" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="zip">Zip</label>
                <input type="text" name="zip" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="phone">Phone</label>
                <input type="text" name="phone" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="hours">Hours</label>
                <input type="text" name="hours" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="long">Longitude</label>
                <input
                  type="text"
                  name="long"
                  ref={register({ required: "Field Required" })}
                />
              </div>
              <div className="form-input">
                <label htmlFor="lat">Latitude</label>
                <input
                  type="text"
                  name="lat"
                  ref={register({ required: "Field Required" })}
                />
              </div>
            </div>
            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(NewLocation);
