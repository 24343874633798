function calculateWashFold({ weight, products, settings, isFlorida }) {
  console.log(products);
  let productsWithPrices = [];

  let {
    washFoldMinDelivery,
    washFoldUpToXLbs,
    washFoldPerLb,
  } = settings.settings;

  let price =
    washFoldMinDelivery + parseInt(weight - washFoldUpToXLbs) * washFoldPerLb;

  if (products) {
    settings.products.forEach((p) => {
      if (products[p.item]) {
        price += p.price;
        productsWithPrices.push([p.item, p.price.toFixed(2)]);
      }
    });
  }

  return { price: price, products: productsWithPrices };
}

module.exports = calculateWashFold;
