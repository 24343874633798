import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import ZipForm from "../../global/components/ZipForm/ZipForm";

import { getSettings } from "../../actions/settings";
import { getZips } from "../../actions/zips";

import "./Pricing.scss";
import {
  getSettingsFlorida,
  setFloridaStatus,
} from "../../actions/settings_florida";

function Pricing(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [self, setSelf] = useState(false);
  const [organic, setOrganic] = useState(false);
  const [pounds, setPounds] = useState();
  const [washFoldQuote, setWashFoldQuote] = useState();
  const [washFoldError, setWashFoldError] = useState();
  const [dryCleanItem, setDryCleanItem] = useState();
  const [dryCleanQuote, setDryCleanQuote] = useState([]);
  const [dryCleanVariations, setDryCleanVariations] = useState();
  const [tailorItem, setTailorItem] = useState();
  const [tailorService, setTailorService] = useState({
    hem: false,
    patch: false,
    fix: false,
    lining: false,
    taper: false,
    zipper: false,
    sleeves: false,
    waist: false,
  });
  const [tailorQuote, setTailorQuote] = useState(0);
  const [tailorError, setTailorError] = useState();
  const [washFoldMinDelivery, setWashFoldMinDelivery] = useState(29);
  const [washFoldUpToXLbs, setWashFoldUpToXLbs] = useState();
  const [washFoldPerLb, setWashFoldPerLb] = useState();
  const [organicWashFoldUpToXLbs, setOrganicWashFoldUpToXLbs] = useState();
  const [organicWashFoldPerLb, setOrganicWashFoldPerLb] = useState();
  const [foundTailorItem, setFoundTailorItem] = useState();

  const [organicWashFoldMinDelivery, setOrganicWashFoldMinDelivery] = useState(
    32
  );

  useEffect(() => {
    setLoading(false);
    dispatch(getSettings());
    dispatch(getSettingsFlorida());
    dispatch(getZips());
  }, []);

  useEffect(() => {
    if (props.settingsFlorida.settings) {
      setWashFoldMinDelivery(
        props.settingsFlorida.settings.washFoldMinDelivery
      );
      setOrganicWashFoldMinDelivery(
        props.settingsFlorida.settings.organicWashFoldMinDelivery
      );
      setWashFoldUpToXLbs(props.settingsFlorida.settings.washFoldUpToXLbs);
      setWashFoldPerLb(props.settingsFlorida.settings.washFoldPerLb);
      setOrganicWashFoldUpToXLbs(
        props.settingsFlorida.settings.organicWashFoldUpToXLbs
      );
      setOrganicWashFoldPerLb(
        props.settingsFlorida.settings.organicWashFoldPerLb
      );

      //  setFoundTailorItem(props.settingsFlorida.tailoringServices[0]);
    }
  }, [props.settingsFlorida]);

  useEffect(() => {
    if (tailorItem) {
      let foundServiceItem =
        props.settingsFlorida.tailoringServices &&
        props.settingsFlorida.tailoringServices.find((service) => {
          return service.item == tailorItem;
        });

      setFoundTailorItem(foundServiceItem);
    }
  }, [tailorItem]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let quote;

    // we should make this configurable in the future
    if (self) {
      if (pounds < 8) {
        setWashFoldError("Self drop off orders must be a minimum of 8 lbs.");
        return;
      }
      if (pounds > 8) {
        if (organic) {
          quote = (pounds - 8) * organicWashFoldPerLb + 10;
        } else {
          quote = (pounds - 8) * washFoldPerLb + 10;
        }
      } else {
        quote = 10;
      }
    }

    if (!self) {
      if (organic) {
        if (pounds < organicWashFoldUpToXLbs) {
          setWashFoldError(
            `Pickup/delivery orders must be a minimum of ${organicWashFoldUpToXLbs} lbs.`
          );
          return;
        }
      } else if (pounds < washFoldUpToXLbs) {
        setWashFoldError(
          `Pickup/delivery orders must be a minimum of ${washFoldUpToXLbs} lbs.`
        );
        return;
      }

      if (organic) {
        quote =
          organicWashFoldMinDelivery +
          (pounds - organicWashFoldUpToXLbs) * organicWashFoldPerLb;
      }

      if (!organic) {
        quote =
          washFoldMinDelivery + (pounds - washFoldUpToXLbs) * washFoldPerLb;
      }
    }

    setWashFoldQuote(`${pounds}lbs for only $${quote.toFixed(2)}`);
  };

  const handleTailorSubmit = (e) => {
    e.preventDefault();
    let quote = 0;

    if (!tailorItem) {
      setTailorError("Please select a clothing type.");
      return;
    }

    let foundServiceItem =
      props.settingsFlorida.tailoringServices &&
      props.settingsFlorida.tailoringServices.find((service) => {
        return service.item == tailorItem;
      });

    if (tailorService.hem) {
      quote += foundServiceItem.options.hem;
    }

    if (tailorService.taper) {
      quote += foundServiceItem.options.taper;
    }

    if (tailorService.lining) {
      quote += foundServiceItem.options.lining;
    }

    if (tailorService.patch) {
      quote += foundServiceItem.options.patch;
    }

    if (tailorService.fix) {
      quote += foundServiceItem.options.fix;
    }

    if (tailorService.zipper) {
      quote += foundServiceItem.options.zipper;
    }

    if (tailorService.waist) {
      quote += foundServiceItem.options.waist;
    }

    if (tailorService.sleeves) {
      quote += foundServiceItem.options.sleeves;
    }

    setTailorQuote(quote.toFixed(2));
  };

  const getDryCleanPrice = (e) => {
    let item = e.target.value;

    let foundServiceItem =
      props.settingsFlorida.dryCleanServices &&
      props.settingsFlorida.dryCleanServices.find((service) => {
        return service.item == item;
      });

    setDryCleanItem(item);

    if (foundServiceItem.options.press) {
      setDryCleanQuote([
        foundServiceItem.options.press.dryClean,
        foundServiceItem.options.press.pressOnly,
      ]);
    } else {
      setDryCleanQuote([foundServiceItem.options.base, null]);
    }

    switch (item) {
      case "Dress":
        setDryCleanVariations(
          "Silk, Satin, Velvet, Taffeta, Matte Jersey, Cotton, Linen, Corduroy, Denim, Heavy Knit, Suede Trim, White, Slip, Lined, Sequins, Beads, 2 Piece, Sizing, Flair, Pleats, Organza, Crepe, Chiffon"
        );
        break;
      case "Suit":
        setDryCleanVariations(
          "Satin, Velvet, Taffeta, Cototn, Linen, Corduroy, Denim, Cashmere, Mohair, Heavy Knit, Leather Trim, Flair, Slip, Fully Lined, White, Sizing, 3 Piece, Tuxedo"
        );
        break;
      case "Jumpsuit":
        setDryCleanVariations(
          "Ultra Suede, Satin, Silk, Coton, Linen, Velvet, Heavy Knit, White"
        );
        break;
      case "Accessory":
        setDryCleanVariations(
          "Satin, Taffeta, Velvet, Silk, Cotton, Linen, White, Wool, Leather, Suede, Cashmere"
        );
        break;
      case "Shirt":
        setDryCleanVariations(
          "Ultra Suede, Satin, Silk, Coton, Linen, Velvet, Heavy Knit, White"
        );
        break;
      case "Slacks":
        setDryCleanVariations(
          "Velvet, Satin, Silk, Cotton, Linen, Corduroy, Denim, Lined, Sizing, Leather, White"
        );
        break;
      case "Sweater":
        setDryCleanVariations(
          "Cashmere, Mohair, Angora Blend, Angora, White, Bulky, Hooded, Hip Length, Suede, Leather Trim, Fur Collar, Sequins, Beads"
        );
        break;
      case "Blazer":
        setDryCleanVariations(
          "Satin, Velvet, Taffeta, Ultra Suede, Cotton, Linen, Corduroy, Denim, Cashmere, Mohair, Heavy Knit, Suede, Leather Trim, Flair, Slip, Fully Lined, White, Sizing, Tuxedo"
        );
        break;
      case "Skirt":
        setDryCleanVariations(
          "Satin, Velvet, Taffeta, Silk, Ultra Suede, Cashmere, Mohair, Corduroy, Cotton, Linen, White, Flair, Slip, Lined, Heavy Leather, Knit, Long, Pleats"
        );
        break;
      case "Coat":
        setDryCleanVariations(
          "Heavy Wool, Heavy Knitted, Raincoat, Down, Velvet, Silk, Satin, Cashmere, Mohair, Ultra Suede, Pile Lined, Leather Trim, Suede Trim, Hooded, Long, Water Repellant, Zip Out Lining, Full Collar, Cuffs, Bottom, Fiber Fill, Leather, Suede"
        );
        break;
      case "Outer Jacket":
        setDryCleanVariations(
          "Down, 3/4, Heavy Weight, Satin, Silk, Pile, Lined, Leather, Suede, Trim, Heavy Knit, Medium Weight, Fur Collar, Fur Cuffs, Fur Bottom, Fiber Fill, Zip Out Lining, Water Repellant"
        );
        break;
      case "Blanket":
        setDryCleanVariations("Size, Weight, Fabric");
        break;
      case "Sport Jacket":
        setDryCleanVariations(
          "Cashmere, Mohair, Silk, Velvet, Cotton, Linen, Corduroy, Denim, Ultra Suede, Leather Trim, Suede Trim, White, Fully Lined, Dinner Jacket, Leather, Suede"
        );
        break;
      case "Gown":
        setDryCleanVariations(
          "Silk, Satin, Velvet, Taffeta, Ultra Suede, Matte Jersey, Flair, Sequins, Beads, White, Slip, Lined, 2 Piece, Pleats, Organza, Crepe, Chiffon"
        );
        break;
      case "Comforter":
        setDryCleanVariations("Size, Weight, Fabric, Filling");
        break;
      default:
        return;
    }
  };

  const handleChangeTailorService = (service) => {
    let currentService = tailorService[service];
    setTailorService({ ...tailorService, [service]: !currentService });
  };

  return (
    <div
      id="pricing"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <div id="pricing-hero" className="flex-container section">
        <div className="half centered">
          <div className="content-container">
            <NavLink
              to="/pricing"
              onClick={() => dispatch(setFloridaStatus(false))}
            >
              <div
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                <button>See New York Pricing</button>
              </div>
            </NavLink>
            <h1 className="green-text">
              Laundry day.<span className="orange-text"> Done.</span>
            </h1>
            <h3>
              Your time is priceless. Let us do your laundry. Folded. Pressed.
              Delivered.{" "}
              <span>
                Enjoy Florida's best service at the best prices anywhere.
              </span>
            </h3>
          </div>
        </div>
        <div className="half image-container half centered">
          <img src="/images/pricing-hero.png" />
        </div>

        <div className="sticker">
          <span>
            Current
            <br />
            Special
          </span>
          <span>Free</span>
          <span>Pick Up & Delivery</span>
        </div>
      </div>

      <div id="wash-fold" className="section-small flex-container">
        <div className="half centered">
          <div className="content-container flex-container">
            <img className="half" src="/images/pricing-washfold-clothes.png" />
            <div className="half">
              <h1 className="green-text">Wash & Fold</h1>
              <p>
                From a few articles of clothing to your entire closet, wash &
                fold service is our specialty! Pick up & delivery and same day
                service available. Special instructions are no problem. Enjoy
                fresh, clean laundry, and choose our Organic Service for a
                premium touch. 6 lb. minimum for self drop-off service.
              </p>
            </div>
          </div>
        </div>
        <div className="half centered">
          {props.settingsFlorida && (
            <div className="widget-container">
              <div id="wash-fold-calculator" className="widget">
                <h3>Wash & Fold Calculator</h3>
                <p>
                  Prices start at $10 for 8 lbs self drop off, or $
                  {washFoldMinDelivery} for up to {washFoldUpToXLbs} lbs. Pick
                  up & delivery included.
                </p>
                <form className="calculator-form" onSubmit={handleSubmit}>
                  <div className="radio-group">
                    <div className="radio-item">
                      <input
                        type="radio"
                        id="pickupdelivery"
                        name="self"
                        value="false"
                        onChange={(e) => setSelf(false)}
                        checked={!self}
                      />
                      <label htmlFor="pickupdelivery">Pick up & delivery</label>
                    </div>

                    <div className="radio-item">
                      <input
                        type="radio"
                        id="self"
                        name="self"
                        value="true"
                        onChange={(e) => setSelf(true)}
                        checked={self}
                      />
                      <label htmlFor="self">Self drop off</label>
                    </div>
                  </div>

                  <div className="radio-group">
                    <div className="radio-item">
                      <input
                        type="radio"
                        id="regular"
                        name="organic"
                        value="false"
                        onChange={(e) => {
                          setOrganic(false);
                          setWashFoldQuote(null);
                        }}
                        checked={!organic}
                      />
                      <label htmlFor="regular">Regular laundry service</label>
                    </div>

                    <div className="radio-item">
                      <input
                        type="radio"
                        id="organic"
                        name="organic"
                        value="true"
                        onChange={(e) => {
                          setOrganic(true);
                          setWashFoldQuote(null);
                        }}
                        checked={organic}
                      />
                      <label htmlFor="organic">Organic service</label>
                    </div>
                  </div>

                  <input
                    type="number"
                    id="pounds"
                    name="pounds"
                    placeholder="How many pounds?"
                    onChange={(e) => {
                      setPounds(e.target.value);
                      setWashFoldError(null);
                      setWashFoldQuote(null);
                    }}
                  />
                  <input type="submit" value="Calculate" />

                  {washFoldError && (
                    <div className="widget-form-error">{washFoldError}</div>
                  )}
                </form>

                {washFoldQuote && (
                  <div className="calculator-results">
                    <div>{washFoldQuote}</div>
                  </div>
                )}

                <div className="pricing-page-zip-form">
                  <p>Enter your zip code to get started.</p>
                  <ZipForm />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div id="dry-cleaning" className="section-small flex-container">
        <div className="half centered">
          <div className="widget-container">
            <div className="widget" id="dry-cleaning-calculator">
              <h3>Organic Dry Cleaning Price List</h3>
              <form className="calculator-form">
                <div class="select-wrapper">
                  <img className="down-caret" src="/images/down-caret.png" />
                  <select name="type" id="type" onChange={getDryCleanPrice}>
                    <option value="" disabled selected>
                      Select Clothing Type
                    </option>

                    {props.settingsFlorida.dryCleanServices &&
                      props.settingsFlorida.dryCleanServices.map((service) => {
                        return (
                          <option value={service.item}>{service.item}</option>
                        );
                      })}
                  </select>
                </div>
              </form>
              <div className="calculator-results">
                <div className="calculator-total">
                  <div>{dryCleanItem}</div>
                  {dryCleanQuote && dryCleanQuote[0] && (
                    <div>
                      ${dryCleanQuote[0]} <span>Base Price</span>
                    </div>
                  )}
                </div>

                {dryCleanVariations && (
                  <div className="calculator-details">
                    <span>
                      The following variations will affect final price:
                    </span>
                    {dryCleanVariations}
                  </div>
                )}
                <div className="calculator-max">
                  <div>Maximum Price</div>
                  <div>$80</div>
                </div>
              </div>

              <div className="pricing-page-zip-form">
                <p>Enter your zip code to get started.</p>
                <ZipForm />
              </div>
            </div>
          </div>
        </div>
        <div className="half centered">
          <div className="content-container flex-container">
            <div className="half">
              <h1 className="green-text">
                <span className="orange-text">Organic</span>
                <br />
                Dry Cleaning
              </h1>
              <p>
                Your very best, pressed for less! You choose where we pick up
                and deliver. Once your dry cleaning is in our hands, it gets the
                royal treatment as our team of professionals restores your
                clothing to pristine condition through our very own, totally
                organic process. Expect perfectly cleaned and pressed apparel
                every time – free of stains, free of wrinkles and free of
                disappointment.{" "}
              </p>
            </div>
            <div className="image-container half">
              <img alt="" src="/images/pricing-drycleaning-dress.png" />
            </div>
          </div>
        </div>
      </div>

      <div id="pressed" className="section-small flex-container">
        <div className="half image-container half centered">
          <div className="hanging-shirts">
            <img
              className="shirts"
              alt=""
              src="/images/pricing-hanging-shirts-fl.png"
            />
          </div>
          <div className="pressed-shirts">
            <img
              className="shirts"
              alt=""
              src="/images/pricing-pressed-shirts-fl.png"
            />
          </div>
        </div>

        <div className="half">
          <div className="content-container">
            <h1 className="green-text">
              Fresh, Clean and Green!
              <br />
              <span className="orange-text">
                Organic Laundered
                <br />& Pressed Shirts
              </span>
            </h1>
            <p>
              Whether you prefer pressed and on a hanger, or hand pressed and
              delivered, your designer cotton shirts will be fresh and clean and
              prepared just the way you like them.
            </p>
          </div>
        </div>
      </div>

      <div id="tailoring" className="section-small flex-container">
        <div className="half centered">
          <div className="widget-container">
            <div className="widget" id="tailor-calculator">
              <h3>Tailoring Calculator</h3>

              <form className="calculator-form" onSubmit={handleTailorSubmit}>
                <div class="select-wrapper">
                  <img className="down-caret" src="/images/down-caret.png" />
                  <select
                    name="type"
                    id="type"
                    onChange={(e) => {
                      setTailorItem(e.target.value);
                      setTailorError("");
                    }}
                  >
                    <option value="" disabled selected>
                      Select Clothing Type
                    </option>

                    {props.settingsFlorida.tailoringServices &&
                      props.settingsFlorida.tailoringServices.map((service) => {
                        return (
                          <option value={service.item}>{service.item}</option>
                        );
                      })}
                  </select>
                </div>

                {tailorItem && foundTailorItem && (
                  <div className="checkbox-group">
                    {foundTailorItem && foundTailorItem.options.hem && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="hem"
                          name="tailoring"
                          value="hem"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="hem">Hem</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.sleeves && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="sleeves"
                          name="tailoring"
                          value="sleeves"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="sleeves">Sleeves</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.taper && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="taper"
                          name="tailoring"
                          value="taper"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="taper">Taper</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.waist && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="waist"
                          name="tailoring"
                          value="waist"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="waist">Waist</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.patch && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="patch"
                          name="tailoring"
                          value="patch"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="patch">Patch</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.fix && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="fix"
                          name="tailoring"
                          value="fix"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="fix">Fix</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.lining && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="lining"
                          name="tailoring"
                          value="lining"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="lining">Lining</label>
                      </div>
                    )}

                    {foundTailorItem && foundTailorItem.options.zipper && (
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="zipper"
                          name="tailoring"
                          value="zipper"
                          onChange={(e) =>
                            handleChangeTailorService(e.target.value)
                          }
                        />
                        <label htmlFor="zipper">Zipper</label>
                      </div>
                    )}
                  </div>
                )}
                {tailorError && (
                  <div className="widget-form-error">{tailorError}</div>
                )}

                <input type="submit" value="Estimate" />
              </form>

              <div className="calculator-results">
                {tailorQuote != 0 && (
                  <div className="calculator-max">
                    <div>Approximate Price</div>
                    <div>${tailorQuote}</div>
                  </div>
                )}
              </div>

              <div className="pricing-page-zip-form">
                <p>Enter your zip code to get started.</p>
                <ZipForm />
              </div>
            </div>
          </div>
        </div>
        <div className="half centered">
          <div className="content-container flex-container">
            <div className="half">
              <h1 className="green-text">Tailoring</h1>
              <p>
                Your very best clothes deserve to fit the way you want them to.
                We provide expert, custom tailoring for your favorite garments –
                both men’s and women’s. Fashion is our passion. We’re always au
                courant on the latest trends, designs and fabrics. Our
                relentless attention to detail translates into flawless repairs
                and adjustments. No request is too small, no challenge too
                daunting.
              </p>
            </div>
            <div className="half">
              <img src="/images/pricing-tailoring-mannequin.png" />
            </div>
          </div>
        </div>
      </div>

      <div id="comforter" className="section-small flex-container">
        <div className="sixty-percent centered">
          <div className="content-container flex-container">
            <div className="half image-container centered">
              <img src="/images/pricing-comforter.png" />
            </div>
            <div className="half">
              <h1 className="green-text">
                Clean & Comfy!
                <br />
                <span className="orange-text">
                  Organically cleaned comforters & blankets
                </span>
              </h1>
              <p>
                Everyone wants to get into bed with a comforter that’s been
                perfectly restored to a clean and pristine freshness. There’s no
                better feeling for you and your loved ones than snugging up with
                bedding that’s been given a total, safe, green cleaning. Because
                there’s no environment more important than the one in which you
                sleep.
              </p>
            </div>
          </div>
        </div>
        <div className="forty-percent centered">
          <div className="widget-container">
            <div className="widget">
              <h3>Comforter Price List</h3>
              <div id="comforter-price-list">
                <h4>Comforters</h4>
                <ul>
                  {props.settingsFlorida &&
                    props.settingsFlorida.comforterServices &&
                    props.settingsFlorida.comforterServices.map((service) => {
                      return (
                        <li>
                          <span>{service.item}</span>
                          <span>${service.options.notDown.toFixed(2)}</span>
                        </li>
                      );
                    })}
                </ul>
                <h4>Down Comforters</h4>
                <ul>
                  {props.settingsFlorida &&
                    props.settingsFlorida.comforterServices &&
                    props.settingsFlorida.comforterServices.map((service) => {
                      return (
                        <li>
                          <span>{service.item}</span>
                          <span>${service.options.down.toFixed(2)}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="specials-section" className="flex-container">
        <div className="half text-block-left centered">
          <div className="content-container">
            <h2 className="heading-half green-text">
              <span className="orange-text">Is laundry day getting</span>
              <br />a little overwhelming?
            </h2>
            <p>
              Don’t dispair! Eco Miss Bubble makes laundry day easy with our
              sparkling clean self-service locations, and our easy drop-off
              services. And did we mention the price? The best anywhere, see for
              yourself!
            </p>
            <NavLink to="/specials">
              <div className="button-container">
                <button>Current Specials</button>
              </div>
            </NavLink>
          </div>
        </div>

        <div className="half image-container centered">
          <img src="/images/pricing-specials-laundry.png" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(Pricing);
