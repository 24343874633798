import React from "react";
import { NavLink } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

library.add([faTrash, faPlus]);

function WashPress({ checkoutView, remove, order }) {
  return (
    <li className="order-item">
      <div className="item-name">
        <div className="service-name">
          {!checkoutView && (
            <button onClick={() => remove()}>
              <FontAwesomeIcon icon="trash" />
            </button>
          )}{" "}
          <NavLink to={`/app/order/services/washpress`} className="order-label">
            Wash & Press
          </NavLink>
        </div>
        <div className="service-quote">
          ${parseFloat(order.quote).toFixed(2)}
        </div>
      </div>
      <div className="item-details">
        {order.items.map((item) => {
          // If edit order is coming from an existing order
          if (typeof item.quantity === "number") {
            return (
              <div>
                <p>
                  <span>
                    {item.type} {item.quantity && <>x{item.quantity}</>}
                  </span>
                </p>
              </div>
            );
            // Order has been recently edited
          } else {
            return (
              <div>
                <p>
                  <span>
                    {item.label} {item.quantity && <>x{item.quantity.value}</>}
                  </span>
                </p>
              </div>
            );
          }
        })}
      </div>
    </li>
  );
}

export default WashPress;
