import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { createZip } from "../../../actions/zips";

function NewZipCodeForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    formData.florida = true;
    props.dispatch(
      createZip(formData, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="new-zip">
        <input type="text" name="zip" ref={register} />

        <input className="add submit-button" type="submit" value="Add" />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    zip: state.zip,
  };
};

export default connect(mapStateToProps)(NewZipCodeForm);
