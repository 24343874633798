import {
  AUTHORIZE_USER,
  UNAUTHORIZE_USER,
  AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
  VERIFY_EMAIL,
  UPDATE_ACCOUNT,
  AUTHORIZE_GUEST,
} from "../actions/types";

export default function(state = { error: null, authenticated: false }, action) {
  switch (action.type) {
    case AUTHORIZE_USER:
      return {
        error: null,
        authenticated: true,
        user: action.user,
        authorizedAsGuest: false,
      };
    case UNAUTHORIZE_USER:
      return {
        error: null,
        authenticated: false,
        user: null,
        message: null,
        authorizedAsGuest: false,
      };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_AUTH_ERROR:
      return { ...state, error: null };
    case AUTH_MESSAGE:
      return { ...state, message: action.payload };
    case CLEAR_AUTH_MESSAGE:
      return { ...state, message: null };
    case VERIFY_EMAIL:
      return { ...state, message: action.message };
    case UPDATE_ACCOUNT:
      return { ...state, user: action.user };
    case AUTHORIZE_GUEST:
      return { ...state, authorizedAsGuest: action.authorized };

    default:
      return state;
  }
}
