import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import BannerFooter from "../../global/components/BannerFooter/BannerFooter.js";

import "./Promise.scss";

function Promise(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div
      id="promise"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <div id="promise-hero" className="flex-container section">
        <div className="half centered">
          <div className="content-container">
            <h1 className="green-text">
              Fresh, clean,
              <br />
              <span className="orange-text">and oh so green.</span>
            </h1>
            <p>
              Our organic practices are our promise to you, and to the world.
            </p>
          </div>
        </div>
        <div className="half image-container half centered">
          <img alt="" src="/images/promise-folded-clothes.png" />
        </div>
      </div>

      <div
        id="promise-main"
        style={{
          backgroundImage: `url('/images/promise-background-image.png')`,
        }}
      >
        <div className="eighty-percent">
          <div class="icon-list">
            <div class="icon-list-item">
              <div className="image-container">
                <img
                  alt=""
                  className="icon"
                  src="/images/promise-icon-leaf.png"
                />
              </div>
              <div className="info">
                <p>Organic laundry is better.</p>
                <p>
                  Organic laundering delivers all the visible and invisible
                  benefits that are in demand today. 100% environmentally safe?
                  Check. Non-toxic? Of course. Energy efficient? Absolutely.
                  Anti-bacterial? Naturally. But there’s so much more. Your
                  laundry comes back free of foul odors and stubborn stains,
                  while graced with a fresh, subtle scent and a soft feel for
                  even the most sensitive skin.
                </p>
              </div>
            </div>
            <div class="icon-list-item">
              <div className="image-container">
                <img
                  alt=""
                  className="icon"
                  src="/images/promise-icon-organic.png"
                />
              </div>
              <div className="info">
                <p>Organic is better for your family.</p>
                <p>
                  We only use eco-friendly products in our state-of-the art,
                  energy-efficient washers and dryers. It goes without saying
                  that we use no dyes and only odorless soaps and softeners. Of
                  course, should you have a preference for a particular scent or
                  brand of detergent, simply let us know in note section when
                  placing your order.
                </p>
              </div>
            </div>
            <div class="icon-list-item">
              <div className="image-container">
                <img
                  alt=""
                  className="icon"
                  src="/images/promise-icon-mission.png"
                />
              </div>
              <div className="info">
                <p>Our mission for sustainability.</p>
                <p>
                  You don’t have to love laundry, because we do. Whether you’re
                  a household or a business, we’ll pick up and deliver – on time
                  – all your expertly washed and folded laundry and dry
                  cleaning. Of course, we use only 100% natural soaps that are
                  perfect even for infant clothing, sensitive skins and those
                  suffering from allergies.
                </p>
              </div>
            </div>
            <div class="icon-list-item">
              <div className="image-container">
                <img
                  alt=""
                  className="icon"
                  src="/images/promise-icon-promise.png"
                />
              </div>
              <div className="info">
                <p>Our promise to our customers.</p>
                <p>
                  We’re as choosy about our employees as you are about your
                  clothing and linens. Which is why clients from throughout the
                  metropolitan area come to us... and stay with us. Our staff of
                  friendly, customer-oriented professionals work hard to deliver
                  efficient, on-time service. Plus, they’re always ready to go
                  the extra mile to make sure you are happy.
                </p>
                <p>
                  Every customer is catered to individually. We take your
                  specific needs and preferences very seriously; delivering
                  excellence to your home or business on time, every time.
                </p>
                <p>
                  We currently serve all areas in Manhattan and some parts of
                  Brooklyn and the Bronx. Unlike many of our competitors,{" "}
                  <strong>we work entirely on our own premises</strong>, which
                  gives us full control over the quality and dependability of
                  our services. For a complete list of the cities we service,
                  please see our{" "}
                  <NavLink to="/locations">locations page</NavLink>.
                </p>
                <p>
                  We work hard in order to make your life easier. Our Customer
                  Service team is just a phone call away, 14 hours a day. Call
                  us at (212) 928-WASH to reach a real human - no robots or
                  voicemail. And don’t forget to check back frequently for sales
                  and specials.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BannerFooter />
    </div>
  );
}

export default Promise;
