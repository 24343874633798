function calculateComforter({ items, settings }) {
  let price = 0;
  let services = settings.comforterServices;
  let comforterItems = [];

  items.forEach((item) => {
    let priceListItem = services.find((o) => {
      return o.item === item.value;
    });

    let quantity = item.quantity?.value || 1;

    if (item.type.value === "Down") {
      price += priceListItem.options.down * quantity;
    }

    if (item.type.value === "Not Down") {
      price += priceListItem.options.notDown * quantity;
    }

    comforterItems.push(item);
  });

  return { price, items: comforterItems };
}

module.exports = calculateComforter;
