import { UPDATE_LOCAL_ORDER, RESET_LOCAL_ORDER_STATE, UPDATE_MINIMUM_DELIVERY_SURCHARGE, UPDATE_DRY_CLEAN_SURCHARGE, UPDATE_WASH_PRESS_SURCHARGE, UNAUTHORIZE_USER } from '../actions/types';

export default (state = { address: null }, action) => {

    switch (action.type) {
        case UNAUTHORIZE_USER:
            return {};
        case UPDATE_LOCAL_ORDER:
            return { ...state, [action.key]: action.data };
        case RESET_LOCAL_ORDER_STATE:
            return {};
        case UPDATE_MINIMUM_DELIVERY_SURCHARGE:
            return { ...state, minimumDeliverySurcharge: action.surcharge };
        case UPDATE_DRY_CLEAN_SURCHARGE:
            return { ...state, dryCleanSurcharge: action.surcharge };
        case UPDATE_WASH_PRESS_SURCHARGE:
            return { ...state, washPressSurcharge: action.surcharge };
        default:
            return state;
    }
}