import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import calculateComforter from "../../../../../utils/calculateComforter";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import Select from "react-select";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Comforter(props) {
  const [order, setOrder] = useState();
  const [comforterItems, setComforterItems] = useState();
  const [comforterMinDelivery, setComforterMinDelivery] = useState(32);
  const [isFlorida, setIsFlorida] = useState(false);

  // Refactor later
  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    } else {
      setOrder(props.localOrder);
      if (props.localOrder?.address?.state?.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  const [comforterSizeOptions] = useState([
    {
      label: "Full",
      value: "Full",
    },
    {
      label: "Queen",
      value: "Queen",
    },
    {
      label: "King",
      value: "King",
    },
  ]);

  const { control, errors, handleSubmit, reset, setValue } = useForm({
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    if (isFlorida) {
      // change this to check for Florida zips generally
      if (props.settingsFlorida) {
        setComforterItems(props.settingsFlorida.comforterServices);
        if (props.settingsFlorida.settings) {
          setComforterMinDelivery(
            props.settingsFlorida.settings.comforterMinDelivery
          );
        }
      }
    } else {
      if (props.settings) {
        setComforterItems(props.settings.comforterServices);
        if (props.settings.settings) {
          setComforterMinDelivery(props.settings.settings.comforterMinDelivery);
        }
      }
    }
  }, [props.settings]);

  useEffect(() => {
    if (order && comforterSizeOptions && order.comforterOrder) {
      let items = order.comforterOrder.items.map((comforterItem) => {
        let existingItem;

        if (typeof comforterItem.quantity === "number") {
          existingItem = {
            label: comforterItem.size
              ? comforterItem.size
              : comforterItem.label,
            value: comforterItem.size
              ? comforterItem.size
              : comforterItem.label,
            type: {
              label: comforterItem.type,
              value: comforterItem.type,
            },
            quantity: {
              label: comforterItem.quantity ? comforterItem.quantity : 1,
              value: comforterItem.quantity ? comforterItem.quantity : 1,
            },
          };
        } else {
          existingItem = {
            label: comforterItem.size
              ? comforterItem.size
              : comforterItem.label,
            value: comforterItem.size
              ? comforterItem.size
              : comforterItem.label,
            type: {
              label: comforterItem.type?.label
                ? comforterItem.type?.label
                : comforterItem.label,

              value: comforterItem.type?.label
                ? comforterItem.type?.label
                : comforterItem.label,
            },
            quantity: {
              label: comforterItem.quantity ? comforterItem.quantity.value : 1,
              value: comforterItem.quantity ? comforterItem.quantity.value : 1,
            },
          };
        }

        return existingItem;
      });

      reset({
        items,
      });
    }
  }, [order, comforterSizeOptions]);

  let comforterTypeOptions = [
    { label: "Not Down", value: "Not Down" },
    { label: "Down", value: "Down" },
  ];

  let quantityOptions = [];
  for (let i = 1; i < 21; i++) {
    quantityOptions.push({ label: i, value: i });
  }

  let history = useHistory();

  const onSubmit = (data) => {
    let { price, items } = calculateComforter({
      items: data.items,
      settings: isFlorida ? props.settingsFlorida : props.settings,
    });

    data.quote = price.toFixed(2);
    data.items = items;

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "comforterOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "comforterOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="wash-press-step" className="order-step">
      <h4 className="step-header">
        Please select your comforter size and type.
      </h4>

      {/* <p className="form-error-message">
        For comforter orders under ${comforterMinDelivery}, you will be charged
        the minimum price of ${comforterMinDelivery}.
      </p> */}

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((i, index) => {
          return (
            <div
              key={i.id}
              className="comforter-repeater-module repeater-module"
            >
              <div className="repeater-item">
                <div className="item-name">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={comforterSizeOptions}
                          placeholder="Size"
                        />
                      }
                      control={control}
                      name={`items[${index}]`}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />
                  </div>
                </div>

                <div className="item-options">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={comforterTypeOptions}
                          placeholder="Type"
                        />
                      }
                      control={control}
                      name={`items[${index}].type`}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />

                    <ErrorMessage errors={errors} name={`items[${index}].type`}>
                      {() => (
                        <p className="form-error-message">
                          Please select a size and type.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="item-quantity">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={quantityOptions}
                          defaultValue={{ label: 1, value: 1 }}
                          placeholder="Quantity"
                        />
                      }
                      control={control}
                      name={`items[${index}].quantity`}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`items[${index}].quantity`}
                    >
                      {() => (
                        <p className="form-error-message">
                          Please select a quantity.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="cancel item-cancel">
                    <button
                      className="form-input-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="action-button-container">
          <button
            className="form-add bg-orange"
            type="button"
            onClick={() => {
              append({ type: "Select Item" });
            }}
          >
            <FontAwesomeIcon icon="plus" /> Item
          </button>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.comforterOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(Comforter);
