function calculateDryClean({ items, settings }) {
  let price = 0;
  let services = settings.dryCleanServices;
  let dryCleanItems = [];

  items.forEach((item) => {
    let priceListItem = services.find((o) => {
      return o.item === item.value;
    });

    let itemQuote = 0;
    let quantity = item.quantity?.value || 1;

    if (item.crease) {
      if (item.crease.value === "noCrease") {
        price += priceListItem.options.crease.noCrease * quantity;
        itemQuote += priceListItem.options.crease.noCrease * quantity;
      }
    }

    if (item.press) {
      if (item.press.value === "pressOnly") {
        price += priceListItem.options.press.pressOnly * quantity;
        itemQuote += priceListItem.options.press.pressOnly * quantity;
      } else {
        price += priceListItem.options.press.dryClean * quantity;
        itemQuote += priceListItem.options.press.dryClean * quantity;
      }
    } else {
      price += priceListItem.options.base * quantity;
      itemQuote += priceListItem.options.base * quantity;
    }

    item.itemQuote = itemQuote;
    dryCleanItems.push(item);
  });

  return { price, items: dryCleanItems };
}

module.exports = calculateDryClean;
