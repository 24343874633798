// Redux actions namespacing

// Auth
export const AUTHORIZE_USER = "AUTHORIZE_USER";
export const UNAUTHORIZE_USER = "UNAUTHORIZE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const AUTH_MESSAGE = "AUTH_MESSAGE";
export const CLEAR_AUTH_MESSAGE = "CLEAR_AUTH_MESSAGE";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const AUTHORIZE_GUEST = "AUTHORIZE_GUEST";

// Local Order
export const UPDATE_LOCAL_ORDER = "UPDATE_LOCAL_ORDER";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const RESET_LOCAL_ORDER_STATE = "RESET_LOCAL_ORDER_STATE";
export const UPDATE_MINIMUM_DELIVERY_SURCHARGE =
  "UPDATE_MINIMUM_DELIVERY_SURCHARGE";
export const UPDATE_DRY_CLEAN_SURCHARGE = "UPDATE_DRY_CLEAN_SURCHARGE";
export const UPDATE_WASH_PRESS_SURCHARGE = "UPDATE_WASH_PRESS_SURCHARGE";

// Edit Order
export const SET_ORDER_TO_EDIT = "SET_ORDER_TO_EDIT";
export const UPDATE_EDIT_ORDER = "UPDATE_EDIT_ORDER";
export const RESET_EDIT_ORDER_STATE = "RESET_EDIT_ORDER_STATE";
export const UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE =
  "UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE";
export const UPDATE_EDIT_DRY_CLEAN_SURCHARGE =
  "UPDATE_EDIT_DRY_CLEAN_SURCHARGE";
export const UPDATE_EDIT_WASH_PRESS_SURCHARGE =
  "UPDATE_EDIT_WASH_PRESS_SURCHARGE";

// Order
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const ASSIGN_ORDER_LOCATION = "ASSIGN_ORDER_LOCATION";
export const RESET_ORDER_STATE = "RESET_ORDER_STATE";
export const ORDER_ERROR = "ORDER_ERROR";
export const CLEAR_ORDER_ERROR = "CLEAR_ORDER_ERROR";
export const UPDATE_ORDER_SERVICE = "UPDATE_ORDER_SERVICE";
export const UPDATE_ORDER_SCHEDULE = "UPDATE_ORDER_SCHEDULE";
export const EXPORT_ORDERS = "EXPORT_ORDERS";
export const CLEAR_CSV = "CLEAR_CSV";
export const UPDATE_ORDER = "UPDATE_ORDER";

// Locations
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const CLEAR_LOCATION_ERROR = "CLEAR_LOCATION_ERROR";

// Specials
export const GET_SPECIALS = "GET_SPECIALS";
export const GET_SPECIAL = "GET_SPECIAL";
export const CREATE_SPECIAL = "CREATE_SPECIAL";
export const UPDATE_SPECIAL = "UPDATE_SPECIAL";
export const DELETE_SPECIAL = "DELETE_SPECIAL";
export const SPECIAL_ERROR = "SPECIAL_ERROR";
export const CLEAR_SPECIAL_ERROR = "CLEAR_SPECIAL_ERROR";

// Users
export const CREATE_USER = "CREATE_USER";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USERS = "GET_USERS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const RESET_USER_STATE = "RESET_USER_STATE";
export const EXPORT_CUSTOMERS = "EXPORT_CUSTOMERS";
export const USER_ERROR = "USER_ERROR";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";

// Payment
export const SUBMIT_PAYMENT = "SUBMIT_PAYMENT";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const UPDATE_ORDER_PAYMENT = "UPDATE_ORDER_PAYMENT";
export const REFUND_PAYMENT = "REFUND_PAYMENT";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR";
export const SETUP_INTENT = "SETUP_INTENT";
export const PAYMENT_INTENT = "PAYMENT_INTENT";

// Settings
export const GET_SETTINGS = "GET_SETTINGS";

export const GET_SETTING = "GET_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const SETTINGS_ERROR = "SETTING_ERROR";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTING_ERROR";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";

export const ADD_SERVICE = "ADD_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";

// Settings FLORIDA
export const GET_SETTINGS_FLORIDA = "GET_SETTINGS_FLORIDA";

export const GET_SETTING_FLORIDA = "GET_SETTING_FLORIDA";
export const UPDATE_SETTING_FLORIDA = "UPDATE_SETTING_FLORIDA";
export const SETTINGS_ERROR_FLORIDA = "SETTING_ERROR_FLORIDA";
export const CLEAR_SETTINGS_ERROR_FLORIDA = "CLEAR_SETTING_ERROR_FLORIDA";
export const UPDATE_SETTINGS_FLORIDA = "UPDATE_SETTINGS_FLORIDA";

export const ADD_PRODUCT_FLORIDA = "ADD_PRODUCT_FLORIDA";
export const DELETE_PRODUCT_FLORIDA = "DELETE_PRODUCT_FLORIDA";
export const UPDATE_PRODUCT_FLORIDA = "UPDATE_PRODUCT_FLORIDA";

export const ADD_SERVICE_FLORIDA = "ADD_SERVICE_FLORIDA";
export const DELETE_SERVICE_FLORIDA = "DELETE_SERVICE_FLORIDA";
export const UPDATE_SERVICE_FLORIDA = "UPDATE_SERVICE_FLORIDA";
export const SET_FLORIDA_STATUS = "SET_FLORIDA_STATUS";
// Zips
export const GET_ZIPS = "GET_ZIPS";
export const CREATE_ZIP = "CREATE_ZIP";
export const DELETE_ZIP = "DELETE_ZIP";
export const ZIP_ERROR = "ZIP_ERROR";
export const CLEAR_ZIP_ERROR = "CLEAR_ZIP_ERROR";
