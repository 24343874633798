function checkPromo(special, order) {


    let discount = 0;
    let services = special.services[0];
    let isEligible = true;

    let oneTimeDiscountApplied = false;

    if (services.dryClean) {
        if (!order.dryCleanOrder) {
            isEligible = false;
        }
    }

    if (services.tailoring) {
        if (!order.tailoringOrder) {
            isEligible = false;
        }
    }

    if (services.washFold) {
        if (!order.washFoldOrder) {
            isEligible = false;
        }
    }

    if (services.organicWashFold) {
        if (!order.organicWashFoldOrder) {
            isEligible = false;
        }
    }

    if (services.washPress) {
        if (!order.washPressOrder) {
            isEligible = false;
        }
    }

    if (services.comforter) {
        if (!order.comforterOrder) {
            isEligible = false;
        }
    }



    // Dry Clean Special ------------------------------------------------------->>
    if (order.dryCleanOrder && services.dryClean) {

        let dryCleanSpecialItems = special.dryCleanItems[0];
        let dryCleanDiscountAmount = 0;
        let newQuote;

        // Apply % discount to all eligible items for eligible services 
        if (special.percentDiscount && dryCleanSpecialItems) {
            // For each eligible dry clean item
            // Apply % discount 
            order.dryCleanOrder.items.forEach(orderItem => {
                if (dryCleanSpecialItems[orderItem.value.toLowerCase()]) {
                    let itemQuote = orderItem.itemQuote;
                    newQuote = itemQuote - (itemQuote * (special.percentDiscount / 100));
                    newQuote = parseFloat(newQuote);
                    dryCleanDiscountAmount = itemQuote - newQuote;
                }
            })
        }


        // Apply new price to all eligible items for eligible services 
        if (special.promotionalPrice) {
            // For each eligible dry clean item
            // Apply % discount 
            order.dryCleanOrder.items.forEach(orderItem => {
                if (dryCleanSpecialItems[orderItem.value.toLowerCase()]) {
                    let itemQuote = orderItem.itemQuote;


                    let quantity = orderItem.quantity ? orderItem.quantity.value : 1;

                    newQuote = special.promotionalPrice * quantity;
                    newQuote = parseFloat(newQuote);

                    dryCleanDiscountAmount = parseFloat(itemQuote - newQuote);

                }
            })

        }

        // Discount amount applied once per entire order
        if (special.discountAmount) {

            if (!oneTimeDiscountApplied) {
                dryCleanDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }

        }

        discount += parseFloat(dryCleanDiscountAmount);
    }

    // Tailoring Special ------------------------------------------------------->>

    if (order.tailoringOrder && services.tailoring) {

        let tailoringSpecialItems = special.tailoringItems[0];
        let tailoringDiscountAmount = 0;
        let newQuote;

        // Apply % discount to all eligible items for eligible services 
        if (special.percentDiscount && tailoringSpecialItems) {

            // For each eligible dry clean item
            // Apply % discount 
            order.tailoringOrder.items.forEach(orderItem => {
                if (tailoringSpecialItems[orderItem.value.toLowerCase()]) {
                    let itemQuote = orderItem.itemQuote;
                    newQuote = itemQuote - (itemQuote * (special.percentDiscount / 100));
                    newQuote = parseFloat(newQuote);
                    tailoringDiscountAmount = itemQuote - newQuote;
                }
            })
        }

        // Apply new price to all eligible items for eligible services 
        if (special.promotionalPrice) {

            // For each eligible dry clean item
            // Apply % discount 
            order.tailoringOrder.items.forEach(orderItem => {
                if (tailoringSpecialItems[orderItem.value.toLowerCase()]) {
                    let itemQuote = orderItem.itemQuote;

                    let quantity = orderItem.quantity ? orderItem.quantity.value : 1;

                    newQuote = special.promotionalPrice * quantity;
                    newQuote = parseFloat(newQuote);

                    tailoringDiscountAmount = parseFloat(itemQuote - newQuote);

                }
            })
        }

        // Discount amount applied once per entire order
        if (special.discountAmount) {

            if (!oneTimeDiscountApplied) {
                tailoringDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }

        }

        discount += parseFloat(tailoringDiscountAmount);
    }


    // Wash Fold Special ------------------------------------------------------->>
    if (order.washFoldOrder && services.washFold) {

        let washFoldDiscountAmount = 0;
        let washFoldQuote = order.washFoldOrder.quote;

        // Promotional Price applies to each pound for this service
        if (special.promotionalPrice) {
            let newQuote = parseInt(order.washFoundOrder.weight * special.promotionalPrice);
            washFoldDiscountAmount = washFoldQuote - newQuote;
        }

        // Percent discount applies to total for this service
        if (special.percentDiscount) {
            let newQuote = washFoldQuote - (washFoldQuote * (special.percentDiscount / 100));
            washFoldDiscountAmount = washFoldQuote - newQuote;
        }

        // Discount amount applied once per entire order
        if (special.discountAmount) {
            if (!oneTimeDiscountApplied) {
                washFoldDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }

        }

        discount += washFoldDiscountAmount;
    }

    // Organic Wash Fold Special
    if (order.organicWashFoldOrder && services.organicWashFoldOrder) {

        let organicWashFoldDiscountAmount = 0;
        let organicWashFoldQuote = order.organicWashFoldOrder.quote;

        // Promotional Price applies to each pound for this service
        if (special.promotionalPrice) {
            let newQuote = parseInt(order.washFoundOrder.weight) * special.promotionalPrice;
            organicWashFoldDiscountAmount = organicWashFoldQuote - newQuote;
        }

        // special applies to total for this service
        if (special.percentDiscount) {
            let newQuote = organicWashFoldQuote - (organicWashFoldQuote * (special.percentDiscount / 100));
            organicWashFoldDiscountAmount = organicWashFoldQuote - newQuote;
        }

        // Discount amount applied once per entire order
        if (special.discountAmount) {

            if (!oneTimeDiscountApplied) {
                organicWashFoldDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }
        }
        discount += organicWashFoldDiscountAmount;
    }

    // Wash Press Special
    if (order.washPressOrder && services.washPress) {

        let washPressDiscountAmount = 0;
        let washPressQuote = order.washPressOrder.quote;
        let washPressNumberItems = 0;

        // Get total number of items for this service
        order.washPressOrder.items.forEach(item => {

            let quantity = item.quantity ? item.quantity.value : 1;

            washPressNumberItems += quantity;
        })

        // Promotional Price applies to each item for this service 
        if (special.promotionalPrice) {
            let newQuote = washPressNumberItems * special.promotionalPrice;
            washPressDiscountAmount = washPressQuote - newQuote;
        }

        // special applies to total for this service
        if (special.percentDiscount) {
            let newQuote = washPressQuote - (washPressQuote * (special.percentDiscount / 100));
            washPressDiscountAmount = washPressQuote - newQuote;
        }

        // Discount amount applied once for this service
        if (special.discountAmount) {

            if (!oneTimeDiscountApplied) {
                washPressDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }
        }

        discount += washPressDiscountAmount;
    }

    // Comforter Special
    if (order.comforterOrder && services.comforter) {

        let comforterDiscountAmount = 0;
        let comforterQuote = order.comforterOrder.quote;
        let comforterNumberItems = 0;

        // Get total number of items for this service
        order.comforterOrder.items.forEach(item => {

            let quantity = item.quantity ? item.quantity.value : 1;


            comforterNumberItems += quantity;
        })

        // Promotional Price applies to each item for this service 
        if (special.promotionalPrice) {
            let newQuote = comforterNumberItems * special.promotionalPrice;
            comforterDiscountAmount = comforterQuote - newQuote;
        }

        // special applies to total for this service
        if (special.percentDiscount) {
            let newQuote = comforterQuote - (comforterQuote * (special.percentDiscount / 100));
            comforterDiscountAmount = comforterQuote - newQuote;
        }

        // Discount amount applied once per entire order
        if (special.discountAmount) {
            if (!oneTimeDiscountApplied) {
                comforterDiscountAmount = special.discountAmount;
                oneTimeDiscountApplied = true;
            }
        }

        discount += comforterDiscountAmount;
    }

    if (discount === 0) {
        isEligible = false;

    } else {
        isEligible = true;
    }

    discount = Math.abs(discount);

    return { isEligible, discount };



}




module.exports = checkPromo;