import axios from "axios";
import {
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  GET_USERS,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  RESET_USER_STATE,
  EXPORT_CUSTOMERS,
  USER_ERROR,
  CLEAR_USER_ERROR,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getUsers = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;
  return (dispatch) => {
    return axios
      .get(
        `/api/users?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`,
        config()
      )
      .then((response) => {
        dispatch(clearUserError());
        return response.data;
      })
      .then((data) => {
        dispatch(getUsersSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error getting users."));
        }
      });
  };
};

// Get all customers
export const getCustomers = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;

  return (dispatch) => {
    return axios
      .get(
        `/api/customers?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`,
        config()
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        dispatch(getCustomersSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error getting customers."));
        }
      });
  };
};

export const exportCustomers = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;

  return (dispatch) => {
    return axios
      .get(
        `/api/customers/export?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`,
        config()
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        if (data) {
          dispatch(exportCustomersSuccess(data));
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error exporting customers."));
        }
      });
  };
};

export const getUser = (userId) => {
  return (dispatch) => {
    return axios
      .get(`/api/user/${userId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        if (data) {
          dispatch(getUserSuccess(data));
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error getting this user."));
        }
      });
  };
};

// Update a single user
export const updateUser = (userId, formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/user/${userId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        if (data) {
          dispatch(updateUserSuccess(data));
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error updating this user."));
        }
      });
  };
};

// Update a single customer
export const updateCustomer = (userId, formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/customer/${userId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        if (data) {
          dispatch(updateCustomerSuccess(data));
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("There was an error updating this customer."));
        }
      });
  };
};

// Create a user
export const createUser = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/admin/user`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearUserError());
        if (data) {
          dispatch(createUserSuccess(data));
        }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(userError(err.response.data.message));
        } else {
          dispatch(userError("Error creating user."));
        }
      });
  };
};

export const searchUsers = (sortBy, searchText) => {
  if (searchText) {
    let order = sortBy.desc === true ? -1 : 1;
    return (dispatch) => {
      return axios
        .get(
          `/api/users/search?sortby=${sortBy.key}&sortorder=${order}&searchtext=${searchText}`,
          {
            headers: { Authorization: "JWT " + localStorage.getItem("token") },
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(clearUserError());
          if (data) {
            dispatch(getUsersSuccess(data));
          }
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(userError(err.response.data.message));
          } else {
            dispatch(userError("Error searching users."));
          }
        });
    };
  }
};

export const searchCustomers = (sortBy, searchText) => {
  if (searchText) {
    let order = sortBy.desc === true ? -1 : 1;
    return (dispatch) => {
      return axios
        .get(
          `/api/customers/search?sortby=${sortBy.key}&sortorder=${order}&searchtext=${searchText}`,
          {
            headers: { Authorization: "JWT " + localStorage.getItem("token") },
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(clearUserError());
          if (data) {
            dispatch(getCustomersSuccess(data));
          }
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(userError(err.response.data.message));
          } else {
            dispatch(userError("Error searching customers."));
          }
        });
    };
  }
};

export const exportCustomersSuccess = (data) => ({
  type: EXPORT_CUSTOMERS,
  csv: data,
});

export const getUserSuccess = (data) => ({
  type: GET_USER,
  user: data,
});

export const getCustomersSuccess = (data) => ({
  type: GET_CUSTOMERS,
  customers: data,
});

export const updateUserSuccess = (data) => ({
  type: UPDATE_USER,
  user: data,
});

export const updateCustomerSuccess = (data) => ({
  type: UPDATE_CUSTOMER,
  customer: data,
});

export const createUserSuccess = (data) => ({
  type: CREATE_USER,
  user: data,
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS,
  users: data,
});

export function resetUserState() {
  return {
    type: RESET_USER_STATE,
  };
}

export function userError(error) {
  return {
    type: USER_ERROR,
    payload: error,
  };
}

export function clearUserError() {
  return {
    type: CLEAR_USER_ERROR,
  };
}
