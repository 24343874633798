import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTh,
  faList,
  faSortUp,
  faSortDown,
  faSort,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import LocationForm from "./components/LocationForm";
import StatusForm from "./components/StatusForm";
import Sidebar from "../../global/components/Sidebar/Sidebar";

// Form
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import {
  getOrders,
  exportOrders,
  filterOrders,
  clearCSV,
} from "../../actions/orders";
import { resetLocalOrderState } from "../../actions/localOrder";
import { resetEditOrderState } from "../../actions/editOrder";

library.add([faTh, faList, faSortUp, faSortDown, faSort, faSearch]);

function AppOrders(props) {
  const history = useHistory();

  useEffect(() => {
    if (props.auth && !props.auth.authenticated) {
      history.push("/login");
    }
  }, [props.auth]);

  const [orders, setOrders] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "orderId", desc: true });
  const [limit, setLimit] = useState(75);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [showMoreButton, setShowMoreButton] = useState(false);

  useEffect(() => {
    props.dispatch(getOrders(sortBy, limit, skip));
  }, []);

  useEffect(() => {
    if (props.orders.orders) {
      setOrders(props.orders.orders);
    }
  }, [props.orders]);

  useEffect(() => {
    if (props.auth.authenticated) {
      props.dispatch(getOrders(sortBy, limit, skip));
    }
  }, [sortBy, limit]);

  useEffect(() => {
    if (orders && orders.length > 74) {
      setShowMoreButton(true);
    }
  }, [orders]);

  let locationsOptions = [{ label: "Any Location", value: "any" }];

  if (props.locations.locations) {
    props.locations.locations.forEach((location) => {
      locationsOptions.push({
        label: location.address,
        value: location._id,
      });
    });
  }

  // Pre-populate with local order state values
  const { register, handleSubmit, control, watch, setValue, reset } = useForm({
    mode: "onChange",
  });

  // Watch form values
  let selectedStartDate = watch("startDate");
  let selectedEndDate = watch("endDate");

  const showMore = () => {
    setLimit(limit + 75);
  };

  const formatServices = (services) => {
    if (services) {
      let formattedServices = services.map((service) => {
        if (service === "washFold") {
          return <p>Wash Fold</p>;
        } else if (service === "organicWashFold") {
          return <p>Organic Wash Fold</p>;
        } else if (service === "dryClean") {
          return <p>Dry Clean</p>;
        } else if (service === "tailoring") {
          return <p>Tailoring</p>;
        } else if (service === "comforter") {
          return <p>Comforter</p>;
        } else {
          return <p>Wash Press</p>;
        }
      });
      return formattedServices;
    } else {
      return;
    }
  };

  const handleSort = (column) => {
    // If already sorting by this column, flip the order, else default to descending
    if (sortBy.key === column) {
      setSortBy({ key: column, desc: !sortBy.desc });
    } else {
      setSortBy({ key: column, desc: true });
    }
  };

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const handleGetExport = () => {
    props.dispatch(exportOrders(orders));
  };

  // Sorting is handled by passing sortBy argument to getOrders
  const handleSearchClear = () => {
    reset({
      search: "",
      startDate: null,
      endDate: null,
      location: { label: "Any Location", value: "any" },
      status: { label: "Any Status", value: null },
    });
    props.dispatch(getOrders(sortBy, limit, skip, props.auth.role));
  };

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(filterOrders(formData));
    props.dispatch(clearCSV());
  };

  if (props.auth.user) {
    return (
      <div className="dashboard-container">
        <Sidebar />
        <div className="dashboard-panel-container">
          <div id="app-orders" className="dashboard-panel">
            <div className="dashboard-button-container">
              <NavLink to="/app/order">
                <button
                  className="button-link"
                  onClick={() => {
                    props.dispatch(resetEditOrderState());
                    props.dispatch(resetLocalOrderState());
                  }}
                >
                  Schedule a Pick Up
                </button>
              </NavLink>
            </div>

            <form
              className="app-orders-form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="field">
                <div className="search-input">
                  <input
                    placeholder="Search"
                    onChange={handleSearchInput}
                    type="text"
                    name="search"
                    ref={register}
                  />
                </div>
              </div>

              <div className="field">
                <div className="datepicker-field">
                  <Controller
                    as={
                      <DatePicker
                        selected={selectedStartDate}
                        name="startDate"
                      />
                    }
                    control={control}
                    name="startDate"
                    placeholderText="Start Date"
                  />
                </div>
              </div>

              <div className="space">-</div>

              <div className="field">
                <div className="datepicker-field">
                  <Controller
                    as={
                      <DatePicker selected={selectedEndDate} name="endDate" />
                    }
                    control={control}
                    name="endDate"
                    placeholderText="End Date"
                  />
                </div>
              </div>

              <div className="field">
                <div className="select">
                  <Controller
                    as={
                      <Select
                        options={[
                          { label: "Pick Up", value: "pickUp" },
                          { label: "Delivery", value: "delivery" },
                          { label: "Both", value: "both" },
                        ]}
                        placeholder="Pickup or Delivery"
                      />
                    }
                    control={control}
                    name="type"
                  />
                </div>
              </div>

              <div className="field">
                <div className="select">
                  <Controller
                    as={
                      <Select
                        options={locationsOptions}
                        placeholder="Location"
                      />
                    }
                    control={control}
                    name="location"
                    defaultValue={{ label: "Any Location", value: "any" }}
                  />
                </div>
              </div>

              <div className="field">
                <div className="select">
                  <Controller
                    as={
                      <Select
                        options={[
                          { label: "New", value: "New" },
                          {
                            label: "Ready to Assign Location",
                            value: "Unassigned",
                          },
                          {
                            label: "Waiting for Reauth",
                            value: "Awaiting Authorization",
                          },
                          {
                            label: "Ready to Capture Payment",
                            value: "Reauthorized",
                          },
                          { label: "Paid", value: "Payment Processed" },
                          {
                            label: "En Route for Pick Up",
                            value: "En Route for Pick Up",
                          },
                          {
                            label: "En Route for Delivery",
                            value: "En Route for Delivery",
                          },
                          { label: "Completed", value: "Completed" },
                        ]}
                        placeholder="Status"
                      />
                    }
                    control={control}
                    name="status"
                  />
                </div>
              </div>

              <input
                className="submit-button bg-gray"
                type="submit"
                value="filter"
              />
              <div className="field">
                <span className="search-clear" onClick={handleSearchClear}>
                  Clear
                </span>
              </div>
            </form>

            {props.csv && (
              <div className="export-results-container">
                <CSVLink
                  className="button"
                  filename="orders.csv"
                  data={props.csv}
                >
                  <button>Download</button>
                </CSVLink>
              </div>
            )}

            {(!props.csv && props.user?.role === "SuperAdmin") ||
              (props.user?.role === "Admin" && (
                <div className="export-results-container">
                  <button onClick={handleGetExport}>Export Results</button>
                </div>
              ))}

            {props.error && (
              <div className="error-banner">
                <p>{props.error}</p>
              </div>
            )}

            <div className="dashboard-table">
              <ul
                id="order-table-rows"
                className={
                  props.auth?.user?.role === "Customer"
                    ? "dashboard-table-rows customer-view"
                    : "dashboard-table-rows"
                }
              >
                <li
                  id="order-table-header"
                  className={
                    props.auth?.user?.role === "Customer"
                      ? "dashboard-table-header row customer-view"
                      : "dashboard-table-header row"
                  }
                >
                  <div className="dashboard-table-column">
                    Order #{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("orderId")}
                      icon={
                        sortBy.key === "orderId" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "orderId" && sortBy.desc === false
                          ? faSortUp
                          : faSortDown
                      }
                    />
                  </div>

                  {props.auth?.user?.role !== "Customer" && (
                    <div className="dashboard-table-column">
                      Customer{" "}
                      <FontAwesomeIcon
                        onClick={(e) => handleSort("customerName")}
                        icon={
                          sortBy.key === "customerName" && sortBy.desc === true
                            ? faSortDown
                            : sortBy.key === "customerName" &&
                              sortBy.desc === false
                            ? faSortUp
                            : faSort
                        }
                      />
                    </div>
                  )}

                  <div className="dashboard-table-column">Service</div>

                  <div className="dashboard-table-column">
                    Pick Up{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("pickUpDate")}
                      icon={
                        sortBy.key === "pickUpDate" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "pickUpDate" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  <div className="dashboard-table-column">
                    Delivery{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("deliveryDate")}
                      icon={
                        sortBy.key === "deliveryDate" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "deliveryDate" &&
                            sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  {props.auth?.user?.role !== "Customer" && (
                    <div className="dashboard-table-column">Gratuity</div>
                  )}

                  <div className="dashboard-table-column">
                    Status{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("status")}
                      icon={
                        sortBy.key === "status" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "status" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  <div className="dashboard-table-column">
                    Location{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("locationName")}
                      icon={
                        sortBy.key === "locationName" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "locationName" &&
                            sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>
                </li>

                {orders.map((order) => {
                  let pickup = new Date(order.pickUpDate);
                  let delivery = new Date(order.deliveryDate);
                  let delivery2, delivery2String, delivery2Arr;

                  if (order.deliveryDate2) {
                    delivery2 = new Date(order.deliveryDate2);
                    delivery2String = delivery2.toString();
                    delivery2Arr = delivery2String.split();
                  }

                  let pickupString = pickup.toString();
                  let deliveryString = delivery.toString();

                  let pickupArr = pickupString.split(" ");
                  let deliveryArr = deliveryString.split(" ");

                  let dMonth = deliveryArr[1];
                  let dDate = deliveryArr[2];
                  let pMonth = pickupArr[1];
                  let pDate = pickupArr[2];

                  let deliveryDateString = `${dMonth} ${dDate}`;

                  let pickUpDateString = `${pMonth} ${pDate}`;

                  if (order.deliveryDate2) {
                    let d2Month = delivery2Arr[1];
                    let d2Date = delivery2Arr[2];
                    let deliveryDateString2 = `${d2Month} ${d2Date}`;
                  }

                  return (
                    <li className="row">
                      <div className="dashboard-table-column">
                        <NavLink to={`/app/order/id/${order._id}`}>
                          {order.orderId}
                        </NavLink>
                      </div>
                      {props.auth?.user?.role !== "Customer" && (
                        <div className="dashboard-table-column">
                          {order.firstName}
                        </div>
                      )}
                      <div className="dashboard-table-column dashboard-list-column">
                        {formatServices(order.orderServices)}
                      </div>

                      <div className="dashboard-table-column">
                        {pickUpDateString}
                      </div>

                      <div className="dashboard-table-column">
                        {deliveryDateString}
                      </div>

                      {props.auth.user.role !== "Customer" && (
                        <div className="dashboard-table-column">
                          {order.gratuity > 0 && "$" + order.gratuity}
                        </div>
                      )}

                      <div className="dashboard-table-column">
                        <StatusForm order={order} status={order.status} />
                      </div>
                      <div className="dashboard-table-column">
                        <LocationForm
                          order={order}
                          location={order.location}
                          locationName={order.locationName}
                        />
                      </div>
                    </li>
                  );
                })}
                {orders && orders.length > 0 && showMoreButton && (
                  <li className="row show-more-row">
                    <button onClick={() => showMore()}>Show More</button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p className="dashboard-container"></p>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.orders.error,
    orders: state.orders,
    locations: state.locations,
    csv: state.orders.csv,
  };
};

export default connect(mapStateToProps)(AppOrders);
