import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, ErrorMessage, Controller } from "react-hook-form";
import MaskedInput from "react-input-mask";

import calculateWashFold from "../../../../utils/calculateWashFold";
import calculateOrganicWashFold from "../../../../utils/calculateOrganicWashFold";

// Redux
import { updateLocalOrder } from "../../../../actions/localOrder";
import {
  updateEditOrder,
  resetEditOrderState,
} from "../../../../actions/editOrder";

function AddressStep(props) {
  let history = useHistory();

  const [edit, setEdit] = useState(props.edit);
  const [order, setOrder] = useState();
  const [invalidAddress, setInvalidAddress] = useState(false);

  const [availableZips, setAvailableZips] = useState([
    "10034",
    "10040",
    "10033",
    "10032",
    "10039",
    "10031",
    "10030",
    "10037",
    "10027",
    "10026",
    "10029",
    "10025",
    "10128",
    "10024",
    "10028",
    "10075",
    "10021",
    "10023",
    "10065",
    "10022",
    "10019",
    "10020",
    "10036",
    "10017",
    "10018",
    "10016",
    "10001",
    "10010",
    "10003",
    "10009",
    "10014",
    "10012",
    "10002",
    "10013",
    "10282",
    "10007",
    "10038",
    "10006",
    "10280",
    "10005",
    "10004",
    "10463",
    "10468",
    "10471",
    "10454",
    "11201",
    "11231",
    "11215",
    "11218",
    "11217",
    "11211",
    "11206",
    "11222",
    "11225",
    "11226",
    "11235",
    "11229",
    "11223",
    "11224",
    "11230",
    "11210",
    "10035",
  ]);
  const [floridaZips, setFloridaZips] = useState(["33445"]);
  const [zipAvailable, setZipAvailable] = useState(true);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [defaultAddress2, setDefaultAddress2] = useState("");
  const [defaultCity, setDefaultCity] = useState("");
  const [defaultState, setDefaultState] = useState("NY");
  const [defaultZip, setDefaultZip] = useState("");
  const [defaultFirstName, setDefaultFirstName] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultPhone, setDefaultPhone] = useState("");

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    setError,
  } = useForm({
    mode: "onChange",
  });

  function hasNoLettersOrNumbers(inputString) {
    // return !/[a-zA-Z]/.test(inputString);

    return !(/[a-zA-Z]/.test(inputString) && /\d/.test(inputString));
  }

  const zip = watch("zip");

  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
    } else {
      setOrder(props.localOrder);
    }
  }, [props.editOrder, props.localOrder]);

  useEffect(() => {
    if (!props.auth || !props.auth.authenticated) {
      props.dispatch(resetEditOrderState());
    }
  }, [props.auth]);

  useEffect(() => {
    let zipList = [];
    let floridaZipList = [];
    if (props.zips && props.zips.zips) {
      props.zips.zips.forEach((zip) => {
        zipList.push(zip.zip);
        if (zip.florida) {
          floridaZipList.push(zip.zip);
        }
      });
    }

    setAvailableZips(zipList);
    setFloridaZips(floridaZipList);
  }, [props.zips]);

  useEffect(() => {
    if (zip && zip.toString().length === 5) {
      if (availableZips && availableZips.includes(zip.toString())) {
        setZipAvailable(true);
      } else {
        setZipAvailable(false);
      }
    }
  }, [zip]);

  // Populate default values with user's info (if logged in)
  useEffect(() => {
    if (props.auth.user && !order) {
      setDefaultAddress(props.auth.user.address);
      setDefaultAddress2(props.auth.user.addressLine2);
      setDefaultCity(props.auth.user.city);
      setDefaultState(props.auth.user.state);
      setDefaultZip(props.auth.user.zip);
      setDefaultPhone(props.auth.user.phone);
      setDefaultFirstName(props.auth.user.firstName);
      setDefaultEmail(props.auth.user.email);

      // Corrects for react-hook-form bug
      setValue("phone", props.auth.user.phone);
    }
  }, [props.auth.user, order]);

  // Populate with existing order's address
  useEffect(() => {
    if (order && order.address) {
      setValue("address", order.address.address);
      setValue("address2", order.address.address2);
      setValue("city", order.address.city);
      setValue("state", order.address.state);
      setValue("zip", order.address.zip);
      setValue("email", order.address.email);
      setValue("firstName", order.address.firstName);
      setValue("phone", order.address.phone);
    }
  }, [setValue, order]);

  const onSubmit = (data) => {
    if (hasNoLettersOrNumbers(data.address)) {
      setInvalidAddress(true);
      return;
    } else {
      setInvalidAddress(false);
    }

    if (data.state.toLowerCase() == "fl") {
      data.florida = true;
    } else {
      data.florida = false;
    }

    // State has changed, re-run quotes
    if (
      (order.address?.state?.toLowerCase() == "fl" && !data.florida) ||
      (order?.address?.state?.toLowerCase() !== "fl" && data.florida)
    ) {
      if (order.washFoldOrder) {
        let washFoldResult = calculateWashFold({
          weight: order.washFoldOrder.weight,
          products: order.washFoldOrder.products,
          settings: data.florida ? props.settingsFlorida : props.settings,
        });

        washFoldResult.weight = order.washFoldOrder.weight;
        washFoldResult.quote = washFoldResult.price.toFixed(2);

        if (props.editOrder && props.editOrder.orderId) {
          props.dispatch(
            updateEditOrder(washFoldResult, "washFoldOrder", () => {
              return;
            })
          );
        } else {
          props.dispatch(
            updateLocalOrder(washFoldResult, "washFoldOrder", () => {
              return;
            })
          );
        }
      }

      if (order.organicWashFoldOrder) {
        let organicWashFoldResult = calculateOrganicWashFold({
          weight: order.organicWashFoldOrder.weight,
          products: order.organicWashFoldOrder.products,
          settings: data.florida ? props.settingsFlorida : props.settings,
        });

        organicWashFoldResult.weight = order.organicWashFoldOrder.weight;
        organicWashFoldResult.quote = organicWashFoldResult.price.toFixed(2);

        if (props.editOrder && props.editOrder.orderId) {
          props.dispatch(
            updateEditOrder(
              organicWashFoldResult,
              "organicWashFoldOrder",
              () => {
                return;
              }
            )
          );
        } else {
          props.dispatch(
            updateLocalOrder(
              organicWashFoldResult,
              "organicWashFoldOrder",
              () => {
                return;
              }
            )
          );
        }
      }
    }

    if (edit) {
      props.dispatch(
        updateEditOrder(data, "address", () => {
          history.push("/app/order/services");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "address", () => {
          history.push("/app/order/services");
        })
      );
    }
  };

  const onErrors = (errors) => console.log(errors);

  return (
    <div id="address-step" className="order-step">
      <form id="order-form" onSubmit={handleSubmit(onSubmit, onErrors)}>
        <h4 className="step-header">
          {edit
            ? "Edit Pick Up & Delivery Address"
            : "What is your pick up and delivery address?"}
        </h4>
        <div className="form-line">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            defaultValue={defaultAddress}
            ref={register({
              required: "Please enter your address.",
            })}
          />
          {invalidAddress && (
            <p className="form-error-message">Please enter a valid address.</p>
          )}
          <ErrorMessage errors={errors} name="address">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
        <div className="form-line">
          <label htmlFor="address2">Address Line 2 (optional)</label>
          <input
            type="text"
            name="address2"
            defaultValue={defaultAddress2}
            ref={register}
          />
          <ErrorMessage errors={errors} name="address2">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
        <div className="form-line">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            defaultValue={defaultCity}
            ref={register({ required: "Please enter your city." })}
          />
          <ErrorMessage errors={errors} name="city">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
        <div className="form-line">
          <label htmlFor="state">State</label>
          <input
            type="text"
            name="state"
            defaultValue={defaultState}
            ref={register({ required: "This field is required." })}
          />
          <ErrorMessage errors={errors} name="state">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
        <div className="form-line">
          <label htmlFor="zip">Zip</label>
          <input
            type="text"
            name="zip"
            defaultValue={defaultZip}
            ref={register({
              required: "Please enter your zipcode.",
              validate: (value) => {
                if (
                  availableZips.includes(value.toString()) ||
                  floridaZips.includes(value.toString())
                ) {
                  return true;
                } else {
                  return "Sorry! We're not yet available in your area.";
                }
              },
            })}
          />
          <ErrorMessage errors={errors} name="zip">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
          {/* {!zipAvailable && (
            <p>Sorry, our service is not yet available in your area.</p>
          )} */}
        </div>

        <div className="customer-information">
          <div className="form-line">
            <div className="form-input">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                ref={register({ required: "Please enter your name." })}
                defaultValue={defaultFirstName}
              />
            </div>
            <ErrorMessage errors={errors} name="firstName">
              {({ message }) => <p className="form-error-message">{message}</p>}
            </ErrorMessage>
          </div>

          <div className="form-line">
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                defaultValue={defaultEmail}
                rules={{ required: true }}
                ref={register({
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Please enter a valid email address.",
                  },
                })}
              />
              <ErrorMessage errors={errors} name="email">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
          </div>

          <div className="form-line">
            <div className="form-input">
              <label htmlFor="phone">Phone</label>

              <Controller
                as={
                  <MaskedInput
                    mask="(999) 999-9999"
                    maskplaceholder={"_"}
                    alwaysShowMask
                  ></MaskedInput>
                }
                rules={{
                  required: "Phone number is required.",
                  validate: (value) => value !== "Please enter a phone number",
                }}
                name="phone"
                defaultValue={defaultPhone}
                control={control}
                type="text"
              />
              <ErrorMessage errors={errors} name="phone">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
          </div>
        </div>

        {zipAvailable && (
          <div className="action-button-container">
            <input
              className="submit-button bg-green"
              type="submit"
              value="Continue"
            />
          </div>
        )}
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
    zips: state.zips,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(AddressStep);
