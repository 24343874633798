// 3rd Party Libraries
import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import authReducer from "../reducers/auth";
import localOrderReducer from "../reducers/localOrder";
import editOrderReducer from "../reducers/editOrder";
import ordersReducer from "../reducers/orders";
import locationsReducer from "../reducers/locations";
import specialsReducer from "../reducers/specials";
import usersReducer from "../reducers/users";
import paymentReducer from "../reducers/payment";
import settingsReducer from "../reducers/settings";
import settingsFloridaReducer from "../reducers/settings_florida";
import zipsReducer from "../reducers/zips";

// Creates the Redux store with reducers and middleware
const rootReducer = combineReducers({
  auth: authReducer,
  localOrder: localOrderReducer,
  orders: ordersReducer,
  locations: locationsReducer,
  users: usersReducer,
  specials: specialsReducer,
  payment: paymentReducer,
  settings: settingsReducer,
  settingsFlorida: settingsFloridaReducer,
  editOrder: editOrderReducer,
  zips: zipsReducer,
});

const persistConfig = {
  key: "auth",
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);

let middleware;

if (process.env.NODE_ENV === "development") {
  middleware = applyMiddleware(thunk, logger);
} else {
  middleware = applyMiddleware(thunk);
}

const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

// let decodedToken = jwt_decode(token);
// store.dispatch({ type: 'AUTHORIZE_USER', userId: decodedToken.userId, role: decodedToken.role });
// store.dispatch(getSettings());

export { persistor, store };
