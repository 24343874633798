import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { addProduct } from "../../../actions/settings_florida";

function NewProductForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      addProduct(formData, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="new-product">
        <input type="text" name="item" ref={register} />
        <input type="text" name="price" ref={register} />
        <input className="add submit-button" type="submit" value="Add" />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(NewProductForm);
