import React from "react";
import { NavLink } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

library.add([faTrash, faPlus]);

function WashFold({ checkoutView, remove, order }) {
  return (
    <li className="order-item">
      <div className="item-name">
        <div className="service-name">
          {!checkoutView && (
            <button onClick={() => remove()}>
              <FontAwesomeIcon icon="trash" />
            </button>
          )}
          <NavLink to={`/app/order/services/washfold`} className="order-label">
            Wash & Fold
          </NavLink>
        </div>
        <div className="service-quote">
          ${parseFloat(order.quote).toFixed(2)}
        </div>
      </div>
      <div className="item-details">
        <div>
          <p>{order.weight} lbs</p>
        </div>

        {order.productsWithPrices &&
          order.productsWithPrices.map((p) => {
            return (
              <p>
                {p[0]} (${p[1]})
              </p>
            );
          })}
      </div>
    </li>
  );
}

export default WashFold;
