import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { NavLink } from "react-router-dom";

import { getLocations } from "../../actions/locations";
import { getSpecials } from "../../actions/specials";

import "./Specials.scss";

function Specials(props) {
  const [loading, setLoading] = useState(true);
  const [limit] = useState(100);
  const [skip] = useState(0);
  const [sortBy] = useState({ key: "address", desc: false });

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    dispatch(getLocations(sortBy, limit, skip));
    dispatch(getSpecials(sortBy, limit, skip));
  }, [dispatch, sortBy, limit, skip]);

  return (
    <div
      id="specials"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <div id="specials-hero">
        <h1 className="green-text">
          The best service.{" "}
          <span className="orange-text">The best prices.</span>
        </h1>
        <p>Your time is priceless. Let us do your laundry.</p>
      </div>

      {props.specials && (
        <div className="specials-grid">
          {props.specials.map((special) => {
            let icon;
            if (special.percentDiscount) {
              icon = "/images/specials-icon-percent-tag.png";
            } else {
              icon = "/images/specials-icon-hanging-shirt.png";
            }

            let amt;
            if (special.percentDiscount) {
              amt = `${special.percentDiscount}% off`;
            } else if (special.promotionalPrice) {
              amt = `$${special.promotionalPrice.toFixed(2)} per item`;
            } else if (special.discountAmount) {
              amt = `$${special.discountAmount.toFixed(2)} off order`;
            } else {
              amt = `$${amt}`;
            }

            return (
              <div className="specials-grid-item">
                <img alt="" src={icon} />
                <h1>{special.name}</h1>
                <h2>{amt}</h2>
                {special.firstTimeOnly && (
                  <p>For first-time pickup & delivery clients</p>
                )}
                <p>Applies to eligible services only</p>
                <p>
                  Use code <strong>{special.code.toUpperCase()}</strong>
                </p>
                <NavLink to="/signup">Schedule a Pickup</NavLink>
                <br />
                <NavLink to="/locations">Find a Dropoff Location</NavLink>
              </div>
            );
          })}
        </div>
      )}

      <div className="sticker">
        <span>
          Special
          <br />
          Deal
        </span>
        <span>Free</span>
        <span>Pick Up & Delivery</span>
      </div>

      <div className="locations">
        <div className="locations-heading">
          <h1 className="green-text">
            Location. Location. <span className="orange-text">Location.</span>
          </h1>
          <p>
            With 28 locations across Manhattan, you're never far from New York's
            cleanest and greenest.
          </p>
        </div>

        {props.locations && (
          <div className="locations-container">
            {props.locations.map((location) => {
              return (
                <div className="locations-list-item" key={location.phone}>
                  <p className="address">
                    {location.address}, {location.city}, {location.zip}{" "}
                  </p>
                  <p className="hours">{location.hours}</p>
                  <p className="phone">{location.phone}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    specials: state.specials.specials,
    error: state.specials.error,
  };
};

export default connect(mapStateToProps)(Specials);
