import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { updateOrderStatus } from '../../../actions/orders';

// Form
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

function StatusForm(props) {

    const [editMode, setEditMode] = useState(false);
    const [showForms, setShowForms] = useState(false);
    const [statusOptions] = useState([
        {
            label: "New",
            value: "New"
        },

        {
            label: "En Route for Pick Up",
            value: "En Route for Pick Up"
        },
        {
            label: "In Progress",
            value: "In Progress"
        },
        {
            label: "En Route for Delivery",
            value: "En Route for Delivery"
        },
        {
            label: "Completed",
            value: "Completed"
        }
    ]);

    useEffect(() => {


        if (!props.auth.authorizedAsGuest && props.auth.user.role !== "Customer") {
            setShowForms(true);
        }


    }, []);

    const dispatch = useDispatch();

    const { handleSubmit, control } = useForm({
        mode: "onChange"
    });

    // Dispatch action 
    const onSubmit = (formData) => {
        formData.orderId = props.order.orderId;
        dispatch(updateOrderStatus(formData, () => {
            setEditMode(false);
        }));
    }

    return (
        <div className="table-form-container">
            {!editMode && <span className="status-data">{props.status}

                {showForms && <button className="table-row-action" onClick={() => setEditMode(true)}>Edit</button>}</span>}

            {editMode && <span className="status-data"><button className="table-row-action" onClick={() => setEditMode(false)}>Cancel</button></span>}

            {editMode && showForms &&
                <form id="status-form" onSubmit={handleSubmit(onSubmit)}>

                    <Controller
                        as={<Select
                            placeholder="Status"
                            options={statusOptions}
                        />
                        }
                        control={control}
                        name="status"
                        rules={{ required: true, validate: value => value !== "Status" }}
                    />

                    <input className="submit-button bg-green" type="submit" value="Update" />
                </form>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(StatusForm);
