import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { assignOrderLocation } from '../../../actions/orders';

// Form
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';


function LocationForm(props) {

    const [editMode, setEditMode] = useState(false);
    const [showForms, setShowForms] = useState(false);

    let locationName;
    let foundLocation;

    if (showForms && props.locations && props.location) {
        foundLocation = props.locations.find(location => {
            return location._id === props.location;
        })
        if (foundLocation) {
            locationName = foundLocation.address;
        }
    }

    // Format locations for Select input, excluding current location
    let locationsOptions;
    if (showForms && props.locations) {
        if (props.locations) {
            locationsOptions = props.locations.filter(location => {
                return location._id !== props.location;
            });
        }
        locationsOptions = locationsOptions.map(location => {
            return {
                label: location.address,
                value: location._id
            }
        })
    }



    useEffect(() => {

        if (!props.auth.authorizedAsGuest && props.auth.user.role !== "Customer") {
            setShowForms(true);
        }
    }, []);

    const dispatch = useDispatch();

    const { handleSubmit, control, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            // location: {
            //     label: foundLocation ? foundLocation.address : "Select One",
            //     value: foundLocation ? foundLocation._id : null
            // }
        }
    });

    // Dispatch action 
    const onSubmit = (formData) => {
        formData.orderId = props.order.orderId;
        dispatch(assignOrderLocation(formData, () => {
            setEditMode(false);
        }));
    }

    return (
        <div className="table-form-container">
            {!editMode && <span className="location-data">{props.locationName ? props.locationName : "Unassigned"} {showForms && <button className="table-row-action" onClick={() => setEditMode(true)}>Edit</button>}</span>}

            {editMode && <span className="location-data"><button className="table-row-action" onClick={() => setEditMode(false)}>Cancel</button></span>}

            {editMode && showForms &&
                <form id="location-form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        as={<Select
                            placeholder={props.location ? "Location" : "Location"}
                            options={locationsOptions}
                        />
                        }
                        control={control}
                        name="location"
                        rules={{ required: true, validate: value => value !== "Select a location." }}
                    />

                    <input className="submit-button bg-green" type="submit" value={props.location ? "Update" : "Assign"} />
                </form>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations.locations
    }
}

export default connect(mapStateToProps)(LocationForm);
