import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

// Redux
import { capturePayment, requestReauth } from "../../../actions/payment";

// Form
import { useForm } from "react-hook-form";

function CapturePaymentForm({ order, payment, orderTotal }) {
  const dispatch = useDispatch();

  const { handleSubmit, register, watch, reset } = useForm({
    mode: "onChange",
  });

  const amount = watch("amount");

  const [action, setAction] = useState("charge");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const amountToCharge = amount * 100;
  const amountAuthorized = order.amountAuthorized;
  const amountAuthorizedString = (order.amountAuthorized / 100).toFixed(2);

  useEffect(() => {
    if (amountToCharge > amountAuthorized) {
      setAction("request");
    } else {
      setAction("charge");
    }
  }, [amount]);

  useEffect(() => {
    reset({
      amount: orderTotal,
    });
  }, [orderTotal]);

  // Dispatch action
  const onSubmit = (formData) => {
    formData.orderId = order._id;
    formData.paymentIntentId = order.stripePaymentIntentId;
    formData.amountToCharge = formData.amount * 100;
    formData.description = "ORDER # " + order.orderId;

    if (action === "charge") {
      dispatch(
        capturePayment(formData, () => {
          setShowConfirmation(
            "The customer has successfully been charged for this order."
          );
        })
      );
    } else {
      dispatch(
        requestReauth(formData, () => {
          setShowConfirmation(
            "A request has been sent to this customer for reauthorization."
          );
        })
      );
    }
  };

  return (
    <div className="order-detail-form-container">
      {showConfirmation && <div>{showConfirmation}</div>}

      {!showConfirmation && (
        <div>
          <div className="flex-container">
            <div className="label">
              Amount Authorized:
              <br />
              {!order.reauthStatus && (
                <span style={{ fontWeight: "normal", fontSize: "1.4rem" }}>
                  (50% over the quoted price)
                </span>
              )}
            </div>
            <div>${amountAuthorizedString}</div>
          </div>

          <form
            className="capture-payment-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-row">
              <div className="form-input flex-container">
                <label className="label" htmlFor="amount">
                  Amount to Charge:
                </label>

                <span>$</span>
                <input
                  defaultValue={orderTotal}
                  name="amount"
                  pattern="^\d*(\.\d{0,2})?$"
                  ref={register}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-input flex-container">
                <label className="label" htmlFor="notes">
                  Notes for Customer:
                </label>
                <textarea name="notes" ref={register} />
              </div>
            </div>

            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value={
                  action == "charge"
                    ? "Charge Customer"
                    : "Request Customer Re-Auth"
                }
              />
            </div>

            {payment?.error &&
              payment.error !== "Unable to create setup intent." &&
              payment.error !==
                "An error occurred when creating a payment intent." && (
                <p>{payment.error}</p>
              )}
          </form>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    payment: state.payment,
    order: state.orders.order,
  };
};

export default connect(mapStateToProps)(CapturePaymentForm);
