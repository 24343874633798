import {
  GET_SPECIALS,
  CREATE_SPECIAL,
  UPDATE_SPECIAL,
  DELETE_SPECIAL,
  SPECIAL_ERROR,
  CLEAR_SPECIAL_ERROR,
} from "../actions/types";

export default (state = { error: null }, action) => {
  switch (action.type) {
    case CREATE_SPECIAL:
      return { ...state, specials: [action.special, ...state.specials] };

    case GET_SPECIALS:
      return { ...state, specials: action.specials };

    case UPDATE_SPECIAL:
      let specialIndex = state.specials.findIndex(
        (special) => special._id === action.special._id
      );

      let updatedSpecials = state.specials.map((item, index) => {
        if (index !== specialIndex) {
          return item;
        }
        return action.special;
      });

      return { ...state, specials: updatedSpecials };
    case DELETE_SPECIAL:
      return {
        ...state,
        specials: state.specials.filter(
          (special) => special.name !== action.special.name
        ),
      };
    case SPECIAL_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_SPECIAL_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
