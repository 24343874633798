import React from "react";
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateMinDelivery } from "../../../actions/settings";


function MinDeliveryForm(props) {

    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {
        props.dispatch(updateMinDelivery(formData, () => {
            props.close();
        }));
    }

    return (

        <form className="minimum-delivery-section" onSubmit={handleSubmit(onSubmit)}>

            <div className="form-row">
                <div className="form-input">
                    <label htmlFor="washFoldMinDelivery">Wash & Fold</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.washFoldMinDelivery}</p>}

                    {props.editMode && <input type="text" name="washFoldMinDelivery" ref={register} defaultValue={props.settings.washFoldMinDelivery} />}
                </div>
                <div className="form-input">
                    <label htmlFor="organicWashFoldMinDelivery">Organic Wash & Fold</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.organicWashFoldMinDelivery}</p>}

                    {props.editMode && <input type="text" name="organicWashFoldMinDelivery" ref={register} defaultValue={props.settings.organicWashFoldMinDelivery} />}
                </div>
                <div className="form-input">
                    <label htmlFor="washPressMinDelivery">Wash & Press</label>
                    {!props.editMode &&
                        <div>
                            <p className="current-value">{props.settings.washPressMinDelivery}</p>

                        </div>
                    }

                    {props.editMode &&
                        <div>
                            <input type="text" name="washPressMinDelivery" ref={register} defaultValue={props.settings.washPressMinDelivery} />

                        </div>
                    }
                </div>

            </div>

            <div className="form-row">
                <div className="form-input">
                    <label htmlFor="dryCleanMinDelivery">Dry Clean</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.dryCleanMinDelivery}</p>}

                    {props.editMode && <input type="text" name="dryCleanMinDelivery" ref={register} defaultValue={props.settings.dryCleanMinDelivery} />}
                </div>
                <div className="form-input">
                    <label htmlFor="tailoringMinDelivery">Tailoring</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.tailoringMinDelivery}</p>}

                    {props.editMode && <input type="text" name="tailoringMinDelivery" ref={register} defaultValue={props.settings.tailoringMinDelivery} />}
                </div>
                <div className="form-input">
                    <label htmlFor="comforterMinDelivery">Comforter</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.comforterMinDelivery}</p>}

                    {props.editMode && <input type="text" name="comforterMinDelivery" ref={register} defaultValue={props.settings.comforterMinDelivery} />}
                </div>
            </div>

            {props.editMode &&
                <div className="action-button-container">
                    <input className="submit-button update" type="submit" value="Update" />
                </div>
            }

        </form>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(MinDeliveryForm);


