import axios from "axios";
import {
  GET_LOCATIONS,
  GET_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  LOCATION_ERROR,
  CLEAR_LOCATION_ERROR,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getLocations = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;

  return (dispatch) => {
    return axios
      .get(
        `/api/locations?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`,
        config()
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getLocationsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(locationError(err.response.data.message));
        } else {
          dispatch(locationError("Error getting locations."));
        }
      });
  };
};

export const getLocation = (locationId) => {
  return (dispatch) => {
    return axios
      .get(`/api/location/${locationId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getLocationSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(locationError(err.response.data.message));
        } else {
          dispatch(locationError("Error getting location."));
        }
      });
  };
};

export const updateLocation = (locationId, formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/location/${locationId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateLocationSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(locationError(err.response.data.message));
        } else {
          dispatch(locationError("Error updating location."));
        }
      });
  };
};

export const createLocation = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/admin/location`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(createLocationSuccess(data));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(locationError(err.response.data.message));
        } else {
          dispatch(locationError("Error creating location."));
        }
      });
  };
};

export const deleteLocation = (locationId, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/location/${locationId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(deleteLocationSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(locationError(err.response.data.message));
        } else {
          dispatch(locationError("Error deleting location."));
        }
      });
  };
};

export const searchLocations = (sortBy, searchText) => {
  if (searchText) {
    let order = sortBy.desc === true ? -1 : 1;
    return (dispatch) => {
      return axios
        .get(
          `/api/locations/search?sortby=${sortBy.key}&sortorder=${order}&searchtext=${searchText}`,
          config()
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(getLocationsSuccess(data));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(locationError(err.response.data.message));
          } else {
            dispatch(locationError("Error finding locations."));
          }
        });
    };
  }
};

export const getLocationSuccess = (data) => ({
  type: GET_LOCATION,
  location: data,
});

export const updateLocationSuccess = (data) => ({
  type: UPDATE_LOCATION,
  location: data,
});

export const getLocationsSuccess = (data) => ({
  type: GET_LOCATIONS,
  locations: data,
});

export const createLocationSuccess = (data) => ({
  type: CREATE_LOCATION,
  location: data,
});

export const deleteLocationSuccess = (data) => ({
  type: DELETE_LOCATION,
  location: data,
});

export function locationError(error) {
  return {
    type: LOCATION_ERROR,
    payload: error,
  };
}

export function clearLocationError() {
  return {
    type: CLEAR_LOCATION_ERROR,
  };
}
