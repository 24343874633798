import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

library.add([faCaretDown, faCaretUp]);

function AccordionItem(props) {

    const [active, setActive] = useState(false);

    return (
        <div key={props.question} className={active ? "accordion-item active" : "accordion-item"}>
            <div className="question" onClick={() => setActive(!active)}>{props.question}<FontAwesomeIcon icon={active ? "caret-up" : "caret-down"} /></div>
            <div className="answer">{props.answer}</div>
        </div>
    )
}


export default AccordionItem;