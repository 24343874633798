import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';

import Sidebar from "../../global/components/Sidebar/Sidebar";
import Location from "./components/Location/Location";
import NewLocation from "./components/NewLocation/NewLocation";

import { getLocations, searchLocations } from '../../actions/locations';

function AppLocations(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (props.auth && !props.auth.authenticated) {
            history.push("/login");
        }
    }, [props.auth]);

    const [newLocationForm, setNewLocationForm] = useState(false);
    const [sortBy, setSortBy] = useState({ key: 'address', desc: false });
    const [limit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        if (props.auth.authenticated) {
            dispatch(getLocations(sortBy, limit, skip));
        }
    }, [dispatch, sortBy, limit, skip]);

    const handleSort = (column) => {
        if (sortBy.key === column) {
            setSortBy({ key: column, desc: !sortBy.desc });
        } else {
            setSortBy({ key: column, desc: true });
        }
    }

    const handleSelectLocation = (location) => {
        setCurrentLocation(location);
    }

    const goBackToList = () => {
        setCurrentLocation(null);
    }

    const handleCancelEditLocation = () => {
        setCurrentLocation(null);
        setNewLocationForm(false);
    }

    const handleSearchInput = (e) => {
        setSearchText(e.target.value);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        props.dispatch(searchLocations(sortBy, searchText));
    }

    const handleSearchClear = () => {
        setSearchText("");
        props.dispatch(getLocations(sortBy, limit, skip));
    }

    return (
        <div className="dashboard-container">
            <Sidebar />

            {props.locations &&
                <div className="dashboard-panel-container">

                    <div className="dashboard-button-container">
                        {!newLocationForm && !currentLocation && <button onClick={() => setNewLocationForm(true)} className="button-link">Add a Location</button>}

                        {newLocationForm && <NewLocation handleCancelEditLocation={handleCancelEditLocation} />}

                        {currentLocation && <Location goBackToList={goBackToList} handleCancelEditLocation={handleCancelEditLocation} location={currentLocation} />}

                        {!currentLocation && !newLocationForm &&
                            <div id="search-form">
                                <form className="search-input" noValidate autoComplete="off" onSubmit={handleSearch}>
                                    <input onChange={handleSearchInput} value={searchText ? searchText : ""} type="text" name="search" />
                                    <button className="search-input-button" type="submit"><FontAwesomeIcon icon="search" /></button>
                                    <span className="search-clear" onClick={handleSearchClear}>Clear</span>
                                </form>
                            </div>
                        }
                    </div>

                    {!currentLocation && !newLocationForm &&
                        <div id="app-locations" className="dashboard-panel">
                            <div className="dashboard-table">
                                <ul id="location-table-rows" className="dashboard-table-rows">

                                    <li id="location-table-header" className="dashboard-table-header row">
                                        <div className="dashboard-table-column">Address <FontAwesomeIcon onClick={(e) => handleSort("address")} icon={sortBy.key === 'address' && sortBy.desc === true ? faSortDown : sortBy.key === 'address' && sortBy.desc === false ? faSortUp : faSort} /></div>
                                        <div className="dashboard-table-column">Phone</div>
                                        <div className="dashboard-table-column">Hours</div>
                                    </li>

                                    {
                                        props.locations.locations && props.locations.locations.map(location => {

                                            return (
                                                <li className="row" key={location.address}>
                                                    <div className="dashboard-table-column"><p onClick={() => handleSelectLocation(location)}>{location.address}</p></div>
                                                    <div className="dashboard-table-column">{location.phone}</div>
                                                    <div className="dashboard-table-column">{location.hours}</div>
                                                </li>
                                            );

                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations
    }
}

export default connect(mapStateToProps)(AppLocations);

