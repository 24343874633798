

import React from "react";
import { connect } from 'react-redux';
import { useForm, } from 'react-hook-form';
import { updateWashFoldSettings } from "../../../actions/settings";

function WashFoldForm(props) {


    const { register, handleSubmit } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(updateWashFoldSettings(formData, () => {
            props.close();
        }));
    }

    return (
        <form className="wash-fold-settings-section" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
                <div className="form-input">
                    <label>Min Delivery Up to _ Lbs</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.washFoldUpToXLbs}</p>}

                    {props.editMode && <input type="text" name="washFoldUpToXLbs" ref={register} defaultValue={props.settings.washFoldUpToXLbs} />}
                </div>
                <div className="form-input">
                    <label>Price Per Lb After Minimum ($)</label>
                    {!props.editMode &&
                        <p className="current-value">{props.settings.washFoldPerLb}</p>}

                    {props.editMode && <input type="text" name="washFoldPerLb" ref={register} defaultValue={props.settings.washFoldPerLb} />}
                </div>
            </div>

            {props.editMode &&
                <div className="action-button-container">
                    <input className="submit-button update" type="submit" value="Update" />
                </div>
            }

        </form>

    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(WashFoldForm);