import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';
import MaskedInput from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray, Controller, ErrorMessage } from 'react-hook-form';

import { getUser, createUser, updateUser } from '../../../../actions/users';

function User(props) {

    let history = useHistory();
    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [firstName, setFirstName] = useState(props.user.firstName);
    const [lastName, setLastName] = useState(props.user.lastName);
    const [location, setLocation] = useState({ label: props.user.locationName, value: props.user.location });
    const [role, setRole] = useState({ label: props.user.role, value: props.user.role });
    const [phone, setPhone] = useState(props.user.phone);
    const [email, setEmail] = useState(props.user.email);

    const { register, handleSubmit, errors, control } = useForm({
        mode: "onChange"
    });

    // Format locations for Select input, excluding current location
    let locationsOptions;
    if (props.locations) {
        locationsOptions = props.locations.locations.map(location => {
            return {
                label: location.address,
                value: location._id
            }
        })
    }

    const handleCancel = () => {
        if (props.user) {
            setEditMode(false)
        } else {
            props.handleCancelEditUser();
        }
    }

    // Dispatch action 
    const onSubmit = (formData) => {

        props.dispatch(updateUser(props.user._id, formData, () => {
            setEmail(formData.email);
            setFirstName(formData.firstName);
            setLastName(formData.lastName);
            setPhone(formData.phone);
            setLocation(formData.location);
            setRole(formData.role);
            setEditMode(false);
        }));
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-panel-container">

                <div className="action-links-container">
                    {!editMode && <button onClick={() => setEditMode(true)}>Edit</button>}
                    {editMode && <button onClick={() => handleCancel()}>Cancel</button>}
                </div>


                <div id="app-user">

                    <form className="user-form" onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="role">Role</label>
                                {!editMode &&
                                    <p className="current-value">{role.label}</p>}

                                {editMode &&
                                    <Controller
                                        as={<Select
                                            placeholder="User Role"
                                            options={[
                                                {
                                                    label: "Super Admin",
                                                    value: "SuperAdmin"
                                                },
                                                {
                                                    label: "Admin",
                                                    value: "Admin"
                                                }
                                            ]}
                                        />
                                        }
                                        defaultValue={role}
                                        control={control}
                                        name="role"
                                        rules={{ required: true, validate: value => value !== "Select a user role." }}
                                    />
                                }
                            </div>
                        </div>


                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="location">Location</label>
                                {!editMode &&
                                    <p className="current-value">{location.label}</p>}
                                {editMode &&

                                    <Controller
                                        as={<Select
                                            placeholder="Location"
                                            options={locationsOptions}
                                        />
                                        }
                                        defaultValue={location}
                                        control={control}
                                        name="location"
                                        rules={{ required: true, validate: value => value !== "Select a location." }}
                                    />

                                }
                            </div>
                        </div>



                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="firstName">First Name</label>
                                {!editMode &&
                                    <p className="current-value">{firstName}</p>}
                                {editMode && <input type="text" name="firstName" defaultValue={firstName} ref={register} />}
                            </div>

                            <div className="form-input">
                                <label htmlFor="lastName">Last Name</label>
                                {!editMode &&
                                    <p className="current-value">{lastName}</p>}
                                {editMode && <input type="text" name="lastName" defaultValue={lastName} ref={register} />}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="email">Email</label>
                                {!editMode && <p className="current-value">{email}</p>}
                                {editMode && <input
                                    type="text"
                                    name="email"
                                    defaultValue={email}
                                    ref={
                                        register({
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Please enter a valid email address.'
                                            }
                                        })}

                                />}
                                <ErrorMessage errors={errors} name="email">
                                    {({ message }) => <p className="form-error-message" >{message}</p>}
                                </ErrorMessage>
                            </div>
                            {editMode &&
                                <div className="form-input">
                                    <label htmlFor="password">Temporary Password</label>
                                    <input type="password" name="password" ref={register} />
                                </div>
                            }
                        </div>



                        <div className="action-button-container">
                            <input className="submit-button bg-green" type="submit" value="Save" />
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users

    }
}


export default connect(mapStateToProps)(User);