import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Note: NavLink automatically adds active class to active link

function ProgressBar(props) {
  let history = useHistory();

  const [order, setOrder] = useState();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (
      props.editOrder &&
      props.editOrder.orderId &&
      props.auth.authenticated
    ) {
      setOrder(props.editOrder);
      setEditMode(true);
    } else {
      setOrder(props.localOrder);
      setEditMode(false);
    }
  }, [props.editOrder, props.localOrder, props.auth.authenticated]);

  const checkAddress = (e) => {
    if (order && !order.address) {
      e.preventDefault();

      history.push("/app/order/address");
    }
  };

  const checkSchedule = (e) => {
    if (order.schedule) {
      let {
        deliveryDate,
        deliveryTime,
        pickUpDate,
        pickUpTime,
      } = order.schedule;

      if (deliveryDate && deliveryTime && pickUpDate && pickUpTime) {
        return;
      }
    }
  };

  return (
    <div className="progress-bar">
      <NavLink to="/app/order/address" className="progress-bar-step">
        <div className="step-bar"></div>
        <div className="step-number">1</div>
        <div className="step-label">Location</div>
      </NavLink>

      <NavLink
        onClick={checkAddress}
        to="/app/order/services"
        className="progress-bar-step"
      >
        <div className="step-bar"></div>
        <div className="step-number">2</div>
        <div className="step-label">Services</div>
      </NavLink>

      {!editMode && (
        <NavLink to="/app/order/schedule" className="progress-bar-step">
          <div className="step-bar"></div>
          <div className="step-number">3</div>
          <div className="step-label">Schedule</div>
        </NavLink>
      )}

      <NavLink
        onClick={checkSchedule}
        to="/app/order/checkout"
        className="progress-bar-step"
      >
        <div className="step-number">{editMode ? "3" : "4"}</div>
        <div className="step-label">Check Out</div>
      </NavLink>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
  };
};

export default connect(mapStateToProps)(ProgressBar);
