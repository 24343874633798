function calculatePricing({ order, settings, gratuity = 0, discount = 0 }) {
  try {
    let {
      washFoldOrder,
      organicWashFoldOrder,
      washPressOrder,
      dryCleanOrder,
      comforterOrder,
      tailoringOrder,
      schedule,
    } = order;

    let {
      washFoldMinDelivery,
      organicWashFoldMinDelivery,
      washPressMinDelivery,
      dryCleanMinDelivery,
      comforterMinDelivery,
      tailoringMinDelivery,
    } = settings.settings;

    let serviceTotal = 0;
    let grandTotal = 0;
    let minimumDeliveryAmount = 0;
    let minimumDeliverySurcharge = 0;
    let multipleDelivery = 0;
    let dryClean = 0;
    let premiumProducts = 0;

    if (washFoldOrder) {
      serviceTotal += parseFloat(washFoldOrder.quote);
    }

    if (organicWashFoldOrder) {
      serviceTotal += parseFloat(organicWashFoldOrder?.quote);
    }

    if (washPressOrder) {
      serviceTotal += parseFloat(washPressOrder?.quote);
    }

    if (dryCleanOrder) {
      serviceTotal += parseFloat(dryCleanOrder?.quote);
    }

    if (tailoringOrder) {
      serviceTotal += parseFloat(tailoringOrder?.quote);
    }

    if (comforterOrder) {
      serviceTotal += parseFloat(comforterOrder?.quote);
    }

    // Minimum Delivery -- logic changed where minimum applies to each individual service only, regardless of serviceTotal

    // ORIGINAL:
    // if (washFoldOrder && serviceTotal < washFoldMinDelivery) {
    //   minimumDeliverySurcharge = washFoldMinDelivery - serviceTotal;
    //   minimumDeliveryAmount = washFoldMinDelivery;
    // }

    if (washFoldOrder || organicWashFoldOrder) {
      if (washFoldOrder) {
        if (parseFloat(washFoldOrder.quote) < washFoldMinDelivery) {
          minimumDeliverySurcharge =
            washFoldMinDelivery - parseFloat(washFoldOrder.quote);
          minimumDeliveryAmount = washFoldMinDelivery;
        }
      }

      if (organicWashFoldOrder) {
        if (
          parseFloat(organicWashFoldOrder.quote) < organicWashFoldMinDelivery
        ) {
          minimumDeliverySurcharge =
            organicWashFoldMinDelivery - parseFloat(organicWashFoldOrder.quote);
          minimumDeliveryAmount = organicWashFoldMinDelivery;
        }
      }
    } else {
      if (washPressOrder) {
        if (parseFloat(washPressOrder.quote) < washPressMinDelivery) {
          minimumDeliverySurcharge =
            washPressMinDelivery - parseFloat(washPressOrder.quote);
          minimumDeliveryAmount = washPressMinDelivery;
        }
      }

      if (dryCleanOrder) {
        if (parseFloat(dryCleanOrder.quote) < dryCleanMinDelivery) {
          minimumDeliverySurcharge =
            dryCleanMinDelivery - parseFloat(dryCleanOrder.quote);
          minimumDeliveryAmount = dryCleanMinDelivery;
        }
      }

      if (tailoringOrder) {
        if (parseFloat(tailoringOrder.quote) < tailoringMinDelivery) {
          minimumDeliverySurcharge =
            tailoringMinDelivery - parseFloat(tailoringOrder.quote);
          minimumDeliveryAmount = tailoringMinDelivery;
        }
      }

      if (comforterOrder) {
        if (parseFloat(comforterOrder.quote) < comforterMinDelivery) {
          minimumDeliverySurcharge =
            comforterMinDelivery - parseFloat(comforterOrder.quote);
          minimumDeliveryAmount = comforterMinDelivery;
        }
      }
    }

    // Multiple Delivery
    // If neither delivery meets the service minimum, calculate the surcharge based on the larger order
    if (schedule?.earlierDeliveryServiceQuote) {
      let firstDeliveryQuote = schedule.earlierDeliveryServiceQuote;
      let secondDeliveryQuote = serviceTotal - firstDeliveryQuote;

      if (
        firstDeliveryQuote < minimumDeliveryAmount &&
        secondDeliveryQuote < minimumDeliveryAmount
      ) {
        let largerDelivery = Math.max(firstDeliveryQuote, secondDeliveryQuote);

        multipleDelivery = minimumDeliveryAmount - largerDelivery;
      }
    }

    // Products
    if (washFoldOrder && washFoldOrder.productsWithPrices) {
      washFoldOrder.productsWithPrices.forEach((p) => {
        let productPrice = parseFloat(p[1]);
        premiumProducts += parseFloat(p[1]);
      });
    }

    if (organicWashFoldOrder && organicWashFoldOrder.productsWithPrices) {
      organicWashFoldOrder.productsWithPrices.forEach((p) => {
        premiumProducts += parseFloat(p[1]);
      });
    }

    grandTotal =
      serviceTotal +
      minimumDeliverySurcharge +
      multipleDelivery +
      dryClean +
      premiumProducts;

    // Gratuity
    if (gratuity) {
      grandTotal += parseFloat(gratuity);
    }

    // Discount / promo
    if (discount) {
      grandTotal -= parseFloat(discount);
    }

    return {
      serviceTotal,
      grandTotal,
      minimumDelivery: minimumDeliverySurcharge,
      multipleDelivery,
      dryClean,
      gratuity,
      discount,
      products: premiumProducts,
    };
  } catch (err) {
    console.log(err);
  }
}

module.exports = calculatePricing;
