import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";

// Form
import { useForm, ErrorMessage } from 'react-hook-form';

function ZipForm(props) {

    let history = useHistory();

    const [zipAvailable, setZipAvailable] = useState(null);
    const [showZipForm, setShowZipForm] = useState(true);
    const [availableZips, setAvailableZips] = useState(["10034", "10040", "10033", "10032", "10039", "10031", "10030", "10037", "10027", "10026", "10029", "10025", "10128", "10024", "10028", "10075", "10021", "10023", "10065", "10022", "10019", "10020", "10036", "10017", "10018", "10016", "10001", "10010", "10003", "10009", "10014", "10012", "10002", "10013", "10282", "10007", "10038", "10006", "10280", "10005", "10004", "10463", "10468", "10471", "10454", "11201", "11231", "11215", "11218", "11217", "11211", "11206", "11222", "11225", "11226", "11235", "11229", "11223", "11224", "11230", "11210", "10035"]);

    const { register, handleSubmit, errors, watch } = useForm({
        mode: "onChange",
        defaultValues: {}
    });

    const zip = watch('zip');

    useEffect(() => {

        let zipList = [];

        if (props.zips && props.zips.zips) {
            props.zips.zips.forEach(zip => {
                zipList.push(zip.zip);
            })
        }

        setAvailableZips(zipList);

    }, [props.zips]);


    useEffect(() => {
        if (zip && zip.toString().length === 5) {

            if (availableZips && availableZips.includes(zip.toString())) {
                setZipAvailable(true);
                setShowZipForm(false);
            } else {
                setZipAvailable(false);
            }

        }
    }, [zip]);

    // Dispatch action 
    const onSubmit = (formData) => {
        if (zipAvailable) {
            history.push("/signup/" + formData.zip);
        }
    }

    return (
        <div id="zip-form-container">
            <form id="zip-form" onSubmit={handleSubmit(onSubmit)}>

                <div className="zip-input-container">
                    <input type="tel" maxLength="5" name="zip" placeholder="ZIP CODE" ref={register({ required: "Please enter your email zip code." })} />

                    {zipAvailable == false &&
                        <div className="zip-message">Sorry! We're not yet available in your area.</div>
                    }

                    {zipAvailable &&
                        <div className="zip-message">We're available in your area!</div>
                    }

                </div>

                {zipAvailable &&
                    <div className="zip-form-button">
                        <input className="submit-button bg-green" type="submit" value="Schedule a Pickup" />
                    </div>
                }

            </form>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        zips: state.zips
    }
}


export default connect(mapStateToProps)(ZipForm);
