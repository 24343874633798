import { CREATE_ORDER, UPDATE_ORDER, GET_ORDERS, GET_ORDER, ASSIGN_ORDER_LOCATION, UPDATE_ORDER_STATUS, RESET_ORDER_STATE, ORDER_ERROR, CLEAR_ORDER_ERROR, UPDATE_ORDER_SERVICE, UPDATE_ORDER_SCHEDULE, UPDATE_ORDER_PAYMENT, REFUND_PAYMENT, EXPORT_ORDERS, CLEAR_CSV } from '../actions/types';

export default (state = {}, action) => {

    switch (action.type) {
        case CREATE_ORDER:
            return { ...state, newOrder: action.order };
        case GET_ORDER:
            return { ...state, order: action.order };
        case GET_ORDERS:
            return { ...state, orders: action.orders };
        case ASSIGN_ORDER_LOCATION:
            let locationIndex = state.orders.findIndex(
                (order) => order.orderId === action.order.orderId
            );
            let updatedLocationOrders = state.orders.map((item, index) => {
                if (index !== locationIndex) {
                    return item
                }

                return action.order;
            })
            return { ...state, orders: updatedLocationOrders, order: action.order };
        case UPDATE_ORDER_STATUS:
            let statusIndex = state.orders.findIndex(
                (order) => order.orderId === action.order.orderId
            );
            let updatedStatusOrders = state.orders.map((item, index) => {
                if (index !== statusIndex) {
                    return item
                }

                return action.order;
            })
            return { ...state, orders: updatedStatusOrders, order: action.order };
        case UPDATE_ORDER_PAYMENT:

            if (state.orders) {
                let paymentIndex = state.orders.findIndex(
                    (order) => order.orderId === action.order.orderId
                );
                let updatedPaymentOrders = state.orders.map((item, index) => {
                    if (index !== paymentIndex) {
                        return item
                    }

                    return action.order;
                })
                return { ...state, orders: updatedPaymentOrders, order: action.order };

            } else {
                return { ...state, order: action.order };
            }

        case UPDATE_ORDER_SERVICE:

            let serviceIndex = state.orders.findIndex(
                (order) => order.orderId === action.order.orderId
            );
            let updatedServiceOrders = state.orders.map((item, index) => {
                if (index !== serviceIndex) {
                    return item
                }

                return action.order;
            })
            return { ...state, orders: updatedServiceOrders, order: action.order };

        case UPDATE_ORDER:
            let updateIndex = state.orders.findIndex(
                (order) => order.orderId === action.order.orderId
            );
            let updatedOrders = state.orders.map((item, index) => {
                if (index !== updateIndex) {
                    return item
                }

                return action.order;
            })
            return { ...state, orders: updatedOrders, order: action.order };


        case UPDATE_ORDER_SCHEDULE:

            if (state.orders) {
                let scheduleIndex = state.orders.findIndex(
                    (order) => order.orderId === action.order.orderId
                );
                let updatedScheduleOrders = state.orders.map((item, index) => {
                    if (index !== scheduleIndex) {
                        return item
                    }

                    return action.order;
                })
                return { ...state, orders: updatedScheduleOrders, order: action.order };

            } else {
                return { ...state, order: action.order };
            }

        case RESET_ORDER_STATE:
            return {};
        case CLEAR_ORDER_ERROR:
            return { ...state, error: '' };
        case CLEAR_CSV:
            return { ...state, csv: '' };
        case ORDER_ERROR:
            return { ...state, error: action.payload };
        case EXPORT_ORDERS:
            return { ...state, csv: action.csv };
        case REFUND_PAYMENT:

            if (state.orders) {
                let paymentIndex = state.orders.findIndex(
                    (order) => order.orderId === action.order.orderId
                );
                let refundPaymentOrders = state.orders.map((item, index) => {
                    if (index !== paymentIndex) {
                        return item
                    }

                    return action.order;
                })
                return { ...state, orders: refundPaymentOrders, order: action.order };

            } else {
                return { ...state, order: action.order };
            }
        default:
            return state;
    }
};

