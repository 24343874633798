import { UPDATE_LOCAL_ORDER, RESET_LOCAL_ORDER_STATE, UPDATE_MINIMUM_DELIVERY_SURCHARGE, UPDATE_DRY_CLEAN_SURCHARGE, UPDATE_WASH_PRESS_SURCHARGE } from './types';


export function updateLocalOrder(formData, key, callback) {
    callback();
    return { type: UPDATE_LOCAL_ORDER, data: formData, key }
}

export function updateMinimumDeliverySurcharge(surcharge) {
    return { type: UPDATE_MINIMUM_DELIVERY_SURCHARGE, surcharge }
}

export function updateDryCleanSurcharge(surcharge) {
    return { type: UPDATE_DRY_CLEAN_SURCHARGE, surcharge }
}

export function updateWashPressSurcharge(surcharge) {
    return { type: UPDATE_WASH_PRESS_SURCHARGE, surcharge }
}

export function removeService(key) {
    return { type: UPDATE_LOCAL_ORDER, data: { test: "Test" }, key }
}

export function resetLocalOrderState() {

    return {
        type: RESET_LOCAL_ORDER_STATE
    };

}