import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import {
  updateWashPress,
  deleteService,
} from "../../../actions/settings_florida";

function WashPressForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateWashPress(formData, () => {
        props.close();
      })
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    props.dispatch(
      deleteService(props.product._id, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="product">
        <button className="delete" onClick={onDelete}>
          X
        </button>
        <input
          type="hidden"
          name="id"
          ref={register}
          defaultValue={props.product._id}
        />
        <input
          type="text"
          name="item"
          ref={register}
          defaultValue={props.product.item}
        />
        <input
          type="text"
          name="price"
          ref={register}
          defaultValue={props.product.price}
        />
        <button className="submit-button update" type="submit">
          Update
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(WashPressForm);
