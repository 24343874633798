import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useHistory, Link } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";

// Redux Actions
import {
  resetPassword,
  clearAuthError,
  authMessage,
  checkToken,
} from "../../actions/auth";

function ResetPassword(props) {
  let history = useHistory();
  let { token } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading animation
    setLoading(false);

    // Check for expired token
    props.dispatch(checkToken(token, () => console.log("checked token")));
  }, []);

  const clearError = () => {
    props.dispatch(clearAuthError());
  };

  // Dispatch action
  const onSubmit = (formData) => {
    formData.token = token;
    props.dispatch(resetPassword(formData, () => history.push("/login")));
    props.dispatch(authMessage(null));
  };

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onChange",
  });

  return (
    <div
      id="login"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <h2>Reset your password.</h2>

      {props.auth.error && (
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          {props.auth.error && <p>{props.auth.error}</p>}

          <div className="forgot-password-container">
            <Link to="/forgot-password" onClick={clearError}>
              Request Password Reset
            </Link>
          </div>
        </form>
      )}

      {!props.auth.error && (
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          {props.auth.error && <p>{props.auth.error}</p>}
          <div className="form-line">
            <div className="form-input">
              <input
                placeholder="New password"
                type="password"
                name="password"
                ref={register}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="form-input">
              <input
                placeholder="Confirm new password"
                type="password"
                name="password_confirm"
                ref={register({
                  validate: (value) => {
                    return value === watch("password");
                  },
                })}
              />
              <ErrorMessage errors={errors} name="password_confirm">
                {() => (
                  <p className="login-form-error-message">
                    Passwords must match.
                  </p>
                )}
              </ErrorMessage>
            </div>
          </div>

          <div className="action-button-container">
            <input
              className="submit-button bg-green"
              type="submit"
              value="Reset"
            />
          </div>

          <div className="forgot-password-container">
            <Link to="/login" onClick={clearError}>
              Log In
            </Link>
          </div>
        </form>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ResetPassword);
