import { SET_ORDER_TO_EDIT, UPDATE_EDIT_ORDER, RESET_EDIT_ORDER_STATE, UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE, UPDATE_EDIT_DRY_CLEAN_SURCHARGE, UPDATE_EDIT_WASH_PRESS_SURCHARGE } from './types';

export function setOrderToEdit(order, callback) {
    callback();
    return { type: SET_ORDER_TO_EDIT, order }
}

export function updateEditOrder(formData, key, callback) {
    callback();
    return { type: UPDATE_EDIT_ORDER, data: formData, key }
}

export function updateEditMinimumDeliverySurcharge(surcharge) {
    return { type: UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE, surcharge }
}

export function updateEditDryCleanSurcharge(surcharge) {
    return { type: UPDATE_EDIT_DRY_CLEAN_SURCHARGE, surcharge }
}

export function updateEditWashPressSurcharge(surcharge) {
    return { type: UPDATE_EDIT_WASH_PRESS_SURCHARGE, surcharge }
}

export function removeService(key) {
    return { type: UPDATE_EDIT_ORDER, data: { test: "Test" }, key }
}

export function resetEditOrderState() {
    return {
        type: RESET_EDIT_ORDER_STATE
    };
}