function calculateOrganicWashFold({ weight, products, settings }) {
  try {
    // let productsWithPrices = [];

    let {
      organicWashFoldMinDelivery,
      organicWashFoldUpToXLbs,
      organicWashFoldPerLb,
    } = settings.settings;

    let price =
      organicWashFoldMinDelivery +
      parseInt(weight - organicWashFoldUpToXLbs) * organicWashFoldPerLb;

    // if (products) {
    //   settings.products.forEach((p) => {
    //     if (products[p.item]) {
    //       price += p.price;
    //       productsWithPrices.push([p.item, p.price.toFixed(2)]);
    //     }
    //   });
    // }

    return {
      price: price,
      // products: productsWithPrices
    };
  } catch (err) {
    console.log(err);
  }
}

module.exports = calculateOrganicWashFold;
