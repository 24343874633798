import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import config from "../../../config/keys";
import Locations from "../../../data/locations.json";

const GoogleAPIKey = config.googleMaps;

function MapContainer(props) {
  const [mapCenter, setMapCenter] = useState({
    lat: 40.797814,
    lng: -73.9510804,
  });
  const [zoom, setZoom] = useState(11);

  useEffect(() => {
    if (props.coords) {
      setMapCenter({ lat: props.coords.lat, lng: props.coords.long });
      setZoom(15);
    }
  }, [props.coords]);
  if (!props.locations) {
    return null;
  }

  const generateMarkers = () => {
    return props.locations.map((location) => {
      return (
        <Marker
          key={location.address}
          onClick={() => {
            props.setLocation({
              address: location.address,
              city: location.city,
              state: location.state,
              zip: location.zip,
              phone: location.phone,
              hours: location.hours,
            });
            setMapCenter({ lat: location.lat, lng: location.long });
            setZoom(15);
          }}
          title={location.address}
          name={location.address}
          position={{ lat: location.lat, lng: location.long }}
        ></Marker>
      );
    });
  };
  return (
    <Map
      google={props.google}
      zoom={zoom}
      style={{
        width: "100%",
        height: "100%",
      }}
      initialCenter={mapCenter}
      center={mapCenter}
    >
      {generateMarkers()}
    </Map>
  );
}

// Find two color marker icons
// Import them into file to use URL
// When user clicks a marker, turn all other markers normal and make that selected
// When user searches a zipcode,
// convert zipcode to longitude / latitutde

// find closest location, center map on that location, make all other markers normal, make that marker selected

export default GoogleApiWrapper({
  apiKey: GoogleAPIKey,
})(MapContainer);
