import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { updateMinDeliveryDays } from "../../../actions/settings_florida";

function MinDeliveryDaysForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateMinDeliveryDays(formData, () => {
        props.close();
      })
    );
  };

  return (
    <form
      className="minimum-delivery-section"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-row">
        <div className="form-input">
          <label htmlFor="washFoldMinDeliveryDays">Wash & Fold</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.washFoldMinDeliveryDays}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="washFoldMinDeliveryDays"
              ref={register}
              defaultValue={props.settings.washFoldMinDeliveryDays}
            />
          )}
        </div>
        <div className="form-input">
          <label htmlFor="organicWashFoldMinDeliveryDays">
            Organic Wash & Fold
          </label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.organicWashFoldMinDeliveryDays}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="organicWashFoldMinDeliveryDays"
              ref={register}
              defaultValue={props.settings.organicWashFoldMinDeliveryDays}
            />
          )}
        </div>
        <div className="form-input">
          <label htmlFor="washPressMinDeliveryDays">Wash & Press</label>
          {!props.editMode && (
            <div>
              <p className="current-value">
                {props.settings.washPressMinDeliveryDays}
              </p>
            </div>
          )}

          {props.editMode && (
            <div>
              <input
                type="text"
                name="washPressMinDeliveryDays"
                ref={register}
                defaultValue={props.settings.washPressMinDeliveryDays}
              />
            </div>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-input">
          <label htmlFor="dryCleanMinDeliveryDays">Dry Clean</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.dryCleanMinDeliveryDays}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="dryCleanMinDeliveryDays"
              ref={register}
              defaultValue={props.settings.dryCleanMinDeliveryDays}
            />
          )}
        </div>
        <div className="form-input">
          <label htmlFor="tailoringMinDeliveryDays">Tailoring</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.tailoringMinDeliveryDays}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="tailoringMinDeliveryDays"
              ref={register}
              defaultValue={props.settings.tailoringMinDeliveryDays}
            />
          )}
        </div>
        <div className="form-input">
          <label htmlFor="comforterMinDeliveryDays">Comforter</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.comforterMinDeliveryDays}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="comforterMinDeliveryDays"
              ref={register}
              defaultValue={props.settings.comforterMinDeliveryDays}
            />
          )}
        </div>
      </div>

      {props.editMode && (
        <div className="action-button-container">
          <input
            className="submit-button update"
            type="submit"
            value="Update"
          />
        </div>
      )}
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(MinDeliveryDaysForm);
