import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { getLocations } from "../../actions/locations";
import { getZips } from "../../actions/zips";

import ZipForm from "../../global/components/ZipForm/ZipForm";

import "./Home.scss";

function Home(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showInstall, setShowInstall] = useState(false);
  const [limit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [sortBy, setSortBy] = useState({ key: "address", desc: false });

  useEffect(() => {
    dispatch(getZips());
    dispatch(getLocations(sortBy, limit, skip));
  }, [dispatch, sortBy, limit, skip]);

  useEffect(() => {
    setLoading(false);
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    const previouslyDismissed = localStorage.getItem("dismissedPWA");
    if (isIos() && !isInStandaloneMode() && !previouslyDismissed) {
      setShowInstall(true);
    }
  }, []);

  return (
    <div
      id="home"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <div id="home-hero" className="hero section flex-container">
        <div className="half text-block-left centered">
          <div className="content-container">
            <h1 className="green-text">
              Clean, green & outstanding laundry service, every time.
            </h1>
            <p>
              Responsible laundry and dry cleaning, the way you want it. Best
              prices. Best service. Best practices.
              <br />
              <span>Guaranteed.</span>
            </p>
          </div>
        </div>
        <div className="half image-container centered">
          <img src="/images/home-hero-clothes.png" alt="" />
        </div>
      </div>

      <div id="home-special-bar" className="bar">
        <div className="bar-image">
          <div className="sticker">
            <span>
              Current
              <br />
              Special
            </span>
            <span>Free</span>
            <span>Pick Up & Delivery</span>
          </div>
        </div>
        <div className="bar-color"></div>
      </div>

      <div id="home-promise" className="section flex-container">
        <div className="half text-block-left centered">
          <div className="content-container">
            <h2 className="white-text">
              Our best for you means the best for all of us.
            </h2>
            <div>
              <p>
                Big changes start with little ones, and sustainability starts
                with us all.
              </p>
              <p>
                By using only organic practices, Eco Miss Bubble promises a
                cleaner, healthier laundy service for you, for your family, and
                for all of us.
              </p>
              <p>
                All of our work is done on our premises - we don't use off-site
                services. This way we can manage and oversee every aspect of our
                cleaning process ensuring best practices for you and for us all.
              </p>
            </div>
            <div className="button-container">
              <NavLink to="/promise">
                <button>Our Practice &#8226; Our Promise</button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="half image-container centered">
          <img alt="" id="promise-badge" src="/images/organic-badge.png" />
          <img alt="" src="/images/home-promise-clothes.png" />
        </div>
      </div>

      <div id="home-info" className="section-alternating">
        <h2 className="heading-full green-text">
          New York's best laundry & dry cleaning services have never been
          faster, easier, or more convenient.
        </h2>

        <div className="flex-container">
          <div className="half image-container centered">
            <img alt="" className="app-screen" src="/images/webapp.png" />
          </div>

          <div className="half text-block-right centered">
            <div className="content-container">
              <h2 className="heading-half orange-text">
                Now offering online ordering for on demand laundry & dry
                cleaning.
                <br />
                <span className="green-text">
                  We pick up, we deliver. You sit back and relax.
                </span>
              </h2>
              <p>
                New York's best laundry and dry cleaning at the touch of a
                button. It's fasy, easy, and the best price around. Just enter
                your zip code to get started.
              </p>

              <ZipForm />
            </div>
          </div>
        </div>

        <div className="flex-container">
          <div className="half text-block-left">
            <div className="content-container">
              <h2 className="heading-half green-text">
                <span className="orange-text">
                  Visit our clean, green, comfortable laundromats.
                </span>
                <br />
                With more locations than anyone else in Manhattan.
              </h2>
              <p>
                We are New York City's largest chain and most trusted laundry
                service. With 28 locations across the city, our big, spacious,
                and comfortable laundromats are New Yorkers' favorite!
              </p>
              <div className="button-container">
                <NavLink to="/locations">
                  <button>Locations & Hours</button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="half image-container">
            <img alt="" src="/images/home-locations-map.png" />
          </div>
        </div>

        <div className="flex-container">
          <div className="half image-container centered">
            <img
              alt=""
              className="vertical"
              src="/images/home-services-jacket.png"
            />
          </div>

          <div className="half text-block-right centered">
            <div className="content-container">
              <h2 className="heading-half green-text">
                Say Hello to the
                <br />
                <span className="orange-text">
                  best prices and services anywhere!
                </span>
              </h2>
              <p>
                From crisp pressed shirts, to comfy-clean comforters, we handle
                virtually any laundry and tailoring need. And if you have a
                special need, just give us a call from 8am to 10pm, 7 days a
                week. (212) 928-WASH (9274)
              </p>
              <div className="button-container">
                <NavLink to="/pricing">
                  <button>Services & Prices</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-container">
          <div className="half text-block-left centered">
            <div className="content-container">
              <h2 className="heading-half green-text">
                <span className="orange-text">Is laundry day getting</span>
                <br />a little overwhelming?
              </h2>
              <p>
                Don’t dispair! Eco Miss Bubble makes laundry day easy with our
                sparkling clean self-service locations, and our easy drop-off
                services. And did we mention the price? The best anywhere, see
                for yourself!
              </p>
              <div className="button-container">
                <NavLink to="/specials">
                  <button>Current Specials</button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="half image-container centered">
            <img alt="" src="/images/home-specials-laundry.png" />
          </div>
        </div>
      </div>

      <div
        id="home-icons"
        className="section flex-container"
        style={{ backgroundImage: `url('/images/home-eco-shirt.png')` }}
      >
        <div className="half text-block-left centered">
          <div className="content-container">
            <h2 className="heading-half white-text">
              Save time. Save money. Save the planet.
            </h2>
            <p>
              Eco Miss Bubble offers all of your laundry and dry cleaning needs,
              with the best service and best prices in New York City.
            </p>

            <div className="icon-grid">
              <div className="icon-item">
                <img alt="" src="/images/home-icon-service.png" />
                <span>We Service All Clothing</span>
              </div>
              <div className="icon-item">
                <img alt="" src="/images/home-icon-press.png" />
                <span>
                  Press &<br />
                  Fold Services
                </span>
              </div>
              <div className="icon-item">
                <img alt="" src="/images/home-icon-delicate.png" />
                <span>Delicate Items</span>
                <br />
              </div>
              <div className="icon-item">
                <img alt="" src="/images/home-icon-stain.png" />
                <span>Stain Removal</span>
                <br />
              </div>
              <div className="icon-item">
                <img alt="" src="/images/home-icon-bulk.png" />
                <span>Bulk Laundry Services</span>
              </div>
              <div className="icon-item">
                <img alt="" src="/images/home-icon-schedule.png" />
                <span>
                  Schedule Pick Up
                  <br />& Delivery
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInstall && (
        <div
          className="bounce"
          id="add-to-homescreen"
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 100,
            textAlign: "center",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              marginLeft: "0.5em",
              marginRight: "0.5em",
              paddingLeft: "0.5em",

              borderRadius: "10px",
              border: "0.1em solid green",
              background: "white",
            }}
          >
            <div
              onClick={() => {
                setShowInstall(false);
                localStorage.setItem("dismissedPWA", true);
              }}
              style={{
                float: "right",
                borderWidth: "1em",
                borderRadius: "20px",
                borderColor: "red",
              }}
            >
              <FontAwesomeIcon
                icon="times"
                size="lg"
                style={{ color: "red", marginRight: "0.2em" }}
              />
            </div>
            <p style={{ fontSize: "1em" }}>
              Install this webapp on your iPhone: tap
              <img
                alt=""
                src="https://img.icons8.com/color/48/000000/share-rounded.png"
                style={{
                  height: "1.5em",
                  verticalAlign: "center",
                  color: "#147EFB",
                }}
              />
              and then Add to Homescreen.
            </p>
          </div>
          <FontAwesomeIcon
            icon={faArrowDown}
            size="2x"
            // className="arrow-down bounce"
            style={{ color: "#147EFB" }}
          />
        </div>
      )}
    </div>
  );
}

export default Home;
