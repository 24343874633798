import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTh,
  faList,
  faSortUp,
  faSortDown,
  faSort,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import Sidebar from "../../global/components/Sidebar/Sidebar";
import NewCustomer from "./components/NewCustomer/NewCustomer";
import Customer from "./components/Customer/Customer";

import {
  getCustomers,
  searchCustomers,
  exportCustomers,
} from "../../actions/users";

library.add([faTh, faList, faSortUp, faSortDown, faSort, faSearch]);

function AppCustomers(props) {
  const history = useHistory();

  useEffect(() => {
    if (props.auth && !props.auth.authenticated) {
      history.push("/login");
    }
  }, [props.auth]);

  const [newCustomerForm, setNewCustomerForm] = useState(false);
  const [sortBy, setSortBy] = useState({ key: "firstName", desc: true });
  const [limit, setLimit] = useState(75);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  useEffect(() => {
    if (props.users.customers && props.users.customers.length > 74) {
      setShowMoreButton(true);
    }
  }, [props.users.customers]);

  useEffect(() => {
    if (props.auth.authenticated) {
      props.dispatch(getCustomers(sortBy, limit, skip));
      props.dispatch(exportCustomers(sortBy, limit, skip));
    }
  }, [sortBy, limit]);

  const showMore = () => {
    setLimit(limit + 75);
  };

  const handleSort = (column) => {
    // If already sorting by this column, flip the order, else default to descending
    if (sortBy.key === column) {
      setSortBy({ key: column, desc: !sortBy.desc });
    } else {
      setSortBy({ key: column, desc: true });
    }
  };

  const handleSelectCustomer = (customer) => {
    setCurrentCustomer(customer);
  };

  const handleCancelEditCustomer = () => {
    setCurrentCustomer(null);
    setNewCustomerForm(false);
    props.dispatch(getCustomers(sortBy, limit, skip));
  };

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props.dispatch(searchCustomers(sortBy, searchText));
    props.dispatch(exportCustomers(sortBy, limit, skip));
  };

  // Sorting is handled by passing sortBy argument to getCustomers
  const handleSearchClear = () => {
    setSearchText("");
    props.dispatch(getCustomers(sortBy, limit, skip));
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-panel-container">
        <div id="app-users" className="dashboard-panel">
          <div className="dashboard-button-container">
            {!newCustomerForm && (
              <button
                onClick={() => setNewCustomerForm(true)}
                className="button-link"
              >
                Add a Customer
              </button>
            )}

            {newCustomerForm && (
              <NewCustomer
                handleCancelEditCustomer={handleCancelEditCustomer}
              />
            )}

            {currentCustomer && (
              <Customer
                handleCancelEditCustomer={handleCancelEditCustomer}
                customer={currentCustomer}
              />
            )}

            {!currentCustomer && !newCustomerForm && (
              <div id="search-form">
                <form
                  className="search-input"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSearch}
                >
                  <input
                    onChange={handleSearchInput}
                    value={searchText}
                    type="text"
                    name="search"
                  />
                  <button className="search-input-button" type="submit">
                    <FontAwesomeIcon icon="search" />
                  </button>
                  <span className="search-clear" onClick={handleSearchClear}>
                    Clear
                  </span>
                </form>
              </div>
            )}
          </div>

          {props.csv && (
            <div className="export-results-container">
              <CSVLink
                className="button"
                filename="orders.csv"
                data={props.csv}
              >
                <button>Export Results</button>
              </CSVLink>
            </div>
          )}

          {!currentCustomer && !newCustomerForm && props.auth.user && (
            <div className="dashboard-table">
              <ul id="user-table-rows" className="dashboard-table-rows">
                <li
                  id="user-table-header"
                  className="dashboard-table-header row"
                >
                  <div className="dashboard-table-column">
                    First Name{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("firstName")}
                      icon={
                        sortBy.key === "firstName" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "firstName" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  <div className="dashboard-table-column">
                    Last Name{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("lastName")}
                      icon={
                        sortBy.key === "lastName" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "lastName" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  <div className="dashboard-table-column">
                    Phone{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("phone")}
                      icon={
                        sortBy.key === "phone" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "phone" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>

                  <div className="dashboard-table-column">
                    Email{" "}
                    <FontAwesomeIcon
                      onClick={(e) => handleSort("email")}
                      icon={
                        sortBy.key === "email" && sortBy.desc === true
                          ? faSortDown
                          : sortBy.key === "email" && sortBy.desc === false
                          ? faSortUp
                          : faSort
                      }
                    />
                  </div>
                </li>

                {props.users.customers &&
                  props.users.customers.map((user) => {
                    return (
                      <li className="row">
                        <div className="dashboard-table-column">
                          {user.firstName && (
                            <a onClick={() => handleSelectCustomer(user)}>
                              {user.firstName}
                            </a>
                          )}

                          {!user.firstName && (
                            <a onClick={() => handleSelectCustomer(user)}>
                              {user.email}
                            </a>
                          )}
                        </div>
                        <div className="dashboard-table-column">
                          {user.lastName}
                        </div>
                        <div className="dashboard-table-column">
                          {user.phone}
                        </div>
                        <div className="dashboard-table-column">
                          {user.email}
                        </div>
                      </li>
                    );
                  })}
                {showMoreButton && (
                  <li className="row show-more-row">
                    <button onClick={() => showMore()}>Show More</button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.users,
    csv: state.users.csv,
  };
};

export default connect(mapStateToProps)(AppCustomers);
