import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { createZip, deleteZip } from "../../../actions/zips";

function ZipCodeForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    formData.florida = false;
    props.dispatch(
      createZip(formData, () => {
        props.close();
      })
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    props.dispatch(
      deleteZip(props.zip._id, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="zip">
        <button className="delete" onClick={onDelete}>
          X
        </button>
        <input type="hidden" ref={register} value={props.zip._id} name="id" />
        <input
          type="text"
          name="zip"
          ref={register}
          defaultValue={props.zip.zip}
        />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(ZipCodeForm);
