import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

// Components
import BannerFooter from "../../global/components/BannerFooter/BannerFooter";
import AccordionItem from "./components/AccordionItem";

import faqs from "../../data/faqs.json";
import "./Support.scss";

library.add([faCaretDown, faCaretUp]);

function Support(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div
      id="support"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <div id="support-hero" className="flex-container">
        <div className="half centered">
          <div className="content-container">
            <h1 className="green-text">
              Frequently Asked
              <br />
              <span className="orange-text">Questions.</span>
            </h1>
          </div>
        </div>
        <div className="half image-container half centered">
          <img src="/images/support-hanger-leaf.png" />
        </div>
      </div>

      <div
        id="faq-section"
        style={{ backgroundImage: `url('/images/support-background.jpg')` }}
      >
        <div id="support-accordion">
          {faqs.map((faq) => {
            return (
              <AccordionItem
                key={faq.question}
                question={faq.question}
                answer={faq.answer}
              />
            );
          })}
        </div>
      </div>

      <BannerFooter />
    </div>
  );
}

export default Support;
