import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { createOrder, updateOrder } from "../../../../actions/orders";
import { resetLocalOrderState } from "../../../../actions/localOrder";
import { resetEditOrderState } from "../../../../actions/editOrder";
import {
  getPaymentMethods,
  fetchSetupSecret,
} from "../../../../actions/payment";

import calculatePricing from "../../../../utils/calculatePricing";
import getPaymentIntent from "../../../../utils/getPaymentIntent";

// Form
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

function CheckoutStep({
  auth,
  editOrder,
  localOrder,
  settings,
  settingsFlorida,
  payment,
  user,
  setOrderComplete,
  orders,
  newOrder,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [order, setOrder] = useState();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [saveNewCard, setSaveNewCard] = useState(false);
  const [sms, setSMS] = useState(false);

  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);

  const [cardsOptions, setCardsOptions] = useState(null);
  const [isFlorida, setIsFlorida] = useState(false);

  const [paymentInitiated, setPaymentInitiated] = useState(false);

  const { control, watch, handleSubmit, register } = useForm({
    mode: "onChange",
  });

  function isLessThanXHours(hours, delivery, pickup) {
    const date1 = new Date(delivery);
    const date2 = new Date(pickup);

    const timeDifference = Math.abs(date1 - date2);

    return timeDifference < hours * 60 * 60 * 1000;
  }

  useEffect(() => {
    if (editOrder?.orderId && auth?.authenticated) {
      setOrder(editOrder);
    } else {
      setOrder(localOrder);
    }

    if (user && !editOrder) {
      dispatch(getPaymentMethods());
    }
  }, [auth, editOrder, localOrder]);

  useEffect(() => {
    if (
      editOrder?.address?.state.toLowerCase() == "fl" ||
      localOrder?.address?.state?.toLowerCase() == "fl"
    ) {
      setIsFlorida(true);
    } else {
      setIsFlorida(false);
    }
  }, [editOrder, localOrder]);

  const email = watch("email");
  const phone = watch("phone");
  const firstName = watch("firstName");
  const card = watch("card");

  // Display customer's saved cards
  useEffect(() => {
    if (payment.cards && !editOrder) {
      let cards = [];
      payment.cards.forEach((card) => {
        cards.push({
          label: card.card.last4,
          value: card.id,
        });
      });
      cards.push({
        label: "New Card",
        value: "newCard",
      });
      setCardsOptions(cards);
    }
  }, [payment]);

  // Secret for saving card to Stripe, will use if checked
  useEffect(() => {
    if (user) {
      dispatch(fetchSetupSecret(user));

      if (user.role === "Customer") {
        dispatch(resetEditOrderState());
      }
    }
  }, [user]);

  const cardHandleChange = (e) => {
    const { error } = e;
    setError(error?.message || "");
  };

  const handleSubmitChanges = () => {
    let updatedOrder = editOrder;

    let result = calculatePricing({
      order: updatedOrder,
      settings: isFlorida ? settingsFlorida : settings,
      gratuity: updatedOrder.gratuity,
      discount: updatedOrder.promo,
    });

    if (result.grandTotal) {
      updatedOrder.quoteTotalPrice = result.grandTotal;
    }

    dispatch(
      updateOrder(updatedOrder, user, () => {
        dispatch(resetLocalOrderState());
        dispatch(resetEditOrderState());
        history.push("/app/orders");
      })
    );
  };

  const onPaymentSubmit = async (data) => {
    // Make sure pickup/delivery dates still apply

    let minHours = 0;

    if (order.dryCleanOrder) {
      minHours = settings.settings.dryCleanMinDeliveryDays * 24;
    }
    if (order.tailoringOrder) {
      minHours = settings.settings.tailoringMinDeliveryDays * 24;
    }
    if (order.washPress) {
      minHours = settings.settings.washPressMinDeliveryDays * 24;
    }
    if (order.comforter) {
      minHours = settings.settings.comforterMinDeliveryDays * 24;
    }

    if (
      isLessThanXHours(
        minHours,
        order.schedule.deliveryDate,
        order.schedule.pickUpDate
      )
    ) {
      setError(
        "Delivery time is no longer available for this order. Please update your scheduled delivery to place this order."
      );
      return;
    }

    setPaymentInitiated(true);
    try {
      if (!order) {
        return;
      }

      // Prevents double-clicking
      if (paymentInitiated) {
        return;
      }

      let result = calculatePricing({
        order: order,
        settings: isFlorida ? settingsFlorida : settings,
        gratuity: order.gratuity,
        discount: 0, // update
      });

      if (!result) {
        setError(
          "There was an error with order pricing. Please contact us for assistance."
        );
        return;
      }

      let orderTotal = result.grandTotal;

      if (!orderTotal) {
        setError(
          "There was an error with your order. Please contact us for assistance. Your card has not been authorized."
        );
        return;
      }

      let amountToAuth = (orderTotal + orderTotal / 2) * 100;

      let secret = await getPaymentIntent({
        user,
        amount: amountToAuth,
        order,
      });

      setProcessing(true);

      if (!secret) {
        setError(
          "There was an payment processing error. Please contact us for assistance. Your card has not been authorized."
        );
        setProcessing(false);
        return;
      }

      let paymentArgs;
      if (card && card.value != "newCard") {
        paymentArgs = {
          payment_method: card.value,
        };
      } else {
        paymentArgs = {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        };
      }

      let payload = await stripe.confirmCardPayment(secret, paymentArgs);
      setProcessing(false);

      if (!payload || payload.error) {
        setError(payload.error.message);
        return;
      } else {
        order.quoteTotalPrice = orderTotal;
        order.stripePaymentIntentId = payload.paymentIntent.id;
        order.amountAuthorized = amountToAuth;
        order.firstName = firstName;
        order.email = email;
        order.phone = phone;
        order.sms = sms;
        order.promo = order.promo ? order.promo._id : null;
        order.instructions = data.instructions;

        dispatch(
          createOrder(order, user, () => {
            setShowOrderConfirmation(true);
            setOrderComplete(true);
            dispatch(resetEditOrderState());
            dispatch(resetLocalOrderState());
            setPaymentInitiated(false);
            setError(null);
          })
        );

        // Save card to account if saveNewCard is selected
        if (
          !card ||
          (card.value == "newCard" && payment.secret && saveNewCard)
        ) {
          const confirmCardResult = await stripe.confirmCardSetup(
            payment.secret,
            {
              payment_method: {
                card: elements.getElement(CardNumberElement),
              },
            }
          );

          if (!confirmCardResult || confirmCardResult.error) {
            setError(confirmCardResult.error.message);
          }
        }
      }
    } catch (err) {
      setProcessing(false);
      setError(
        "There was an error processing your order. Please contact us for assistance."
      );
    }
  };

  return (
    <div id="checkout-step" className="order-step">
      <div>
        {editOrder && (
          <div className="action-button-container">
            <input
              id="submit-changes-button"
              onClick={handleSubmitChanges}
              className="submit-button bg-green"
              value="Submit Changes"
            />
          </div>
        )}

        {!showOrderConfirmation && !editOrder && (
          <>
            <div className="error-message" style={{ marginTop: "25px" }}>
              {orders?.error || error}
            </div>

            <form id="order-form" onSubmit={handleSubmit(onPaymentSubmit)}>
              <h4>Instructions (Optional)</h4>

              <input
                style={{ width: "100%", height: "30px" }}
                type="text"
                name="instructions"
                ref={register}
              />

              <h4>Payment Details</h4>

              {user && (
                <Controller
                  as={
                    <Select options={cardsOptions} placeholder="Select Card" />
                  }
                  control={control}
                  name="card"
                  rules={{
                    required: false,
                    validate: (value) => value !== "Select Card",
                  }}
                />
              )}

              {(!user || (card && card.value == "newCard")) && (
                <div className="stripe-card">
                  <div className="stripe-element-container">
                    <CardNumberElement
                      className="card-element"
                      onChange={cardHandleChange}
                    />
                  </div>

                  <div className="stripe-element-container">
                    <CardExpiryElement
                      className="card-element"
                      onChange={cardHandleChange}
                    />
                  </div>

                  <div className="stripe-element-container">
                    <CardCvcElement
                      className="card-element"
                      onChange={cardHandleChange}
                    />
                  </div>

                  {user && (
                    <div className="save-card-container">
                      <input
                        type="checkbox"
                        checked={saveNewCard}
                        onChange={() => setSaveNewCard(!saveNewCard)}
                      />
                      <label>Save Card</label>
                    </div>
                  )}

                  <div className="sms-updates-container">
                    <input
                      type="checkbox"
                      checked={sms}
                      onChange={() => setSMS(!sms)}
                    />
                    <label>
                      Send me SMS text updates (message rates may apply).
                    </label>
                  </div>
                </div>
              )}

              <div className="action-button-container">
                <input
                  className="submit-button bg-green"
                  type="submit"
                  disabled={processing}
                  value={
                    processing && !orders.error
                      ? "Processing..."
                      : "Authorize Card"
                  }
                />
              </div>
            </form>
          </>
        )}
      </div>

      {showOrderConfirmation && (
        <div>
          <p className="thank-you">
            Thank you for your order! Please check your email for a
            confirmation.
          </p>

          {user && (
            <div className="action-button-container">
              <NavLink to={`/app/order/id/${newOrder._id}`}>
                <button className="reschedule-button">
                  View Order Details
                </button>
              </NavLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    user: state.auth.user,
    editOrder: state.editOrder,
    localOrder: state.localOrder,
    newOrder: state.orders.newOrder,
    payment: state.payment,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(CheckoutStep);
