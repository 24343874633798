function calculateTailoring({ items, settings, services }) {
  let price = 0;
  let tailoringServices = settings.tailoringServices;
  let tailoringItems = [];

  items.forEach((item, index) => {
    let priceListItem = tailoringServices.find((o) => {
      return o.item === item.value;
    });

    let selectedServices = services[index];

    selectedServices.forEach((s) => {
      price += priceListItem.options[`${s}`];
    });

    tailoringItems.push(item);
  });

  return { price, items: tailoringItems };
}

module.exports = calculateTailoring;
