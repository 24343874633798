import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import calculateWashFold from "../../../../../utils/calculateWashFold";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import { useForm, ErrorMessage } from "react-hook-form";

function WashFold(props) {
  const [order, setOrder] = useState(null);
  const [washFoldMinDelivery, setWashFoldMinDelivery] = useState(29);
  const [washFoldUpToXLbs, setWashFoldUpToXLbs] = useState(20);
  const [washFoldPerLb, setWashFoldPerLb] = useState(1.45);
  const [defaultWeight, setDefaultWeight] = useState();
  const [products, setProducts] = useState(null);
  const [isFlorida, setIsFlorida] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [floridaProductOptions, setFloridaProductOptions] = useState([]);

  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);

      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }

      if (props.editOrder.washFoldOrder) {
        setProducts(props.editOrder.washFoldOrder.products);
      } else {
        setProducts({});
      }
    } else {
      setOrder(props.localOrder);
      setProducts({});
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  // PRODUCTS
  useEffect(() => {
    setFloridaProductOptions(props.settingsFlorida.products);
    setProductOptions(props.settings.products);

    if (isFlorida) {
      setWashFoldMinDelivery(
        props.settingsFlorida.settings.washFoldMinDelivery
      );
      setWashFoldUpToXLbs(props.settingsFlorida.settings.washFoldUpToXLbs);
      setWashFoldPerLb(props.settingsFlorida.settings.washFoldPerLb);
    } else {
      setWashFoldMinDelivery(props.settings.settings.washFoldMinDelivery);
      setWashFoldUpToXLbs(props.settings.settings.washFoldUpToXLbs);
      setWashFoldPerLb(props.settings.settings.washFoldPerLb);
    }
  }, [props.settings, props.settingsFlorida, isFlorida]);

  // Default weight from order
  useEffect(() => {
    if (order && order.washFoldOrder) {
      setDefaultWeight(order.washFoldOrder.weight);
    }
  }, [order]);

  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
  });

  let weight = watch("weight");
  let selectedProducts = watch("products");
  const history = useHistory();

  const onSubmit = (data) => {
    let { price, products } = calculateWashFold({
      weight: weight || order?.washFoldOrder.weight,
      products: selectedProducts || order?.washFoldOrder.products,
      settings: isFlorida ? props.settingsFlorida : props.settings,
      isFlorida,
    });

    data.quote = price.toFixed(2);
    data.productsWithPrices = products;

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "washFoldOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "washFoldOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="wash-fold-step" className="order-step">
      <h4 className="step-header">
        Approximately how many pounds is your wash & fold order?
      </h4>

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-line">
          <input
            type="number"
            name="weight"
            min={0}
            defaultValue={defaultWeight}
            ref={register({
              required:
                "Please provide your best estimate. You will be charged for the exact weight once we receive your items.",
            })}
          />{" "}
          lbs
          <ErrorMessage errors={errors} name="weight">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
          {weight < washFoldUpToXLbs && (
            <p className="form-error-message">
              For pick up / delivery orders under {washFoldUpToXLbs} lbs, you
              will be charged the minimum price of ${washFoldMinDelivery}.
            </p>
          )}
        </div>

        <div className="form-line">
          <div className="form-input">
            <label htmlFor="products">
              Products:
              <br />
              <span>
                (Optional - if none selected, we will use our complimentary
                detergent and softener)
              </span>
            </label>
            {isFlorida && (
              <div className="form-checkbox-group">
                {floridaProductOptions &&
                  floridaProductOptions.map((product) => {
                    return (
                      <div className="products-item">
                        <input
                          defaultChecked={
                            products[product.item]
                              ? products[product.item]
                              : false
                          }
                          type="checkbox"
                          name={`products[${product.item}]`}
                          ref={register}
                        />
                        <div htmlFor={`products[${product.item}]`}>
                          {product.item} - ${product.price.toFixed(2)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            {!isFlorida && (
              <div className="form-checkbox-group">
                {productOptions &&
                  productOptions.map((product) => {
                    return (
                      <div className="products-item">
                        <input
                          defaultChecked={
                            products[product.item]
                              ? products[product.item]
                              : false
                          }
                          type="checkbox"
                          name={`products[${product.item}]`}
                          ref={register}
                        />
                        <div htmlFor={`products[${product.item}]`}>
                          {product.item} - ${product.price.toFixed(2)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.washFoldOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    editOrder: state.editOrder,
    localOrder: state.localOrder,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(WashFold);
