import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Tailoring
import calculateTailoring from "../../../../../utils/calculateTailoring";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import Select from "react-select";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Tailoring(props) {
  const [order, setOrder] = useState();
  const [tailoringItems, setTailoringItems] = useState();
  const [tailoringSelectOptions, setTailoringSelectOptions] = useState();
  const [tailoringMinDelivery, setTailoringMinDelivery] = useState(32);
  const [isFlorida, setIsFlorida] = useState(false);

  const {
    control,
    watch,
    errors,
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
  });

  // Refactor later
  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    } else {
      setOrder(props.localOrder);
      if (props.localOrder?.address?.state?.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    if (isFlorida) {
      if (props.settingsFlorida) {
        setTailoringItems(props.settingsFlorida.tailoringServices);

        if (props.settingsFlorida.tailoringServices) {
          // Create Select/dropdown option values for Tailoring
          let options = props.settingsFlorida.tailoringServices.map(
            (tailoringItem) => {
              let availableServices = [];

              for (const property in tailoringItem.options) {
                if (tailoringItem.options[property]) {
                  availableServices.push({
                    label: property.charAt(0).toUpperCase() + property.slice(1),
                    value: property,
                  });
                }
              }

              return {
                label: tailoringItem.item,
                value: tailoringItem.item,
                availableServices,
              };
            }
          );

          setTailoringSelectOptions(options);
        }

        if (props.settingsFlorida.settings) {
          setTailoringMinDelivery(props.settings.settings.tailoringMinDelivery);
        }
      }
    } else {
      if (props.settings) {
        setTailoringItems(props.settings.tailoringServices);

        if (props.settings.tailoringServices) {
          // Create Select/dropdown option values for Tailoring
          let options = props.settings.tailoringServices.map(
            (tailoringItem) => {
              let availableServices = [];

              for (const property in tailoringItem.options) {
                if (tailoringItem.options[property]) {
                  availableServices.push({
                    label: property.charAt(0).toUpperCase() + property.slice(1),
                    value: property,
                  });
                }
              }

              return {
                label: tailoringItem.item,
                value: tailoringItem.item,
                availableServices,
              };
            }
          );

          setTailoringSelectOptions(options);
        }

        if (props.settings.settings) {
          setTailoringMinDelivery(props.settings.settings.tailoringMinDelivery);
        }
      }
    }
  }, [props.settings, props.settingsFlorida]);

  useEffect(() => {
    if (order) {
      if (order.tailoringOrder && tailoringSelectOptions) {
        let items = order.tailoringOrder.items.map((tailoringItem) => {
          let foundOption = tailoringSelectOptions.find((option) => {
            return option.value === tailoringItem.type;
          });

          let existingItem = {
            label: tailoringItem.type
              ? tailoringItem.type
              : tailoringItem.value,
            value: tailoringItem.type
              ? tailoringItem.type
              : tailoringItem.value,

            availableServices: tailoringItem.availableServices
              ? tailoringItem.availableServices
              : foundOption.availableServices,
          };

          return existingItem;
        });

        let services;
        if (order.tailoringOrder.services) {
          services = order.tailoringOrder.services;
        } else {
          services = order.tailoringOrder.items.map((tailoringItem) => {
            return tailoringItem.services;
          });
        }

        let instructions;
        if (order.tailoringOrder.instructions) {
          instructions = order.tailoringOrder.instructions;
        } else {
          instructions = order.tailoringOrder.items.map((tailoringItem) => {
            return tailoringItem.instructions;
          });
        }

        reset({
          items,
          services,
          instructions,
        });
      }
    }
  }, [order, tailoringSelectOptions]);

  let history = useHistory();

  const onSubmit = (data) => {
    let { price, items } = calculateTailoring({
      items: data.items,
      services: data.services,
      settings: isFlorida ? props.settingsFlorida : props.settings,
    });

    data.quote = price.toFixed(2);
    data.items = items;

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "tailoringOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "tailoringOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="tailoring-step" className="order-step">
      <h4 className="step-header">
        Please add items for tailoring and provide instructions below.
      </h4>

      {/* <p className="form-error-message">
        For pick up / delivery orders under {tailoringMinDelivery} lbs, you will
        be charged the minimum price of ${tailoringMinDelivery}.
      </p> */}

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((i, index) => {
          let selectedTailoringItem;
          selectedTailoringItem = watch(`items[${index}]`);

          return (
            <div
              key={i.id}
              className="tailoring-repeater-module repeater-module"
            >
              <div className="repeater-item">
                <div className="item-name">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={tailoringSelectOptions}
                          placeholder="Item"
                        />
                      }
                      control={control}
                      name={`items[${index}]`}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />
                    <ErrorMessage errors={errors} name={`items[${index}]`}>
                      {() => (
                        <p className="form-error-message">
                          Item cannot be empty.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="cancel">
                  <button
                    className="form-input-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </div>

                <div className="item-options">
                  <div className="form-checkbox-group">
                    {selectedTailoringItem &&
                      selectedTailoringItem.availableServices &&
                      selectedTailoringItem.availableServices.map((service) => {
                        return (
                          <div key={service.label}>
                            <label>{service.label}</label>
                            <input
                              type="checkbox"
                              name={`services[${index}]`}
                              ref={register}
                              value={service.value}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="item-instructions">
                  <textarea
                    ref={register}
                    placeholder="Instructions"
                    name={`instructions[${index}]`}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="action-button-container">
          <button
            className="form-add bg-orange"
            type="button"
            onClick={() => {
              append({ type: "Select Item" });
            }}
          >
            <FontAwesomeIcon icon="plus" /> Item
          </button>
        </div>
        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.tailoringOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    tailoringOrder: state.localOrder.tailoringOrder,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
    editOrder: state.editOrder,
    localOrder: state.localOrder,
  };
};

export default connect(mapStateToProps)(Tailoring);
