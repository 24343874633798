import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import calculateWashPress from "../../../../../utils/calculateWashPress";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import Select from "react-select";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function WashPress(props) {
  const [order, setOrder] = useState();
  const [washPressItems, setWashPressItems] = useState();
  const [washPressSelectOptions, setWashPressSelectOptions] = useState();
  const [washPressMinDelivery, setWashPressMinDelivery] = useState(32);
  const [isFlorida, setIsFlorida] = useState(false);

  // Refactor later
  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    } else if (props.localOrder) {
      setOrder(props.localOrder);
      if (props.localOrder?.address?.state?.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    }
  }, [props.editOrder, props.localOrder]);

  const { control, errors, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    if (isFlorida) {
      if (props.settingsFlorida) {
        setWashPressItems(props.settingsFlorida.washPressServices);

        if (props.settingsFlorida.washPressServices) {
          // Create Select/dropdown option values for Wash Press
          let options = props.settingsFlorida.washPressServices.map(
            (washPressItem) => {
              return { label: washPressItem.item, value: washPressItem.item };
            }
          );

          setWashPressSelectOptions(options);
        }

        if (props.settingsFlorida.settings) {
          setWashPressMinDelivery(
            props.settingsFlorida.settings.washPressMinDelivery
          );
        }
      }
    } else {
      if (props.settings) {
        setWashPressItems(props.settings.washPressServices);

        if (props.settings.washPressServices) {
          // Create Select/dropdown option values for Wash Press
          let options = props.settings.washPressServices.map(
            (washPressItem) => {
              return { label: washPressItem.item, value: washPressItem.item };
            }
          );

          setWashPressSelectOptions(options);
        }

        if (props.settings.settings) {
          setWashPressMinDelivery(props.settings.settings.washPressMinDelivery);
        }
      }
    }
  }, [props.settings]);

  useEffect(() => {
    if (order?.washPressOrder && washPressSelectOptions) {
      let items = order.washPressOrder.items.map((washPressItem) => {
        let q = washPressItem.quantity;

        if (q && q.value) {
          q = q.value;
        }

        if (!q) {
          q = 1;
        }

        let existingItem = {
          label: washPressItem.type ? washPressItem.type : washPressItem.value,
          value: washPressItem.type ? washPressItem.type : washPressItem.value,
          quantity: {
            label: q,
            value: q,
          },
        };

        return existingItem;
      });

      reset({
        items,
      });
    } else if (props.washPressOrder) {
      setValue("items", props.washPressOrder.items);
    }
  }, [order, washPressSelectOptions]);

  // Create Select/dropdown option values for Wash & Press
  // let washPressSelectOptions = WashPressItems.map(washPress => {
  //     return { label: washPress.item, value: washPress.item }
  // });

  let quantityOptions = [];
  for (let i = 1; i < 21; i++) {
    quantityOptions.push({ label: i, value: i });
  }

  let history = useHistory();

  const onSubmit = (data) => {
    let { price } = calculateWashPress({
      items: data.items,
      settings: isFlorida ? props.settingsFlorida : props.settings,
    });

    data.quote = price.toFixed(2);

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "washPressOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "washPressOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="wash-press-step" className="order-step">
      <h4 className="step-header">What would you like washed and pressed?</h4>

      {/* <p className="form-error-message">
        For pick up / delivery orders under {washPressMinDelivery} lbs, you will
        be charged the minimum price of ${washPressMinDelivery}.
      </p> */}

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((i, index) => {
          return (
            <div
              key={i.id}
              className="washpress-repeater-module repeater-module"
            >
              <div className="repeater-item">
                <div className="item-name">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={washPressSelectOptions}
                          placeholder="Item"
                        />
                      }
                      control={control}
                      name={`items[${index}]`}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />

                    <ErrorMessage errors={errors} name={`items[${index}]`}>
                      {() => (
                        <p className="form-error-message">
                          Item cannot be empty.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="item-quantity">
                  <div className="select">
                    <Controller
                      as={
                        <Select
                          options={quantityOptions}
                          placeholder="Quantity"
                        />
                      }
                      control={control}
                      name={`items[${index}].quantity`}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`items[${index}].quantity`}
                    >
                      {() => (
                        <p className="form-error-message">
                          Please select a quantity.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="cancel item-cancel">
                    <button
                      className="form-input-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="action-button-container">
          <button
            className="form-add bg-orange"
            type="button"
            onClick={() => {
              append({ type: "Select Item" });
            }}
          >
            <FontAwesomeIcon icon="plus" /> Item
          </button>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.washPressOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    washPressOrder: state.localOrder.washPressOrder,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
    editOrder: state.editOrder,
    localOrder: state.localOrder,
  };
};

export default connect(mapStateToProps)(WashPress);
