import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Header from "../global/components/Header/Header";
import Footer from "../global/components/Footer/Footer";

// Views
import Home from "../views/Home/Home";
import Pricing from "../views/Pricing/Pricing";
import Privacy from "../views/Privacy/Privacy";
import Promise from "../views/Promise/Promise";
import Specials from "../views/Specials/Specials";
import Locations from "../views/Locations/Locations";
import BusinessServices from "../views/BusinessServices/BusinessServices";
import Support from "../views/Support/Support";
import Contact from "../views/Contact/Contact";
import Login from "../views/Login/Login";
import Signup from "../views/Signup/Signup";
import AppVerification from "../views/AppVerification/AppVerification";

// App Views
import AppDashboard from "../views/AppDashboard/AppDashboard";
import AppAccount from "../views/AppAccount/AppAccount";

// Admin Table Views
import AppOrders from "../views/AppOrders/AppOrders";
import AppUsers from "../views/AppUsers/AppUsers";
import AppCustomers from "../views/AppCustomers/AppCustomers";
import AppLocations from "../views/AppLocations/AppLocations";
import AppSpecials from "../views/AppSpecials/AppSpecials";
import AppSettings from "../views/AppSettings/AppSettings";
import AppSettingsFlorida from "../views/AppSettingsFlorida/AppSettingsFlorida";

// Forms
import AppOrder from "../views/AppOrder/AppOrder";
import ResetPassword from "../views/ResetPassword/ResetPassword";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";

// Detail Pages
import AppOrderDetail from "../views/AppOrderDetail/AppOrderDetail";

import Meta from "../meta.json";

// Global Styles
import "../global/styles/global.scss";

import FloridaPricing from "../views/Pricing/FloridaPricing";

function AppRouter(props) {
  const [containerFixed, setContainerFixed] = useState(false);

  const setContainer = (val) => {
    setContainerFixed(val);
  };

  if (Meta.clearCache) {
    if ("serviceWorker" in navigator) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      });
    }
  }

  return (
    <Router>
      <div className={containerFixed ? "app container-fixed" : "app"}>
        <div className="app-container">
          <Header setContainer={setContainer} />
          <div className="view-container">
            <Switch>
              {/* <Route path="/privacy"><Privacy /></Route> */}
              <Route path="/privacy-policy">
                <Privacy />
              </Route>
              <Route path="/pricing">
                <Pricing />
              </Route>
              <Route path="/florida-pricing">
                <FloridaPricing />
              </Route>
              <Route path="/specials">
                <Specials />
              </Route>
              <Route path="/promise">
                <Promise />
              </Route>
              <Route path="/locations">
                <Locations />
              </Route>
              <Route path="/business-services">
                <BusinessServices />
              </Route>
              <Route path="/support">
                <Support />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/verify/:token">
                <AppVerification />
              </Route>
              {/* <Route path="/order/guest"><AppOrder guest={true} /></Route> */}
              <Route path="/signup/:zipcode">
                <Signup />
              </Route>
              <Route path="/signup">
                <Signup />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/reset-password/:token">
                <ResetPassword />
              </Route>
              <Route path="/home">
                <Home />
              </Route>

              <Route path="/app/dashboard">
                <AppDashboard />
              </Route>
              <Route path="/app/account">
                <AppAccount />
              </Route>
              <Route path="/app/settings">
                <AppSettings />
              </Route>
              <Route path="/app/settings-florida">
                <AppSettingsFlorida />
              </Route>

              <Route path="/app/order/id/:orderId">
                <AppOrderDetail setContainerFixed={setContainer} />
              </Route>

              <Route path="/app/orders">
                <AppOrders />
              </Route>
              <Route path="/app/users">
                <AppUsers />
              </Route>
              <Route path="/app/customers">
                <AppCustomers />
              </Route>
              <Route path="/app/locations">
                <AppLocations />
              </Route>
              <Route path="/app/specials">
                <AppSpecials />
              </Route>
              <Route path="/app/order/edit">
                <AppOrder edit={true} />
              </Route>
              <Route path="/app/order">
                <AppOrder />
              </Route>
              <Route path="/app/login">
                <Login />
              </Route>
              <Route path="/app/signup">
                <Signup />
              </Route>

              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.users,
  };
};

export default connect(mapStateToProps)(AppRouter);
