import {
    SET_ORDER_TO_EDIT, UPDATE_EDIT_ORDER, RESET_EDIT_ORDER_STATE, UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE,
    UPDATE_EDIT_DRY_CLEAN_SURCHARGE, UPDATE_EDIT_WASH_PRESS_SURCHARGE, UNAUTHORIZE_USER
} from '../actions/types';

export default (state = {}, action) => {

    switch (action.type) {
        case UNAUTHORIZE_USER:
            return null;
        case SET_ORDER_TO_EDIT:
            return action.order;
        case UPDATE_EDIT_ORDER:
            return { ...state, [action.key]: action.data };
        case RESET_EDIT_ORDER_STATE:
            return null;
        case UPDATE_EDIT_MINIMUM_DELIVERY_SURCHARGE:
            return { ...state, minimumDeliverySurcharge: action.surcharge };
        case UPDATE_EDIT_DRY_CLEAN_SURCHARGE:
            return { ...state, dryCleanSurcharge: action.surcharge };
        case UPDATE_EDIT_WASH_PRESS_SURCHARGE:
            return { ...state, washPressSurcharge: action.surcharge };
        default:
            return state;
    }
}