import React from "react";
import { Provider } from "react-redux";
import AppRouter from "./routers/AppRouter";
import { store } from "./store/configureStore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import packageJson from "../package.json";

import config from "./config/keys";

global.appVersion = packageJson.version;

const stripePromise = loadStripe(config.stripeKey);

export default () => {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Elements>
  );
};
