import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import MaskedInput from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

// Form
import DatePicker from "react-datepicker";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

import {
  getSpecial,
  createSpecial,
  updateSpecial,
} from "../../../../actions/specials";

function NewSpecial(props) {
  let history = useHistory();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: "onChange",
  });

  const handleCancel = () => {
    props.handleCancelEditSpecial();
  };

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      createSpecial(formData, () => {
        props.handleCancelEditSpecial();
      })
    );
  };

  let percentDiscount = watch("percentDiscount");
  let promotionalPrice = watch("promotionalPrice");
  let discountAmount = watch("discountAmount");
  let servicesTailoring = watch("services.tailoring");
  let servicesDryClean = watch("services.dryClean");

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const selectedExpirationDate = watch("expirationDate");

  return (
    <div className="dashboard-container">
      <div className="dashboard-panel-container">
        <div id="app-special">
          <div className="action-links-container">
            <button onClick={() => handleCancel()}>Cancel</button>
          </div>

          <form className="special-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-input">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" ref={register} />
              </div>
              <div className="form-input">
                <label htmlFor="code">Code</label>
                <input type="text" name="code" ref={register} />
              </div>
            </div>

            <div className="form-row">
              <div className="select-field">
                <label htmlFor="firstTimeOnly">
                  First-Time Customers Only?
                </label>
                <Controller
                  as={
                    <Select
                      options={[
                        {
                          label: "Yes",
                          value: true,
                        },
                        {
                          label: "No",
                          value: false,
                        },
                      ]}
                      placeholder="Select One"
                    />
                  }
                  control={control}
                  name="firstTimeOnly"
                  rules={{
                    required: false,
                    validate: (value) => value !== "Select One",
                  }}
                />
              </div>
            </div>

            <div className="form-row discount-type">
              <p>Discount Type:</p>
            </div>

            <div className="form-row">
              {!promotionalPrice && !discountAmount && (
                <div className="form-input">
                  <label htmlFor="percentDiscount">Percent Discount (%)</label>
                  <p className="form-input-info">
                    Percent off each selected service
                  </p>
                  <input
                    name="percentDiscount"
                    pattern="^\d*(\.\d{0,2})?$"
                    ref={register}
                  />
                </div>
              )}

              {!percentDiscount && !discountAmount && (
                <div className="form-input">
                  <label htmlFor="promotionalPrice">
                    Promotional Price ($)
                  </label>
                  <p className="form-input-info">
                    Applied per item in selected services
                  </p>
                  <input
                    name="promotionalPrice"
                    pattern="^\d*(\.\d{0,2})?$"
                    ref={register}
                  />
                </div>
              )}

              {!percentDiscount && !promotionalPrice && (
                <div className="form-input">
                  <label htmlFor="discountAmount">Discount Amount ($)</label>
                  <p className="form-input-info">
                    Applied once per selected service
                  </p>
                  <input type="number" name="discountAmount" ref={register} />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="datepicker-field">
                <label htmlFor="expirationDate">Expiration Date</label>
                <Controller
                  as={
                    <DatePicker
                      selected={selectedExpirationDate}
                      name="expirationDate"
                      minDate={today}
                    />
                  }
                  control={control}
                  name="expirationDate"
                  placeholderText="Select a Date"
                />
              </div>
            </div>

            <div className="form-row new-applicable-services">
              <label htmlFor="services">Applicable Services</label>
              <div className="form-checkbox-group">
                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.washFold"
                    ref={register}
                  />
                  <div htmlFor="services.washFold">Wash & Fold</div>
                </div>

                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.organicWashFold"
                    ref={register}
                  />
                  <div htmlFor="services.organicWashFold">
                    Organic Wash & Fold
                  </div>
                </div>

                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.washPress"
                    ref={register}
                  />
                  <div htmlFor="services.washPress">Wash & Press</div>
                </div>

                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.dryClean"
                    ref={register}
                  />
                  <div htmlFor="services.dryClean">Dry Clean</div>
                </div>

                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.tailoring"
                    ref={register}
                  />
                  <div htmlFor="services.tailoring">Tailoring</div>
                </div>

                <div className="specials-service-item">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    name="services.comforter"
                    ref={register}
                  />
                  <div htmlFor="services.comforter">Comforter</div>
                </div>
              </div>
            </div>

            {servicesTailoring && (
              <div className="form-row new-applicable-tailoring-items">
                <label htmlFor="tailoringItems">
                  Applicable Tailoring Items
                </label>
                <div className="form-checkbox-group">
                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.dress"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.dress">Dress</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.pants"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.pants">Pants</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.jumpsuit"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.jumpsuit">Jumpsuit</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.shirt"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.shirt">Shirt</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.jacket"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.jacket">Jacket</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.coat"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.coat">Coat</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="tailoringItems.curtains"
                      ref={register}
                    />
                    <div htmlFor="tailoringItems.curtains">Curtains</div>
                  </div>
                </div>
              </div>
            )}

            {servicesDryClean && (
              <div className="form-row new-applicable-dryclean-items">
                <label htmlFor="items">Applicable Dry Clean Items</label>
                <div className="form-checkbox-group">
                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.dress"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.dress">Dress</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.pants"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.pants">Pants</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.jumpsuit"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.jumpsuit">Jumpsuit</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.skirt"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.skirt">Skirt</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.shirt"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.shirt">Shirt</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.outerjacket"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.outerjacket">Outer Jacket</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.sportjacket"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.sportjacket">Sport Jacket</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.blazer"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.blazer">Blazer</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.coat"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.coat">Coat</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.sweater"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.sweater">Sweater</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.blanket"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.blanket">Blanket</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.comforter"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.comforter">Comforter</div>
                  </div>

                  <div className="specials-item-item">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name="dryCleanItems.gown"
                      ref={register}
                    />
                    <div htmlFor="dryCleanItems.gown">Gown</div>
                  </div>
                </div>
              </div>
            )}

            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    specials: state.specials,
    specials: state.specials,
  };
};

export default connect(mapStateToProps)(NewSpecial);
