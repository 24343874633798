import React from "react";
import { connect } from 'react-redux';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

import { updateTimeWindows } from "../../../actions/settings";

function TimeWindowsForm(props) {
    const { handleSubmit, control } = useForm({
        mode: "onChange",
    });


    // Dispatch action 
    const onSubmit = (formData) => {
        props.dispatch(updateTimeWindows(formData, () => {
            props.close();
        }));
    }

    // Create default time Select options, 11-12pm to 9-10pm 
    const times = [
        { value: 7, label: "7-8am" },
        { value: 8, label: "8-9am" },
        { value: 9, label: "9-10am" },
        { value: 10, label: "10-11am" },
        { value: 11, label: "11am-12pm" },
        { value: 12, label: "12-1pm" }];

    for (let i = 13; i < 22; i++) {
        times.push({ value: i, label: `${i - 12}-${i - 11}pm` });
    }

    return (

        <form className="time-windows-section" onSubmit={handleSubmit(onSubmit)}>

            <div className="form-row">
                <div className="form-input">
                    <label htmlFor="firstPickUp">First Pick Up</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.firstPickUp && props.settings.firstPickUp.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.firstPickUp}
                                control={control}
                                name="firstPickUp"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>

                <div className="form-input">
                    <label htmlFor="lastPickUp">Last Pick Up</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.lastPickUp && props.settings.lastPickUp.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.lastPickUp}
                                control={control}
                                name="lastPickUp"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>
            </div>

            <div className="form-row">

                <div className="form-input">
                    <label htmlFor="firstDelivery">First Delivery</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.firstDelivery && props.settings.firstDelivery.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.firstDelivery}
                                control={control}
                                name="firstDelivery"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>

                <div className="form-input">
                    <label htmlFor="lastDelivery">Last Delivery</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.lastDelivery && props.settings.lastDelivery.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                control={control}
                                name="lastDelivery"
                                defaultValue={props.settings.lastDelivery}
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>

            </div>


            <div className="form-row">
                {/* <div className="form-input">
                    <label htmlFor="firstSameDayPickUp">First Pick Up for Same-Day</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.firstSameDayPickUp && props.settings.firstSameDayPickUp.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.firstSameDayPickUp}
                                control={control}
                                name="firstSameDayPickUp"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div> */}

                <div className="form-input">
                    <label htmlFor="lastSameDayPickUp">Last Pick Up for Same Day</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.lastSameDayPickUp && props.settings.lastSameDayPickUp.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.lastSameDayPickUp}
                                control={control}
                                name="lastSameDayPickUp"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>


                <div className="form-input">
                    <label htmlFor="firstSameDayDelivery">First Delivery for Same-Day</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.firstSameDayDelivery && props.settings.firstSameDayDelivery.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.firstSameDayDelivery}
                                control={control}
                                name="firstSameDayDelivery"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div>
            </div>





            {/* <div className="form-input">
                    <label htmlFor="lastSameDayDelivery">Last Delivery for Same-Day</label>

                    {!props.editMode &&
                        <p className="current-value">{props.settings.lastSameDayDelivery && props.settings.lastSameDayDelivery.label}</p>}

                    {props.editMode &&
                        <div className="select-field">
                            <Controller
                                as={<Select
                                    options={times}
                                    placeholder="Select One"
                                />
                                }
                                defaultValue={props.settings.lastSameDayDelivery}
                                control={control}
                                name="lastSameDayDelivery"
                                rules={{ required: false, validate: value => value !== "Select One" }}
                            />
                        </div>
                    }
                </div> */}



            {
                props.editMode &&
                <div className="action-button-container">
                    <input className="submit-button update" type="submit" value="Update" />
                </div>
            }

        </form >
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        locations: state.locations,
        users: state.users
    }
}


export default connect(mapStateToProps)(TimeWindowsForm);


