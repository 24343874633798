import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import MaskedInput from "react-input-mask";
import Select from "react-select";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-brands-svg-icons";

// Redux Actions
import {
  updateAccount,
  disconnectFacebook,
  disconnectGoogle,
  deleteAccount,
} from "../../actions/auth";
import { getSettingsFlorida } from "../../actions/settings_florida";
import { getZips } from "../../actions/zips";

// Components
import Sidebar from "../../global/components/Sidebar/Sidebar";
import DisableOrderingForm from "./components/DisableOrderingForm";
import TimeWindowsForm from "./components/TimeWindowsForm";
import MinDeliveryForm from "./components/MinDeliveryForm";
import MinDeliveryDaysForm from "./components/MinDeliveryDaysForm";
import ProductForm from "./components/ProductForm";
import NewProductForm from "./components/NewProductForm";
import TailoringForm from "./components/TailoringForm";
import NewTailoringForm from "./components/NewTailoringForm";
import NewWashPressForm from "./components/NewWashPressForm";
import WashPressForm from "./components/WashPressForm";
import DryCleanForm from "./components/DryCleanForm";
import NewDryCleanForm from "./components/NewDryCleanForm";
import ComforterForm from "./components/ComforterForm";
import WashFoldForm from "./components/WashFoldForm";
import OrganicWashFoldForm from "./components/OrganicWashFoldForm";
import ZipCodeForm from "./components/ZipCodeForm";
import NewZipCodeForm from "./components/NewZipCodeForm";

// Form
library.add(faChevronDown, faChevronUp);

function AppSettingsFlorida(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.auth && !props.auth.authenticated) {
      history.push("/login");
    }
  }, [props.auth]);

  useEffect(() => {
    if (props.auth.authenticated) {
      dispatch(getSettingsFlorida());
      dispatch(getZips());
    }
  }, []);

  const [editMode, setEditMode] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [serviceToDisconnect, setServiceToDisconnect] = useState();
  const [expandMinDelivery, setExpandMinDelivery] = useState(false);
  const [expandMinDeliveryDays, setExpandMinDeliveryDays] = useState(false);
  const [expandWashFoldSettings, setExpandWashFoldSettings] = useState(false);
  const [
    expandOrganicWashFoldSettings,
    setExpandOrganicWashFoldSettings,
  ] = useState(false);
  const [expandWashFoldProducts, setExpandWashFoldProducts] = useState(false);
  const [expandWashPressServices, setExpandWashPressServices] = useState(false);
  const [expandDryCleanServices, setExpandDryCleanServices] = useState(false);
  const [expandTailoringServices, setExpandTailoringServices] = useState(false);
  const [expandComforterServices, setExpandComforterServices] = useState(false);
  const [expandZipCodes, setExpandZipCodes] = useState(false);
  const [expandDisableOrdering, setExpandDisableOrdering] = useState(false);
  const [expandTimeWindows, setExpandTimeWindows] = useState(false);

  const [cacheCleared, setCacheCleared] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    setCacheCleared(true);
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-panel-container">
        <div className="action-links-container">
          {!editMode && <button onClick={() => setEditMode(true)}>Edit</button>}
          {editMode && (
            <button onClick={() => setEditMode(false)}>Cancel</button>
          )}
        </div>

        {props.settingsFlorida.settings && (
          <div id="app-settings" className="settings-form">
            {!cacheCleared && (
              <button className="cache-button" onClick={() => clearCacheData()}>
                Clear Cache
              </button>
            )}

            {cacheCleared && <div>Cached has been cleared.</div>}

            <div className="ordering-disabled-banner">
              {props.settingsFlorida.settings.disableOrdering
                ? "Ordering is currently disabled. To enable, click Edit and update the Disable Ordering settings below."
                : ""}
            </div>

            <div
              onClick={() => setExpandDisableOrdering(!expandDisableOrdering)}
              className="form-group-label"
            >
              Disable Ordering
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandDisableOrdering ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandDisableOrdering && (
              <DisableOrderingForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() => setExpandTimeWindows(!expandTimeWindows)}
              className="form-group-label"
            >
              Time Windows
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandTimeWindows ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandTimeWindows && (
              <TimeWindowsForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() => setExpandMinDelivery(!expandMinDelivery)}
              className="form-group-label"
            >
              Minimum Delivery Amounts ($)
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandMinDelivery ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandMinDelivery && (
              <MinDeliveryForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() => setExpandMinDeliveryDays(!expandMinDeliveryDays)}
              className="form-group-label"
            >
              Minimum Delivery Days{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandMinDeliveryDays ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandMinDeliveryDays && (
              <MinDeliveryDaysForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() => setExpandWashFoldSettings(!expandWashFoldSettings)}
              className="form-group-label"
            >
              Wash & Fold Settings{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandWashFoldSettings ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandWashFoldSettings && (
              <WashFoldForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() =>
                setExpandOrganicWashFoldSettings(!expandOrganicWashFoldSettings)
              }
              className="form-group-label"
            >
              Organic Wash & Fold Settings{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={
                  expandOrganicWashFoldSettings ? "chevron-down" : "chevron-up"
                }
              />
            </div>

            {expandOrganicWashFoldSettings && (
              <OrganicWashFoldForm
                settings={props.settingsFlorida.settings}
                editMode={editMode}
                close={() => setEditMode(false)}
              />
            )}

            <div
              onClick={() => setExpandWashFoldProducts(!expandWashFoldProducts)}
              className="form-group-label"
            >
              Wash & Fold Products{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandWashFoldProducts ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandWashFoldProducts && (
              <div className="wash-fold-products-section">
                {!editMode &&
                  props.settingsFlorida.products &&
                  props.settingsFlorida.products.map((product) => {
                    return (
                      <div className="product">
                        <div className="product-name">{product.item}</div>
                        {product.price && (
                          <div className="product-price">
                            {" "}
                            {product.price.toFixed(2)}
                          </div>
                        )}
                      </div>
                    );
                  })}

                {editMode &&
                  props.settingsFlorida.products &&
                  props.settingsFlorida.products.map((product) => {
                    return (
                      <ProductForm
                        key={product._id}
                        product={product}
                        close={() => setEditMode(false)}
                      />
                    );
                  })}

                {editMode && (
                  <NewProductForm close={() => setEditMode(false)} />
                )}
              </div>
            )}

            {/* { Wash & Press } */}
            <div
              onClick={() =>
                setExpandWashPressServices(!expandWashPressServices)
              }
              className="form-group-label"
            >
              Wash & Press Services{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandWashPressServices ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandWashPressServices && (
              <div className="wash-press-services">
                {!editMode &&
                  props.settingsFlorida.washPressServices &&
                  props.settingsFlorida.washPressServices.map((product) => {
                    return (
                      <div className="product">
                        <div className="product-name">{product.item}</div>
                        <div className="product-price">
                          {product.price.toFixed(2)}
                        </div>
                      </div>
                    );
                  })}

                {editMode &&
                  props.settingsFlorida.washPressServices &&
                  props.settingsFlorida.washPressServices.map((product) => {
                    return (
                      <WashPressForm
                        product={product}
                        close={() => setEditMode(false)}
                      />
                    );
                  })}

                {editMode && (
                  <NewWashPressForm close={() => setEditMode(false)} />
                )}
              </div>
            )}

            {/* { Dry Clean} */}
            <div
              onClick={() => setExpandDryCleanServices(!expandDryCleanServices)}
              className="form-group-label"
            >
              Dry Clean Services{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandDryCleanServices ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandDryCleanServices && (
              <div className="dry-clean-services">
                {!editMode &&
                  props.settingsFlorida.dryCleanServices &&
                  props.settingsFlorida.dryCleanServices.map((product) => {
                    return (
                      <div className="dry-clean product">
                        <div className="product-name">{product.item}</div>
                        <div className="product-pricing">
                          <p>
                            <label>Price:</label>
                            <span>{product.options.base}</span>
                          </p>
                          <p>
                            <label>Press Only:</label>
                            <span>
                              {product.options.press
                                ? product.options.press.pressOnly
                                : ""}
                            </span>
                          </p>
                          <p>
                            <label>Crease:</label>
                            <span>{product.options.crease ? "Yes" : "No"}</span>
                          </p>
                          <p>
                            <label>Starch:</label>
                            <span>{product.options.starch ? "Yes" : "No"}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}

                {editMode &&
                  props.settingsFlorida.dryCleanServices &&
                  props.settingsFlorida.dryCleanServices.map((product) => {
                    return (
                      <DryCleanForm
                        product={product}
                        close={() => setEditMode(false)}
                      />
                    );
                  })}

                {editMode && (
                  <div>
                    <div className="new-settings-item">New Dry Clean Item:</div>
                    <NewDryCleanForm close={() => setEditMode(false)} />
                  </div>
                )}
              </div>
            )}

            {/* { Tailoring } */}
            <div
              onClick={() =>
                setExpandTailoringServices(!expandTailoringServices)
              }
              className="form-group-label"
            >
              Tailoring Services{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandTailoringServices ? "chevron-down" : "chevron-up"}
              />
            </div>
            {expandTailoringServices && (
              <div className="tailoring-services">
                {!editMode &&
                  props.settingsFlorida.tailoringServices &&
                  props.settingsFlorida.tailoringServices.map((product) => {
                    return (
                      <div className="product">
                        <div className="product-name">{product.item}</div>
                        <div className="product-pricing">
                          <p>
                            <label>Hem</label>
                            <span>{product.options["hem"]}</span>
                          </p>
                          <p>
                            <label>Taper</label>
                            <span>{product.options["taper"]}</span>
                          </p>
                          <p>
                            <label>Zipper</label>
                            <span>{product.options["zipper"]}</span>
                          </p>
                          <p>
                            <label>Sleeves</label>
                            <span>{product.options["sleeves"]}</span>
                          </p>
                          <p>
                            <label>Patch</label>
                            <span>{product.options["patch"]}</span>
                          </p>
                          <p>
                            <label>Fix</label>
                            <span>{product.options["fix"]}</span>
                          </p>
                          <p>
                            <label>Waist</label>
                            <span>{product.options["waist"]}</span>
                          </p>
                          <p>
                            <label>Lining</label>
                            <span>{product.options["lining"]}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}

                {editMode &&
                  props.settingsFlorida.tailoringServices &&
                  props.settingsFlorida.tailoringServices.map((product) => {
                    return (
                      <TailoringForm
                        product={product}
                        close={() => setEditMode(false)}
                      />
                    );
                  })}

                {editMode && (
                  <div>
                    <div className="new-settings-item">New Tailoring Item:</div>
                    <NewTailoringForm close={() => setEditMode(false)} />
                  </div>
                )}
              </div>
            )}

            {/* { Comforter } */}
            <div
              onClick={() =>
                setExpandComforterServices(!expandComforterServices)
              }
              className="form-group-label"
            >
              Comforter Services{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandComforterServices ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandComforterServices && (
              <div className="comforter-services">
                {!editMode &&
                  props.settingsFlorida.comforterServices &&
                  props.settingsFlorida.comforterServices.map((product) => {
                    return (
                      <div className="product">
                        <div className="product">
                          <div className="product-name">{product.item}</div>
                          <div className="product-pricing">
                            <p>
                              <label>Not Down</label>
                              <span>{product.options.notDown}</span>
                            </p>
                            <p>
                              <label>Down</label>
                              <span>{product.options.down}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {editMode &&
                  props.settingsFlorida.comforterServices &&
                  props.settingsFlorida.comforterServices.map((product) => {
                    return (
                      <ComforterForm
                        product={product}
                        close={() => setEditMode(false)}
                      />
                    );
                  })}
              </div>
            )}

            {/* { Zip Codes } */}
            <div
              onClick={() => setExpandZipCodes(!expandZipCodes)}
              className="form-group-label"
            >
              Eligible Zip Codes{" "}
              <FontAwesomeIcon
                className="expand-icon"
                icon={expandZipCodes ? "chevron-down" : "chevron-up"}
              />
            </div>

            {expandZipCodes && (
              <div className="zip-codes">
                {!editMode &&
                  props.zips.zips &&
                  props.zips.zips.map((zip) => {
                    if (zip.florida) {
                      return <div className="zip">{zip.zip}</div>;
                    } else {
                      return null;
                    }
                  })}

                {editMode &&
                  props.zips &&
                  props.zips.zips.map((zip) => {
                    if (zip.florida) {
                      return (
                        <ZipCodeForm
                          zip={zip}
                          close={() => setEditMode(false)}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}

                {editMode && (
                  <NewZipCodeForm close={() => setEditMode(false)} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
    zips: state.zips,
  };
};

export default connect(mapStateToProps)(AppSettingsFlorida);
