import React, { useState } from "react";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import MaskedInput from "react-input-mask";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-brands-svg-icons";

// Redux Actions
import {
  updateAccount,
  disconnectFacebook,
  disconnectGoogle,
  deleteAccount,
} from "../../actions/auth";

// Components
import Sidebar from "../../global/components/Sidebar/Sidebar";

library.add(fab);

function AppAccount(props) {
  const history = useHistory();

  if (!props.auth.user) {
    history.push("/login");
  }

  const [editMode, setEditMode] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [serviceToDisconnect, setServiceToDisconnect] = useState();

  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    let userId = props.auth.user._id;

    if (!userId) {
      userId = props.auth.user.userId;
    }

    props.dispatch(
      updateAccount(userId, formData, () => {
        setEditMode(false);
      })
    );
  };

  const submitPassword = (formData) => {
    let userId = props.auth.user._id;

    if (!userId) {
      userId = props.auth.user.userId;
    }

    props.dispatch(
      updateAccount(userId, formData, () => {
        setShowPasswordFields(false);

        if (serviceToDisconnect == "google") {
          disconnectGoogleAccount();
        } else if (serviceToDisconnect == "facebook") {
          disconnectFacebookAccount();
        }
      })
    );
  };

  const confirmDeleteAccount = () => {
    let userId = props.auth.user._id;

    if (!userId) {
      userId = props.auth.user.userId;
    }

    props.dispatch(
      deleteAccount(userId, () => {
        history.push("/");
      })
    );
  };

  const disconnectSocial = (service) => {
    setShowPasswordFields(true);
    setServiceToDisconnect(service);
  };

  const disconnectGoogleAccount = () => {
    props.dispatch(disconnectGoogle(props.auth.user._id));
  };

  const disconnectFacebookAccount = () => {
    props.dispatch(disconnectFacebook(props.auth.user._id));
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-panel-container">
        <div className="action-links-container">
          {!editMode && <button onClick={() => setEditMode(true)}>Edit</button>}
          {editMode && (
            <button onClick={() => setEditMode(false)}>Cancel</button>
          )}
        </div>

        <div id="app-account">
          {props.user && (
            <>
              {editMode && props.user.role == "Customer" && (
                <p className="note">
                  Note: Existing orders will not be affected by changes made
                  here. If you have an order in progress, please contact us at{" "}
                  <a href="tel:+12129289274">(212) 928-9274.</a>
                </p>
              )}

              <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-input">
                    <label htmlFor="firstName">First Name</label>
                    {!editMode && (
                      <p className="current-value">{props.user.firstName}</p>
                    )}

                    {editMode && (
                      <input
                        type="text"
                        name="firstName"
                        ref={register}
                        defaultValue={props.user.firstName}
                      />
                    )}
                  </div>

                  <div className="form-input">
                    <label htmlFor="lastName">Last Name</label>
                    {!editMode && (
                      <p className="current-value">{props.user.lastName}</p>
                    )}
                    {editMode && (
                      <input
                        type="text"
                        name="lastName"
                        ref={register}
                        defaultValue={props.user.lastName}
                      />
                    )}
                  </div>
                </div>

                {props.user.role == "Customer" && (
                  <>
                    <div className="form-row">
                      <div className="form-input">
                        <label htmlFor="address">Address</label>
                        {!editMode && (
                          <p className="current-value">{props.user.address}</p>
                        )}
                        {editMode && (
                          <input
                            type="text"
                            name="address"
                            ref={register}
                            defaultValue={props.user.address}
                          />
                        )}
                      </div>

                      <div className="form-input">
                        <label htmlFor="addressLine2">Address Line 2</label>
                        {!editMode && (
                          <p className="current-value">
                            {props.user.addressLine2}
                          </p>
                        )}
                        {editMode && (
                          <input
                            type="text"
                            name="addressLine2"
                            ref={register}
                            defaultValue={props.user.addressLine2}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <label htmlFor="city">City</label>
                        {!editMode && (
                          <p className="current-value">{props.user.city}</p>
                        )}
                        {editMode && (
                          <input
                            type="text"
                            name="city"
                            ref={register}
                            defaultValue={props.user.city}
                          />
                        )}
                      </div>

                      <div className="form-input">
                        <label htmlFor="state">State</label>
                        {!editMode && (
                          <p className="current-value">{props.user.state}</p>
                        )}
                        {editMode && (
                          <input
                            type="text"
                            name="state"
                            ref={register}
                            defaultValue={props.user.state || "NY"}
                          />
                        )}
                      </div>

                      <div className="form-input">
                        <label htmlFor="zip">Zip</label>
                        {!editMode && (
                          <p className="current-value">{props.user.zip}</p>
                        )}
                        {editMode && (
                          <input
                            type="text"
                            name="zip"
                            ref={register}
                            defaultValue={props.user.zip}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="form-row">
                  <div className="form-input">
                    <label htmlFor="email">Email</label>
                    {!editMode && (
                      <p className="current-value">{props.user.email}</p>
                    )}
                    {editMode && (
                      <input
                        type="text"
                        name="email"
                        defaultValue={props.user.email}
                        ref={register({
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                    )}
                    <ErrorMessage errors={errors} name="email">
                      {({ message }) => (
                        <p className="form-error-message">{message}</p>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="form-input">
                    <label htmlFor="phone">Phone</label>
                    {!editMode && (
                      <p className="current-value">{props.user.phone}</p>
                    )}
                    {editMode && (
                      <Controller
                        as={
                          <MaskedInput
                            mask="(999) 999-9999"
                            maskplaceholder={"_"}
                            alwaysShowMask
                          ></MaskedInput>
                        }
                        name="phone"
                        defaultValue={props.user.phone}
                        control={control}
                      />
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-input checkbox-input">
                    {!editMode && (
                      <p className="current-value">
                        {props.user.sms
                          ? "Text Notifications: Enabled"
                          : "Text Notifications: Disabled"}
                      </p>
                    )}
                    {editMode && (
                      <>
                        <input
                          defaultChecked={props.user.sms}
                          type="checkbox"
                          name="sms"
                          ref={register}
                        />
                        <label htmlFor="sms">
                          Send me text notifications with updates about my
                          orders. I understand that message and data rates may
                          apply.
                        </label>
                      </>
                    )}
                  </div>
                </div>

                {editMode && (
                  <div className="action-button-container">
                    <input
                      className="submit-button bg-green"
                      type="submit"
                      value="Update Account Details"
                    />
                  </div>
                )}
              </form>

              <div className="social-login-buttons">
                {/* {!props.user.facebookId &&
                                    <a id="facebook" className="social-login" href={`/api/facebook`}>
                                        <button>
                                            <span><FontAwesomeIcon icon={['fab', 'facebook']} /></span><span>Connect with Facebook</span>
                                        </button>
                                    </a>
                                }

                                {props.user.facebookId &&
                                    <div onClick={() => disconnectSocial("facebook")} className="disconnect-social">
                                        Disconnect Facebook account
                                    </div>
                                } */}

                {!props.user.googleId && (
                  <a id="google" className="social-login" href={`/api/google`}>
                    <button>
                      <span>
                        <FontAwesomeIcon icon={["fab", "google"]} />
                      </span>
                      <span>Continue with Google</span>
                    </button>
                  </a>
                )}

                {props.user.googleId && (
                  <div
                    onClick={() => disconnectSocial("google")}
                    className="disconnect-social"
                  >
                    Disconnect Google account
                  </div>
                )}

                {showPasswordFields && (
                  <form
                    className="create-password-form"
                    onSubmit={handleSubmit(submitPassword)}
                  >
                    <p>
                      Please create a password before disconnecting your social
                      account.
                    </p>

                    <div className="form-line">
                      <div className="form-input">
                        <input
                          placeholder="New password"
                          type="password"
                          name="password"
                          ref={register}
                        />
                      </div>
                    </div>

                    <div className="form-line">
                      <div className="form-input">
                        <input
                          placeholder="Confirm new password"
                          type="password"
                          name="password_confirm"
                          ref={register({
                            validate: (value) => {
                              return value === watch("password");
                            },
                          })}
                        />
                        <ErrorMessage errors={errors} name="password_confirm">
                          {() => (
                            <p className="login-form-error-message">
                              Passwords must match.
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="action-button-container">
                      <input
                        className="submit-button bg-green"
                        type="submit"
                        value="Create Password"
                      />
                    </div>
                  </form>
                )}

                {!showDeleteMessage && (
                  <div
                    onClick={() => setShowDeleteMessage(true)}
                    className="disconnect-social"
                  >
                    Delete Account
                  </div>
                )}

                {showDeleteMessage && (
                  <>
                    <div className="delete-message">
                      Are you sure you want to delete your account? This action
                      cannot be undone!
                    </div>
                    <button
                      onClick={() => confirmDeleteAccount()}
                      className="delete-account-button"
                    >
                      Yes, permanently delete my account.
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AppAccount);
