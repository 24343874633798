import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import "./Header.scss";

import { unauthorizeUser } from "../../../actions/auth";

function Header(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen) {
      props.setContainer(true);
    }
  }, [props, mobileMenuOpen]);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    props.setContainer(false);
  };

  const handleSignOut = () => {
    props.dispatch(unauthorizeUser());
  };

  return (
    <div id="header">
      {mobileMenuOpen && (
        <div id="mobile-menu">
          <NavLink to="/">
            <div className="mobile-menu-logo">
              <img
                src="/images/green-earth-organic-logo.png"
                alt="Green Earth Logo"
              />
            </div>
          </NavLink>
          <div className="x-icon" onClick={closeMobileMenu}>
            <img src="/images/x-icon.png" alt="Green Earth Logo" />
          </div>
          <div className="mobile-menu-nav">
            <NavLink onClick={closeMobileMenu} to="/login">
              <p>{props.auth.user ? "Your Account" : "Login"}</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/pricing">
              <p>Services & Prices NYC</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/florida-pricing">
              <p>Services & Prices Florida</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/specials">
              <p>Specials</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/locations">
              <p>Locations & Self Service</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/promise">
              <p>Our Promise</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/business-services">
              <p>Wholesale & Business Services</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/contact">
              <p>Contact Us</p>
            </NavLink>
            <NavLink onClick={closeMobileMenu} to="/support">
              <p>Support</p>
            </NavLink>
          </div>
        </div>
      )}
      <div className="top-bar">
        <div className="left">
          <span>The most trusted chain in Manhattan, since 1992</span>
          <a href="tel:2129289274">(212) 928-WASH</a>
        </div>
        <div className="top-bar-nav">
          <NavLink to="/contact">
            <p>Contact Us</p>
          </NavLink>
          <NavLink to="/support">
            <p>Support</p>
          </NavLink>
          {!props.user && (
            <NavLink to="/login">
              <p>Login</p>
            </NavLink>
          )}
          {props.user && (
            <a href="/api/logout" onClick={handleSignOut}>
              <p>Sign Out</p>
            </a>
          )}
        </div>
      </div>
      <NavLink to="/">
        <div className="header-logo">
          <img src="/images/green-earth-organic-logo.png" alt="logo" />
        </div>
      </NavLink>

      <div className="header-nav">
        <NavLink onClick={closeMobileMenu} to="/pricing">
          <p>Services & Prices NYC</p>
        </NavLink>
        <NavLink onClick={closeMobileMenu} to="/florida-pricing">
          <p>Services & Prices Florida</p>
        </NavLink>
        <NavLink to="/specials">
          <p>Specials</p>
        </NavLink>
        <NavLink to="/locations">
          <p>Locations & Self Service</p>
        </NavLink>
        <NavLink to="/promise">
          <p>Our Promise</p>
        </NavLink>
        <NavLink to="/business-services">
          <p>Wholesale & Business Services</p>
        </NavLink>

        <NavLink to="/signup" id="login-link">
          <p>
            {props.auth.user && props.auth.user.role == "Customer"
              ? "Your Account"
              : "Schedule a Pickup"}
          </p>
        </NavLink>
      </div>

      <div className="hamburger" onClick={() => setMobileMenuOpen(true)}>
        <img src="/images/hamburger-icon.png" alt="menu icon" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Header);
