import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { useHistory, Link } from "react-router-dom";

// Redux Actions 
import { verifyEmail } from '../../actions/auth';

function AppVerification(props) {

    let history = useHistory();
    let { token } = useParams();

    // Loading animation
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);

        let formData = {};

        if (token) {
            formData.token = token;

            props.dispatch(verifyEmail(formData, () => history.push('/login')));
        }

        // Verify email address 
        // Then log out and log back in

    }, [token]);



    return (
        <div id="verification" className={`${loading ? "view-container loading" : "view-container"}`}>
            {props.auth.error && <h3>{props.auth.error}<br />If you need assistance, please contact us at <a href="mailto:info@msbubblegreen.com">info@msbubblegreen.com</a></h3>}

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(AppVerification);
