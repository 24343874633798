function calculateWashPress({ items, settings }) {
  let price = 0;

  items.forEach((item) => {
    let quantity = item.quantity ? item.quantity.value : 1;
    let foundService = settings.washPressServices.find((service) => {
      return service.item === item.label;
    });
    price += foundService.price * quantity;
  });

  return { price };
}

module.exports = calculateWashPress;
