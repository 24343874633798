import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faShoppingCart,
  faUserFriends,
  faStore,
  faTag,
  faCog,
  faSignOutAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { unauthorizeUser } from "../../../actions/auth";
import { resetLocalOrderState } from "../../../actions/localOrder";
import { resetEditOrderState } from "../../../actions/editOrder";

library.add([
  faShoppingCart,
  faUserFriends,
  faStore,
  faTag,
  faCog,
  faSignOutAlt,
  faUserCircle,
]);

function Sidebar(props) {
  const handleSignOut = () => {
    props.dispatch(unauthorizeUser());
    props.dispatch(resetLocalOrderState());
    props.dispatch(resetEditOrderState());
  };

  if (props.auth.user) {
    return (
      <div id="sidebar">
        <div id="sidebar-menu">
          <div className="welcome-message">
            {props.auth.user.firstName} {props.auth.user.lastName}
          </div>

          <NavLink to="/app/orders">
            <div className="icon">
              <FontAwesomeIcon icon="shopping-cart" />
            </div>
            <div className="label">
              {props.auth.user.role !== "Customer" ? "Orders" : "Order History"}
            </div>
          </NavLink>

          {// All Admins can add and view Customers for all locations
          (props.auth.user.role === "Admin" ||
            props.auth.user.role === "SuperAdmin") && (
            <NavLink to="/app/customers">
              <div className="icon">
                <FontAwesomeIcon icon="user-friends" />
              </div>
              <div className="label">Customers</div>
            </NavLink>
          )}

          {// Only SuperAdmins can manage Users, Locations, and Specials
          props.auth.user.role === "SuperAdmin" && (
            <>
              <NavLink to="/app/users">
                <div className="icon">
                  <FontAwesomeIcon icon="user-circle" />
                </div>
                <div className="label">Users</div>
              </NavLink>

              <NavLink to="/app/locations">
                <div className="icon">
                  <FontAwesomeIcon icon="store" />
                </div>
                <div className="label">Locations</div>
              </NavLink>

              <NavLink to="/app/specials">
                <div className="icon">
                  <FontAwesomeIcon icon="tag" />
                </div>
                <div className="label">Specials</div>
              </NavLink>

              <NavLink to="/app/settings">
                <div className="icon">
                  <FontAwesomeIcon icon="cog" />
                </div>
                <div className="label">Settings</div>
              </NavLink>
              <NavLink to="/app/settings-florida">
                <div className="icon">
                  <FontAwesomeIcon icon="cog" />
                </div>
                <div className="label">Settings FL</div>
              </NavLink>
            </>
          )}

          <NavLink to="/app/account">
            <div className="icon">
              <FontAwesomeIcon icon="cog" />
            </div>
            <div className="label">Account</div>
          </NavLink>

          <a href="/api/logout" onClick={handleSignOut}>
            <div className="icon">
              <FontAwesomeIcon icon="sign-out-alt" />
            </div>
            <div className="label">Sign Out</div>
          </a>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.users,
  };
};

export default connect(mapStateToProps)(Sidebar);
