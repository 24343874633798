import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateLocalOrder } from "../../../../actions/localOrder";

import { updateEditOrder } from "../../../../actions/editOrder";

import checkPromo from "../../../../utils/checkPromo";

import WashFold from "./WashFold";
import OrganicWashFold from "./OrganicWashFold";
import WashPress from "./WashPress";
import Comforter from "./Comforter";
import DryClean from "./DryClean";
import Tailoring from "./Tailoring";

import calculatePricing from "../../../../utils/calculatePricing";

function OrderSummary(props) {
  const location = useLocation();
  const [order, setOrder] = useState();

  const [pricing, setPricing] = useState();

  const [promoCode, setPromoCode] = useState(null);

  const [gratuity, setGratuity] = useState();
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const [checkoutView, setCheckoutView] = useState(false);

  const [deliveryDateString, setDeliveryDateString] = useState();
  const [deliveryDateString2, setDeliveryDateString2] = useState();
  const [pickUpDateString, setPickUpDateString] = useState();

  const [specialError, setSpecialError] = useState(null);
  const [isFlorida, setIsFlorida] = useState(false);

  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);
    } else {
      setOrder(props.localOrder);
    }
  }, [props.editOrder, props.localOrder]);

  useEffect(() => {
    if (
      props.editOrder?.address?.state.toLowerCase() == "fl" ||
      props.localOrder?.address?.state?.toLowerCase() == "fl"
    ) {
      setIsFlorida(true);
    } else {
      setIsFlorida(false);
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  useEffect(() => {
    if (location.pathname === "/app/order/checkout") {
      setCheckoutView(true);
    } else {
      setCheckoutView(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (order) {
      let result = calculatePricing({
        order,
        settings: isFlorida ? props.settingsFlorida : props.settings,
        gratuity: parseFloat(order.gratuity || gratuity || 0),
        discount: parseFloat(order.promo || totalDiscountAmount || 0),
      });

      setPricing(result);
    }
  }, [order]);

  // Handle Promo
  useEffect(() => {
    if (order && order.promo) {
      let { isEligible, discount } = checkPromo(order.promo, order);

      if (!isEligible) {
        setTotalDiscountAmount(0);
        setSpecialError("Your order is not eligible for this promotion.");

        if (props.editOrder && props.editOrder.orderId) {
          props.dispatch(
            updateEditOrder(null, "promo", () => {
              return;
            })
          );
        } else {
          props.dispatch(
            updateLocalOrder(null, "promo", () => {
              return;
            })
          );
        }
      } else {
        setSpecialError(null);
        setTotalDiscountAmount(Math.abs(parseFloat(discount)));
      }
    }
  }, [order]);

  useEffect(() => {
    if (totalDiscountAmount > 0) {
      if (props.editOrder && props.editOrder.orderId) {
        props.dispatch(
          updateEditOrder(totalDiscountAmount, "totalDiscountAmount", () => {
            console.log(totalDiscountAmount);
          })
        );
      } else {
        props.dispatch(
          updateLocalOrder(totalDiscountAmount, "totalDiscountAmount", () => {
            console.log(totalDiscountAmount);
          })
        );
      }
    }
  }, [totalDiscountAmount]);

  const removeService = (service) => {
    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(null, service, () => {
          return;
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(null, service, () => {
          return;
        })
      );
    }
  };

  // Gratuity ------------------------------------------------------------>
  const onGratuityChange = (e) => {
    if (isNaN(e.target.value)) {
      return;
    } else {
      setGratuity(e.target.value);
    }
  };

  const addGratuity = (e) => {
    e.preventDefault();

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(gratuity, "gratuity", () => {
          return;
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(gratuity, "gratuity", () => {
          return;
        })
      );
    }
  };

  // Promo Codes --------------------------------------------------------->
  const onPromoCodeChange = (e) => {
    setPromoCode(e.target.value.toLowerCase());
    if (specialError) {
      setSpecialError(null);
    }
  };

  const checkPromoCode = (e) => {
    if (e) {
      e.preventDefault();
    }

    let foundSpecial;

    if (props.specials) {
      foundSpecial = props.specials.filter((special) => {
        return special.code.toLowerCase() === promoCode;
      });

      if (foundSpecial.length == 0) {
        setSpecialError("Please enter a valid promo code.");

        if (props.editOrder && props.editOrder.orderId) {
          props.dispatch(
            updateEditOrder(0, "totalDiscountAmount", () => {
              return;
            })
          );
        } else {
          props.dispatch(
            updateLocalOrder(0, "totalDiscountAmount", () => {
              return;
            })
          );
        }

        return;
      }

      if (foundSpecial.length > 0) {
        foundSpecial = foundSpecial[0];

        let today = new Date();
        let expirationDate = new Date(foundSpecial.expirationDate);

        // Check if promotion has expired
        if (today > expirationDate) {
          setSpecialError("This promotion has expired.");

          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          }

          return;
        }

        // Check if user is ineligible for first-time promo
        if (foundSpecial.firstTimeOnly && !props.auth.user.newUser) {
          setSpecialError("This promotion is for first-time customers only.");

          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          }

          return;
        }

        let { isEligible, discount } = checkPromo(foundSpecial, order);

        if (!isEligible) {
          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(0, "totalDiscountAmount", () => {
                return;
              })
            );
          }

          setSpecialError("Your order is not eligible for this promotion.");

          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(null, "promo", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(null, "promo", () => {
                return;
              })
            );
          }
        } else {
          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(foundSpecial, "promo", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(foundSpecial, "promo", () => {
                return;
              })
            );
          }

          setSpecialError(null);

          if (props.editOrder && props.editOrder.orderId) {
            props.dispatch(
              updateEditOrder(discount, "totalDiscountAmount", () => {
                return;
              })
            );
          } else {
            props.dispatch(
              updateLocalOrder(discount, "totalDiscountAmount", () => {
                return;
              })
            );
          }
        }
      }
    } else {
      setSpecialError("Promotion not available for this order.");
      setPromoCode("");

      if (props.editOrder && props.editOrder.orderId) {
        props.dispatch(
          updateEditOrder(0, "totalDiscountAmount", () => {
            return;
          })
        );
      } else {
        props.dispatch(
          updateLocalOrder(0, "totalDiscountAmount", () => {
            return;
          })
        );
      }

      return;
    }
  };

  if (order) {
    return (
      <div id="order-summary" className={checkoutView ? "full" : ""}>
        <h4>Order Summary</h4>

        {checkoutView && order && (
          <div className="order-details">
            {order.address && (
              <div>
                <p>
                  <strong>Address:</strong>
                </p>
                <p>{order.address.address}</p>
                <p>{order.address.address2}</p>
                <p>
                  {order.address.city}, {order.address.state}{" "}
                  {order.address.zip}
                </p>
              </div>
            )}

            <div>
              {order.schedule && (
                <>
                  <p>
                    <strong>Pick Up:</strong> {pickUpDateString} (
                    {order.schedule.pickUpTime?.label})
                  </p>

                  {order.schedule.deliveryDate2 && (
                    <>
                      <p>
                        <strong>Delivery:</strong> {deliveryDateString2} (
                        {order.schedule.deliveryTime2?.label})
                      </p>
                    </>
                  )}

                  <p>
                    <strong>Delivery:</strong> {deliveryDateString} (
                    {order.schedule.deliveryTime?.label})
                  </p>
                </>
              )}
            </div>
          </div>
        )}

        <ul>
          {order.dryCleanOrder && (
            <DryClean
              order={order.dryCleanOrder}
              checkoutView={checkoutView}
              remove={() => removeService("dryCleanOrder")}
            />
          )}

          {order.washFoldOrder && (
            <WashFold
              order={order.washFoldOrder}
              checkoutView={checkoutView}
              remove={() => removeService("washFoldOrder")}
            />
          )}

          {order.organicWashFoldOrder && (
            <OrganicWashFold
              order={order.organicWashFoldOrder}
              checkoutView={checkoutView}
              remove={() => removeService("organicWashFoldOrder")}
            />
          )}

          {order.tailoringOrder && order.tailoringOrder.items && (
            <Tailoring
              order={order.tailoringOrder}
              checkoutView={checkoutView}
              remove={() => removeService("tailoringOrder")}
            />
          )}

          {order.washPressOrder && order.washPressOrder.items && (
            <WashPress
              order={order.washPressOrder}
              checkoutView={checkoutView}
              remove={() => removeService("washPressOrder")}
            />
          )}

          {order.comforterOrder && order.comforterOrder.items && (
            <Comforter
              order={order.comforterOrder}
              checkoutView={checkoutView}
              remove={() => removeService("comforterOrder")}
            />
          )}
        </ul>

        {pricing?.minimumDelivery > 0 && (
          <div className="order-surcharge">
            <span style={{ paddingRight: "5px" }}>
              Surcharge to meet delivery minimum (excluding products):
            </span>
            <span>${pricing.minimumDelivery.toFixed(2)}</span>
          </div>
        )}

        {pricing?.dryClean > 0 && (
          <div className="order-surcharge">
            <span>Same-Day Dry Clean Surcharge:</span>
            <span>${pricing?.dryClean.toFixed(2)}</span>
          </div>
        )}

        {order.promo && (
          <div className="order-surcharge">
            <span>Promo Discount:</span>
            <span>-${pricing?.discount.toFixed(2)}</span>
          </div>
        )}

        {order.gratuity > 0 && (
          <div className="order-surcharge">
            <span>Tip for Driver:</span>
            <span>${parseFloat(order.gratuity).toFixed(2)}</span>
          </div>
        )}

        {checkoutView && (
          <>
            <div className="gratuity-form">
              <form onSubmit={addGratuity}>
                <label htmlFor="gratuity">Add a tip for your driver ($)</label>
                <input
                  type="text"
                  name="gratuity"
                  value={gratuity ? gratuity : ""}
                  onChange={(e) => onGratuityChange(e)}
                />
                <input type="submit" value="Apply" />
              </form>
            </div>

            <div className="promo-code-form">
              <p>{specialError}</p>

              <form onSubmit={checkPromoCode}>
                <label htmlFor="code">Promo Code</label>
                <input
                  type="text"
                  name="promoCode"
                  onChange={(e) => onPromoCodeChange(e)}
                  value={promoCode}
                />
                <input type="submit" value="Apply" />
              </form>
            </div>
          </>
        )}

        {pricing && (
          <div className="order-total">
            <span>Order Total:</span>
            <span>${pricing.grandTotal.toFixed(2)}</span>
          </div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
    newOrder: state.orders.newOrder,
    specials: state.specials.specials,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(OrderSummary);
