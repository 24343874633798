import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { updateComforter } from "../../../actions/settings";

function ComforterForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateComforter(formData, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="product comforter-product">
        <input
          type="hidden"
          name="id"
          ref={register}
          defaultValue={props.product._id}
        />

        <div className="comforter-options">
          <div className="comforter-option">
            <p>{props.product.item}</p>
            <input
              type="text"
              name="notDown"
              placeholder="Not Down"
              ref={register}
              defaultValue={props.product.options.notDown}
            />
            <input
              type="text"
              name="down"
              placeholder="Down"
              ref={register}
              defaultValue={props.product.options.down}
            />
          </div>
        </div>
      </div>

      <div className="action-button-container">
        <input className="submit-button update" type="submit" value="Update" />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(ComforterForm);
