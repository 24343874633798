import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

// Form 
import DatePicker from "react-datepicker";
import { useForm, Controller } from 'react-hook-form';

import { updateSpecial, deleteSpecial } from '../../../../actions/specials';

function Special(props) {

    const [editMode, setEditMode] = useState(false);

    const [name, setName] = useState(props.special.name);
    const [code, setCode] = useState(props.special.code);
    const [expirationDate, setExpirationDate] = useState(props.special.expirationDate);
    const [expirationDateString, setExpirationDateString] = useState(null);
    const [firstTimeOnly, setFirstTimeOnly] = useState(props.special.firstTimeOnly);

    const [percentDiscount, setPercentDiscount] = useState(props.special.percentDiscount);
    const [discountAmount, setDiscountAmount] = useState(props.special.discountAmount);
    const [promotionalPrice, setPromotionalPrice] = useState(props.special.promotionalPrice);
    const [services, setServices] = useState(props.special.services[0]);
    const [tailoringItems, setTailoringItems] = useState(props.special.tailoringItems ? props.special.tailoringItems[0] : null);
    const [dryCleanItems, setDryCleanItems] = useState(props.special.dryCleanItems ? props.special.dryCleanItems[0] : null);

    let tailoringItemOptions = [
        { label: "Dress", value: "dress" },
        { label: "Pants", value: "pants" },
        { label: "Jumpsuit", value: "jumpsuit" },
        { label: "Shirt", value: "shirt" },
        { label: "Jacket", value: "jacket" },
        { label: "Coat", value: "coat" },
        { label: "Curtains", value: "curtains" },
    ];


    let dryCleanItemOptions = [
        { label: "Dress", value: "dress" },
        { label: "Gown", value: "gown" },
        { label: "Shirt", value: "shirt" },
        { label: "Jumpsuit", value: "jumpsuit" },
        { label: "Pants", value: "pants" },
        { label: "Skirt", value: "skirt" },
        { label: "Suit", value: "suit" },
        { label: "Blazer", value: "blazer" },
        { label: "Outer Jacket", value: "outerjacket" },
        { label: "Sport Jacket", value: "sportjacket" },
        { label: "Sweater", value: "sweater" },
        { label: "Coat", value: "coat" },
        { label: "Blanket", value: "blanket" },
        { label: "Comforter", value: "comforter" }
    ];

    useEffect(() => {
        if (props.special) {
            let expiration = new Date(props.special.expirationDate);
            let eMonth = expiration.getMonth() + 1;
            let eDate = expiration.getUTCDate();
            setExpirationDateString(`${eMonth}-${eDate}`);
        }
    }, [props.special, expirationDate]);


    const { register, handleSubmit, errors, control, watch } = useForm({
        mode: "onChange",
        defaultValues: {
            expirationDate: expirationDate ? new Date(expirationDate) : null,
            firstTimeOnly: firstTimeOnly ? { label: "Yes", value: true } : { label: "No", value: false }
        }
    });

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    const handleCancel = () => {
        if (props.special && editMode) {
            setEditMode(false)
        } if (!editMode) {
            props.goBackToList();
        } else {
            props.handleCancelEditSpecial();
        }
    }

    const selectedExpirationDate = watch('expirationDate');
    let servicesAddingTailoring = watch("services.tailoring");
    let servicesAddingDryClean = watch("services.dryClean");

    const handleDeleteSpecial = () => {
        props.dispatch(deleteSpecial(props.special._id, () => {
            props.goBackToList();
        }));
    }

    // Dispatch action 
    const onSubmit = (formData) => {
        formData.firstTimeOnly = formData.firstTimeOnly.value;

        formData.expirationDate = new Date(selectedExpirationDate);

        props.dispatch(updateSpecial(props.special._id, formData, () => {
            setEditMode(false);
            setName(formData.name);
            setCode(formData.code);
            setExpirationDate(formData.expirationDate);
            setFirstTimeOnly(formData.firstTimeOnly);
            setPercentDiscount(formData.percentDiscount);
            setDiscountAmount(formData.discountAmount);
            setPromotionalPrice(formData.promotionalPrice);
            setServices(formData.services);
            setTailoringItems(formData.tailoringItems);
            setDryCleanItems(formData.dryCleanItems);

            let newExpiration = new Date(formData.expirationDate);
            let newMonth = newExpiration.getMonth() + 1;
            let newDate = newExpiration.getUTCDate();
            setExpirationDateString(`${newMonth}-${newDate}`);
        }));
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-panel-container">

                <div className="action-links-container">
                    {!editMode && props.special && <button className="edit-button" onClick={() => setEditMode(true)}>Edit</button>}
                    <button className="cancel-button" onClick={() => handleCancel()}>Cancel</button>
                    {props.special && <button className="delete-button" onClick={() => handleDeleteSpecial()}>Delete</button>}

                </div>

                <div id="app-special">
                    <form className="special-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="name">Name</label>
                                {!editMode && props.special &&
                                    <p className="current-value">{name}</p>}
                                {editMode && <input type="text" name="name" ref={register} defaultValue={name} />}
                            </div>
                            <div className="form-input">
                                <label htmlFor="code">Code</label>
                                {!editMode && props.special &&
                                    <p className="current-value">{code}</p>}
                                {editMode &&
                                    <input type="text" name="code" ref={register} defaultValue={code} />
                                }
                            </div>


                            <div className="select-field">
                                <label htmlFor="firstTimeOnly">First-Time Customers Only?</label>
                                {!editMode && props.special &&
                                    <p className="current-value">{firstTimeOnly ? "Yes" : "No"}</p>}
                                {editMode &&
                                    <Controller
                                        as={<Select
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: true
                                                },
                                                {
                                                    label: "No",
                                                    value: false
                                                }
                                            ]}
                                            placeholder="Select One"
                                        />
                                        }
                                        control={control}
                                        name="firstTimeOnly"
                                        rules={{ required: false, validate: value => value !== "Select One" }}

                                    />
                                }
                            </div>
                        </div>
                        <div className="form-row discount-type">
                            <p>Discount Type:</p>
                        </div>

                        <div className="form-row">

                            <div className="form-input">
                                <label htmlFor="percentDiscount">Percent Discount (%)</label>
                                <p className="form-input-info">Percent off each selected service</p>
                                {!editMode && props.special &&
                                    <p className="current-value">{percentDiscount}</p>}
                                {editMode &&
                                    <input type="text" name="percentDiscount" ref={register} defaultValue={percentDiscount} />
                                }
                            </div>



                            <div className="form-input">
                                <label htmlFor="promotionalPrice">Promotional Price ($)</label>
                                <p className="form-input-info">Applied per item in selected services</p>
                                {!editMode && props.special &&
                                    <p className="current-value">{promotionalPrice}</p>}
                                {editMode &&
                                    <input name="promotionalPrice" pattern="^\d*(\.\d{0,2})?$" ref={register} defaultValue={promotionalPrice} />
                                    // <input type="number" name="promotionalPrice" ref={register} defaultValue={promotionalPrice} />
                                }
                            </div>



                            <div className="form-input">
                                <label htmlFor="discountAmount">Discount Amount ($)</label>
                                <p className="form-input-info">Applied once per selected service</p>
                                {!editMode && props.special &&
                                    <p className="current-value">{discountAmount}</p>}
                                {editMode &&
                                    <input name="discountAmount" pattern="^\d*(\.\d{0,2})?$" ref={register} defaultValue={discountAmount} />
                                    // <input type="number" name="discountAmount" ref={register} defaultValue={discountAmount} />
                                }
                            </div>


                        </div>

                        <div className="datepicker-field">
                            <label htmlFor="expirationDate">Expiration Date</label>
                            {!editMode && props.special &&
                                <p className="current-value">{expirationDateString}</p>}
                            {editMode &&
                                <Controller
                                    as={<DatePicker
                                        selected={selectedExpirationDate}
                                        name="expirationDate"
                                        minDate={today}

                                    />}
                                    control={control}
                                    name="expirationDate"
                                    placeholderText="Select a Date"
                                />
                            }
                        </div>


                        <div className="form-row">
                            <div className="form-input">
                                <label htmlFor="services">Applicable Services</label>
                                <div className="form-checkbox-group">

                                    {!editMode && services.washFold &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Wash & Fold</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.washFold}
                                                type="checkbox"
                                                name="services.washFold"
                                                ref={register}
                                            />
                                            <div htmlFor="services.washFold">Wash & Fold</div>

                                        </div>
                                    }

                                    {!editMode && services.organicWashFold &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Organic Wash & Fold</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.organicWashFold}
                                                type="checkbox"
                                                name="services.organicWashFold"
                                                ref={register}
                                            />
                                            <div htmlFor="services.organicWashFold">Organic Wash & Fold</div>
                                        </div>
                                    }

                                    {!editMode && services.washPress &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Wash & Press</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.washPress}
                                                type="checkbox"
                                                name="services.washPress"
                                                ref={register}
                                            />
                                            <div htmlFor="services.washPress">Wash & Press</div>
                                        </div>
                                    }

                                    {!editMode && services.dryClean &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Dry Clean</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.dryClean}
                                                type="checkbox"
                                                name="services.dryClean"
                                                ref={register}
                                            />
                                            <div htmlFor="services.dryClean">Dry Clean</div>
                                        </div>
                                    }

                                    {!editMode && services.tailoring &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Tailoring</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.tailoring}
                                                type="checkbox"
                                                name="services.tailoring"
                                                ref={register}
                                            />
                                            <div htmlFor="services.tailoring">Tailoring</div>
                                        </div>
                                    }


                                    {!editMode && services.comforter &&
                                        <div className="specials-service-item">
                                            <p className="current-value">Comforter</p>
                                        </div>
                                    }
                                    {editMode &&
                                        <div className="specials-service-item">
                                            <input
                                                defaultChecked={services.comforter}
                                                type="checkbox"
                                                name="services.comforter"
                                                ref={register}
                                            />
                                            <div htmlFor="services.comforter">Comforter</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                        {services && (services.tailoring || servicesAddingTailoring) &&
                            <div className="form-row">
                                <div className="form-input">

                                    <label htmlFor="items">Applicable Tailoring Items</label>
                                    <div className="form-checkbox-group">
                                        {tailoringItemOptions.map(item => {
                                            if (!editMode && tailoringItems && tailoringItems[item.value]) {
                                                return (
                                                    <div key={item.value} className="specials-service-item">
                                                        <p className="current-value">{item.label}</p>
                                                    </div>
                                                )
                                            } else if (editMode) {

                                                let isChecked = false;
                                                if (tailoringItems && tailoringItems[item.value]) {
                                                    isChecked = true;
                                                }

                                                return (
                                                    <div key={item.value} className="specials-service-item">
                                                        <input
                                                            defaultChecked={isChecked}
                                                            type="checkbox"
                                                            name={`tailoringItems[${item.value}]`}
                                                            ref={register}
                                                        />
                                                        <div htmlFor={`tailoringItems[${item.value}]`}>{item.label}</div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {services && (services.dryClean || servicesAddingDryClean) &&
                            <div className="form-row">
                                <div className="form-input">

                                    <label htmlFor="items">Applicable Dry Clean Items</label>
                                    <div className="form-checkbox-group">
                                        {dryCleanItemOptions.map(item => {
                                            if (!editMode && dryCleanItems && dryCleanItems[item.value]) {
                                                return (
                                                    <div key={item.value} className="specials-service-item">
                                                        <p className="current-value">{item.label}</p>
                                                    </div>
                                                )
                                            } else if (editMode) {


                                                let isChecked = false;
                                                if (dryCleanItems && dryCleanItems[item.value]) {
                                                    isChecked = true;
                                                }


                                                return (
                                                    <div key={item.value} className="specials-service-item">
                                                        <input
                                                            defaultChecked={isChecked}
                                                            type="checkbox"
                                                            name={`dryCleanItems[${item.value}]`}
                                                            ref={register}
                                                        />
                                                        <div htmlFor={`dryCleanItems[${item.value}]`}>{item.label}</div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        }


                        {editMode &&
                            <div className="action-button-container">
                                <input className="submit-button bg-green" type="submit" value="Save" />
                            </div>
                        }

                    </form>

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        specials: state.specials
    }
}


export default connect(mapStateToProps)(Special);