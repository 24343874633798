import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTh, faList, faSortUp, faSortDown, faSort, faSearch } from '@fortawesome/free-solid-svg-icons'
import { connect, useDispatch } from 'react-redux';

import Sidebar from "../../global/components/Sidebar/Sidebar";
import User from "./components/User/User";
import NewUser from "./components/NewUser/NewUser";

import { getUsers, searchUsers } from '../../actions/users';

library.add([faTh, faList, faSortUp, faSortDown, faSort, faSearch]);

function AppUsers(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.auth && !props.auth.authenticated) {
            history.push("/login");
        }
    }, [props.auth]);

    const [newUserForm, setNewUserForm] = useState(false);
    const [sortBy, setSortBy] = useState({ key: 'firstName', desc: false });
    const [limit, setLimit] = useState(75);
    const [skip, setSkip] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        if (props.auth.authenticated) {
            dispatch(getUsers(sortBy, limit, skip));
        }
    }, [dispatch, sortBy, skip, limit]);

    useEffect(() => {
        if (props.users && props.users.length > 74) {
            setShowMoreButton(true);
        }
    }, [props.users]);

    const showMore = () => {
        setLimit(limit + 75);
    }

    const handleSort = (column) => {
        // If already sorting by this column, flip the order, else default to descending
        if (sortBy.key === column) {
            setSortBy({ key: column, desc: !sortBy.desc });
        } else {
            setSortBy({ key: column, desc: true });
        }
    }

    const handleSelectUser = (user) => {
        setCurrentUser(user);
    }

    const handleCancelEditUser = () => {
        setCurrentUser(null);
        setNewUserForm(false);
    }

    const handleSearchInput = (e) => {
        setSearchText(e.target.value);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        props.dispatch(searchUsers(sortBy, searchText));
    }

    // Sorting is handled by passing sortBy argument to getUsers
    const handleSearchClear = () => {
        setSearchText("");
        props.dispatch(getUsers(sortBy, limit, skip));
    }

    return (
        <div className="dashboard-container">
            <Sidebar />
            {props.users &&
                <div className="dashboard-panel-container">
                    <div id="app-users" className="dashboard-panel">
                        <div className="dashboard-button-container">
                            {!newUserForm && <button onClick={() => setNewUserForm(true)} className="button-link">Add a User</button>}

                            {newUserForm && <NewUser handleCancelEditUser={handleCancelEditUser} />}

                            {currentUser && <User handleCancelEditUser={handleCancelEditUser} user={currentUser} setCurrentUser={setCurrentUser} />}

                            {!currentUser && !newUserForm &&
                                <div id="search-form">
                                    <form className="search-input" noValidate autoComplete="off" onSubmit={handleSearch}>
                                        <input onChange={handleSearchInput} value={searchText} type="text" name="search" />
                                        <button className="search-input-button" type="submit"><FontAwesomeIcon icon="search" /></button>
                                        <span className="search-clear" onClick={handleSearchClear}>Clear</span>
                                    </form>
                                </div>
                            }

                        </div>

                        {!currentUser && !newUserForm && props.auth.user &&
                            <div className="dashboard-table">
                                <ul id="user-table-rows" className="dashboard-table-rows">
                                    <li id="user-table-header" className="dashboard-table-header row">
                                        <div className="dashboard-table-column">First Name <FontAwesomeIcon onClick={(e) => handleSort("firstName")} icon={sortBy.key === 'firstName' && sortBy.desc === true ? faSortDown : sortBy.key === 'firstName' && sortBy.desc === false ? faSortUp : faSort} /></div>
                                        <div className="dashboard-table-column">Last Name <FontAwesomeIcon onClick={(e) => handleSort("lastName")} icon={sortBy.key === 'lastName' && sortBy.desc === true ? faSortDown : sortBy.key === 'lastName' && sortBy.desc === false ? faSortUp : faSort} /></div>
                                        <div className="dashboard-table-column">Role <FontAwesomeIcon onClick={(e) => handleSort("role")} icon={sortBy.key === 'role' && sortBy.desc === true ? faSortDown : sortBy.key === 'role' && sortBy.desc === false ? faSortUp : faSort} /></div>
                                        <div className="dashboard-table-column">Location <FontAwesomeIcon onClick={(e) => handleSort("locationName")} icon={sortBy.key === 'locationName' && sortBy.desc === true ? faSortDown : sortBy.key === 'locationName' && sortBy.desc === false ? faSortUp : faSort} /></div>
                                    </li>

                                    {
                                        props.users.map(user => {
                                            return (
                                                <li className="row" key={user._id}>
                                                    <div className="dashboard-table-column">
                                                        <p onClick={() => handleSelectUser(user)}>{user.firstName}</p>
                                                    </div>
                                                    <div className="dashboard-table-column">{user.lastName}</div>
                                                    <div className="dashboard-table-column">{user.role}</div>
                                                    <div className="dashboard-table-column">{user.locationName}</div>
                                                </li>
                                            );
                                        })
                                    }
                                    {showMoreButton && <li className="row show-more-row"><button onClick={() => showMore()}>Show More</button></li>}
                                </ul>
                            </div>
                        }

                    </div>
                </div>
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        users: state.users.users
    }
}

export default connect(mapStateToProps)(AppUsers);
