import axios from "axios";
import {
  GET_SPECIALS,
  GET_SPECIAL,
  CREATE_SPECIAL,
  UPDATE_SPECIAL,
  DELETE_SPECIAL,
  SPECIAL_ERROR,
  CLEAR_SPECIAL_ERROR,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getSpecials = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;
  return (dispatch) => {
    return axios
      .get(
        `/api/specials?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSpecialsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(specialError(err.response.data.message));
        } else {
          dispatch(specialError("There was an error getting specials."));
        }
      });
  };
};

export const getSpecial = (specialId) => {
  return (dispatch) => {
    return axios
      .get(`/api/special/${specialId}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSpecialSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(specialError(err.response.data.message));
        } else {
          dispatch(specialError("There was an error getting specials."));
        }
      });
  };
};

export const updateSpecial = (specialId, formData, callback) => {
  return (dispatch) => {
    return axios
      .put(`/api/special/${specialId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateSpecialSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(specialError(err.response.data.message));
        } else {
          dispatch(specialError("There was an error getting specials."));
        }
      });
  };
};

export const createSpecial = (formData, callback) => {
  return (dispatch) => {
    formData.firstTimeOnly = formData.firstTimeOnly.value;

    return axios
      .post(`/api/admin/special`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSpecialError());
        dispatch(createSpecialSuccess(data));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(specialError(err.response.data.message));
        } else {
          dispatch(specialError("Error creating special."));
        }
      });
  };
};

export const searchSpecials = (sortBy, searchText) => {
  if (searchText) {
    let order = sortBy.desc === true ? -1 : 1;
    return (dispatch) => {
      return axios
        .get(
          `/api/specials/search?sortby=${sortBy.key}&sortorder=${order}&searchtext=${searchText}`
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(getSpecialsSuccess(data));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(specialError(err.response.data.message));
          } else {
            dispatch(specialError("There was an error getting specials."));
          }
        });
    };
  }
};

// Delete special
export const deleteSpecial = (specialId, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/special/${specialId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearSpecialError());
        dispatch(deleteSpecialSuccess(data));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(specialError(err.response.data.message));
        } else {
          dispatch(specialError("Error deleting special."));
        }
      });
  };
};

export const getSpecialsSuccess = (data) => ({
  type: GET_SPECIALS,
  specials: data,
});

export const getSpecialSuccess = (data) => ({
  type: GET_SPECIAL,
  special: data,
});

export const updateSpecialSuccess = (data) => ({
  type: UPDATE_SPECIAL,
  special: data,
});

export const createSpecialSuccess = (data) => ({
  type: CREATE_SPECIAL,
  special: data,
});

export const deleteSpecialSuccess = (data) => ({
  type: DELETE_SPECIAL,
  special: data,
});

export function specialError(error) {
  return {
    type: SPECIAL_ERROR,
    payload: error,
  };
}

export function clearSpecialError() {
  return {
    type: CLEAR_SPECIAL_ERROR,
  };
}
