import React from "react";

import "./Triangle.scss";

export default function Triangle() {
  return (
    <div id="triangle">
      <img src="/images/triangle-green-gradient.png" alt="" />
    </div>
  );
}
