import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidebar from "../../global/components/Sidebar/Sidebar";
import Special from "./components/Special/Special";
import NewSpecial from "./components/NewSpecial/NewSpecial";

import { getSpecials, searchSpecials } from "../../actions/specials";

function AppSpecials(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.auth && !props.auth.authenticated) {
      history.push("/login");
    }
  }, [props.auth]);

  const [newSpecialForm, setNewSpecialForm] = useState(false);
  const [sortBy, setSortBy] = useState({ key: "address", desc: false });
  const [limit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [currentSpecial, setCurrentSpecial] = useState(null);

  useEffect(() => {
    if (props.auth.authenticated) {
      dispatch(getSpecials(sortBy, limit, skip));
    }
  }, [dispatch, sortBy, limit, skip]);

  const handleSelectSpecial = (special) => {
    setCurrentSpecial(special);
  };

  const goBackToList = () => {
    setCurrentSpecial(null);
  };

  const handleCancelEditSpecial = () => {
    setCurrentSpecial(null);
    setNewSpecialForm(false);
  };

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props.dispatch(searchSpecials(sortBy, searchText));
  };

  const handleSearchClear = () => {
    setSearchText("");
    props.dispatch(getSpecials(sortBy, limit, skip));
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-panel-container">
        {props.error && (
          <div className="error-banner">
            <p>{props.error}</p>
          </div>
        )}

        <div className="dashboard-button-container">
          {!newSpecialForm && !currentSpecial && (
            <button
              onClick={() => setNewSpecialForm(true)}
              className="button-link"
            >
              Add a Special
            </button>
          )}

          {newSpecialForm && (
            <NewSpecial handleCancelEditSpecial={handleCancelEditSpecial} />
          )}

          {currentSpecial && (
            <Special
              goBackToList={goBackToList}
              handleCancelEditSpecial={handleCancelEditSpecial}
              special={currentSpecial}
            />
          )}

          {!currentSpecial && !newSpecialForm && (
            <div id="search-form">
              <form
                className="search-input"
                noValidate
                autoComplete="off"
                onSubmit={handleSearch}
              >
                <input
                  onChange={handleSearchInput}
                  value={searchText}
                  type="text"
                  name="search"
                />
                <button className="search-input-button" type="submit">
                  <FontAwesomeIcon icon="search" />
                </button>
                <span className="search-clear" onClick={handleSearchClear}>
                  Clear
                </span>
              </form>
            </div>
          )}
        </div>

        {!currentSpecial && !newSpecialForm && (
          <div id="app-specials" className="dashboard-panel">
            <div className="dashboard-table">
              <ul id="special-table-rows" className="dashboard-table-rows">
                <li
                  id="special-table-header"
                  className="dashboard-table-header row"
                >
                  <div className="dashboard-table-column">Name</div>
                  <div className="dashboard-table-column">Code</div>
                  <div className="dashboard-table-column">Expires</div>
                  <div className="dashboard-table-column">Discount Amt</div>
                  <div className="dashboard-table-column">% off</div>
                  <div className="dashboard-table-column">
                    Promotional Price
                  </div>
                  <div className="dashboard-table-column">First-Time Only?</div>
                  <div className="dashboard-table-column">
                    Applicable Services
                  </div>
                </li>

                {props.specials.specials &&
                  props.specials.specials.map((special) => {
                    let expiration = new Date(special.expirationDate);
                    let eMonth = expiration.getMonth() + 1;
                    let eDate = expiration.getUTCDate();
                    let expirationDateString = `${eMonth}-${eDate}`;
                    let promotionalPriceString, discountAmountString;

                    if (special.promotionalPrice) {
                      promotionalPriceString =
                        "$" + parseFloat(special.promotionalPrice).toFixed(2);
                    }

                    if (special.discountAmount) {
                      discountAmountString =
                        "$" + parseFloat(special.discountAmount).toFixed(2);
                    }

                    return (
                      <li className="row" key={special.code}>
                        <div className="dashboard-table-column">
                          <p onClick={() => handleSelectSpecial(special)}>
                            {special.name}
                          </p>
                        </div>
                        <div className="dashboard-table-column">
                          {special.code}
                        </div>
                        <div className="dashboard-table-column">
                          {expirationDateString}
                        </div>
                        <div className="dashboard-table-column">
                          {discountAmountString}
                        </div>
                        <div className="dashboard-table-column">
                          {special.percentDiscount}
                        </div>
                        <div className="dashboard-table-column">
                          {promotionalPriceString}
                        </div>
                        <div className="dashboard-table-column">
                          {special.firstTimeOnly ? "Yes" : "No"}
                        </div>
                        <div className="dashboard-table-column">
                          {special.services[0].washFold ? (
                            <p>Wash & Fold</p>
                          ) : (
                            <p></p>
                          )}
                          {special.services[0].organicWashFold ? (
                            <p>Organic Wash & Fold</p>
                          ) : (
                            <p></p>
                          )}
                          {special.services[0].washPress ? (
                            <p>Wash & Press</p>
                          ) : (
                            <p></p>
                          )}
                          {special.services[0].dryClean ? (
                            <p>Dry Clean</p>
                          ) : (
                            <p></p>
                          )}
                          {special.services[0].tailoring ? (
                            <p>Tailoring</p>
                          ) : (
                            <p></p>
                          )}
                          {special.services[0].comforter ? (
                            <p>Comforter</p>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    specials: state.specials,
    error: state.specials.error,
  };
};

export default connect(mapStateToProps)(AppSpecials);
