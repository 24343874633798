import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import LocationsImages from "../../global/components/LocationsImages/LocationsImages";
import Triangle from "../../global/components/Triangle/Triangle";

import "./Contact.scss";

function Contact(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div
      id="contact"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <Triangle />

      <div className="contact-content">
        <div className="contact-info-container">
          <div className="contact-info">
            <h2 className="green-text">Contact us.</h2>
            <p>For general questions, please call us at (212) 928-WASH.</p>
            <p>
              We are New York City’s largest chain and most trusted laundry
              service. With 28 locations across the city, our big, spacious and
              comfortable laundromats are New Yorkers’ favorite!
            </p>
            <div className="button-container">
              <NavLink to="/locations">
                <button>Locations & Hours</button>
              </NavLink>
            </div>

            <p>
              For questions about custom tailoring or pricing, please visit our
              pricing page.
            </p>

            <div className="button-container">
              <NavLink to="/pricing">
                <button>Services & Prices</button>
              </NavLink>
            </div>

            <p>
              For questions about our business services and corporate accounts,
              please visit our Business Services page.
            </p>

            <div className="button-container">
              <NavLink to="/business-services">
                <button>Business Services</button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="image-container">
          <img
            className="phone"
            src="/images/contact-eco-phone.png
          "
            alt="phone"
          />
        </div>
      </div>

      <LocationsImages />

      <div className="green-banner">
        <h2>Your time is priceless. Let us do your laundry.</h2>
      </div>
    </div>
  );
}

export default Contact;
