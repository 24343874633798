import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MaskedInput from "react-input-mask";
import { useHistory } from "react-router-dom";
import { useForm, Controller, ErrorMessage } from "react-hook-form";

import { createUser } from "../../../../actions/users";

function NewCustomer(props) {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    formData.role = "Customer";
    props.dispatch(
      createUser(formData, () => {
        props.handleCancelEditCustomer();
      })
    );
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-panel-container">
        <div id="app-user">
          <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-input">
                <label htmlFor="firstName">First Name</label>
                <input type="text" name="firstName" ref={register} />
              </div>

              <div className="form-input">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" name="lastName" ref={register} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  ref={register({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Please enter a valid email address.",
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="email">
                  {({ message }) => (
                    <p className="form-error-message">{message}</p>
                  )}
                </ErrorMessage>
              </div>

              <div className="form-input">
                <label htmlFor="phone">Phone</label>
                <Controller
                  as={
                    <MaskedInput
                      mask="(999) 999-9999"
                      maskplaceholder={"_"}
                      alwaysShowMask
                    ></MaskedInput>
                  }
                  name="phone"
                  control={control}
                />
              </div>

              <div className="form-input">
                <label htmlFor="password">Temporary Password</label>
                <input type="password" name="password" ref={register} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="address">Address</label>

                <input type="text" name="address" ref={register} />
              </div>

              <div className="form-input">
                <label htmlFor="addressLine2">Address Line 2</label>
                <input type="text" name="addressLine2" ref={register} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="city">City</label>

                <input type="text" name="city" ref={register} />
              </div>

              <div className="form-input">
                <label htmlFor="state">State</label>

                <input
                  defaultValue="NY"
                  type="text"
                  name="state"
                  ref={register}
                />
              </div>

              <div className="form-input">
                <label htmlFor="zip">Zip</label>

                <input type="text" name="zip" ref={register} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-input checkbox-input">
                <input type="checkbox" name="sms" ref={register} />
                <label htmlFor="sms">
                  Send text notifications with updates about orders.
                </label>
              </div>
            </div>

            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(NewCustomer);
