import axios from "axios";
import {
  GET_PAYMENT_METHODS,
  REFUND_PAYMENT,
  PAYMENT_ERROR,
  CLEAR_PAYMENT_ERROR,
  SETUP_INTENT,
  PAYMENT_INTENT,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getPaymentMethods = () => {
  return (dispatch) => {
    return axios
      .get(`/api/payment/methods`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearPaymentError());
        dispatch({ type: GET_PAYMENT_METHODS, cards: data });
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(paymentError("There was an error getting payment methods."));
        }
      });
  };
};

export const capturePayment = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/payment/capture`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then(() => {
        dispatch(clearPaymentError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(
            paymentError("There was an error getting capturing the payment.")
          );
        }
      });
  };
};

export const fetchPaymentIntent = ({ user, amount, order }) => {
  const body = {
    user: user || null,
    customer: user?.stripeId ? user?.stripeId : null,
    amount,
    receipt_email: order.address.email,
    description: order.orderId,
  };

  return (dispatch) => {
    return axios
      .post(`/api/payment/intent`, body, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(paymentIntentSuccess(data.clientSecret));
        dispatch(clearPaymentError());
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(
            paymentError("There was an error getting a payment intent.")
          );
        }
      });
  };
};

export const fetchSetupSecret = (user) => {
  return (dispatch) => {
    return axios
      .post(`/api/payment/setupintent`, user, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(setupIntentSuccess(data.client_secret));
        dispatch(clearPaymentError());
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(paymentError("There was an error getting a setup secret."));
        }
      });
  };
};

// Called from views/AppOrderDetail/components/CapturePaymentForm
export const requestReauth = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/payment/requestreauth`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then(() => {
        dispatch(clearPaymentError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(
            paymentError("There was an error requesting reauthorization.")
          );
        }
      });
  };
};

export const refundPayment = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/payment/refund`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          dispatch({ type: REFUND_PAYMENT, order: data });
          dispatch(clearPaymentError());

          if (callback) {
            callback();
          }
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(paymentError(err.response.data.message));
        } else {
          dispatch(paymentError("There was an error refunding payment."));
        }
      });
  };
};

export const paymentIntentSuccess = (data) => ({
  type: PAYMENT_INTENT,
  intent: data,
});

export const setupIntentSuccess = (data) => ({
  type: SETUP_INTENT,
  secret: data,
});

export function paymentError(error) {
  return {
    type: PAYMENT_ERROR,
    payload: error,
  };
}

export function clearPaymentError() {
  return {
    type: CLEAR_PAYMENT_ERROR,
  };
}
