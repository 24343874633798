import {
  GET_USER,
  GET_USERS,
  UPDATE_USER,
  CREATE_USER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  RESET_USER_STATE,
  EXPORT_CUSTOMERS,
  UNAUTHORIZE_USER,
  USER_ERROR,
  CLEAR_USER_ERROR,
} from "../actions/types";

export default (state = { users: [], error: null }, action) => {
  switch (action.type) {
    case UNAUTHORIZE_USER:
      return {
        users: [],
      };
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.user],
        newUser: action.user,
      };
    case GET_USER:
      return { ...state, user: action.user };

    case UPDATE_USER:
      let userIndex = state.users.findIndex(
        (user) => user.email === action.user.email
      );

      let updatedUsers = state.users.map((item, index) => {
        if (index !== userIndex) {
          return item;
        }

        return action.user;
      });
      return { ...state, users: updatedUsers, user: action.user };
    case EXPORT_CUSTOMERS:
      return { ...state, csv: action.csv };

    case UPDATE_USER:
      return { ...state, user: action.user };
    case UPDATE_CUSTOMER:
      return { ...state, newCustomer: action.customer };
    case GET_USERS:
      return { ...state, users: action.users };
    case GET_CUSTOMERS:
      return { ...state, customers: action.customers };
    case RESET_USER_STATE:
      return {};
    case USER_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_USER_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
