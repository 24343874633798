import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { updateDisableOrdering } from "../../../actions/settings_florida";

function DisableOrderingForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateDisableOrdering(formData, () => {
        props.close();
      })
    );
  };

  return (
    <form
      className="disable-ordering-section"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-row">
        <div className="form-input">
          <label>Ordering Disabled</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.disableOrdering
                ? "Yes, ordering is disabled."
                : "We are currently open for orders."}
            </p>
          )}

          {props.editMode && (
            <>
              <input
                defaultChecked={props.settings.disableOrdering}
                type="checkbox"
                name="disableOrdering"
                ref={register}
              />
              <label htmlFor="disableOrdering"></label>
            </>
          )}
        </div>

        <div className="form-input">
          <label>Message (Reason for Closing and When Open)</label>
          {!props.editMode && (
            <p className="current-value">
              {props.settings.disableOrderingMessage}
            </p>
          )}

          {props.editMode && (
            <input
              type="text"
              name="disableOrderingMessage"
              ref={register}
              defaultValue={props.settings.disableOrderingMessage}
            />
          )}
        </div>
      </div>

      {props.editMode && (
        <div className="action-button-container">
          <input
            className="submit-button update"
            type="submit"
            value="Update"
          />
        </div>
      )}
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(DisableOrderingForm);
