import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

library.add([faTrash, faPlus]);

function DryClean({ checkoutView, remove, order }) {
  return (
    <li className="order-item">
      <div className="item-name">
        <div className="service-name">
          {!checkoutView && (
            <button onClick={() => remove()}>
              <FontAwesomeIcon icon="trash" />
            </button>
          )}{" "}
          <NavLink to={`/app/order/services/dryclean`} className="order-label">
            Dry Clean
          </NavLink>
        </div>
        <div className="service-quote">
          ${parseFloat(order.quote).toFixed(2)}
        </div>
      </div>
      <div className="item-details">
        {order.items &&
          order.items.map((item) => {
            // If edit order is coming from an existing order
            if (typeof item.quantity === "number") {
              let starch = null;
              if (item.starch) {
                if (item.starch === "lightStarch") {
                  starch = "Light Starch";
                } else if (item.starch === "heavyStarch") {
                  starch = "Heavy Starch";
                }
              }

              return (
                <div>
                  <p>
                    <span>
                      {item.type} x{item.quantity}
                    </span>
                    {(item.pressOnly || item.crease || starch) && (
                      <>
                        ({item.pressOnly && <span>Press Only</span>}
                        {item.crease && <span>Crease</span>}
                        {starch && <span>{starch}</span>})
                      </>
                    )}
                  </p>
                </div>
              );

              // Order has been recently edited
            } else {
              let starch = null;
              if (item.starch && item.starch?.label) {
                if (item.starch.label !== "No Starch") {
                  starch = item.starch?.label;
                }
              }

              return (
                <div key={item.label}>
                  <p>
                    <span>
                      {item.label}{" "}
                      {item.quantity && <>x{item.quantity.value}</>}
                    </span>
                    {(item.press || item.crease || starch) && (
                      <>
                        ({item.press && <span>{item.press?.label}</span>}
                        {item.crease && <span>{item.crease?.label}</span>}
                        {starch && <span>{starch}</span>})
                      </>
                    )}
                  </p>
                </div>
              );
            }
          })}
      </div>
    </li>
  );
}

export default DryClean;
