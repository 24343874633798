import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { updateLocation, deleteLocation } from "../../../../actions/locations";

function Location(props) {
  const [editMode, setEditMode] = useState(false);

  const [address, setAddress] = useState(props.location.address);
  const [city, setCity] = useState(props.location.city);
  const [state, setState] = useState(props.location.state);
  const [zip, setZip] = useState(props.location.zip);
  const [phone, setPhone] = useState(props.location.phone);
  const [hours, setHours] = useState(props.location.hours);
  const [name, setName] = useState(props.location.name);
  const [latitude, setLatitude] = useState(props.location.lat);
  const [longitude, setLongitude] = useState(props.location.long);

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
  });

  const handleCancel = () => {
    if (props.location && editMode) {
      setEditMode(false);
    }
    if (!editMode) {
      props.goBackToList();
    } else {
      props.handleCancelEditLocation();
    }
  };

  const handleDeleteLocation = () => {
    props.dispatch(
      deleteLocation(props.location._id, () => {
        props.goBackToList();
      })
    );
  };

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateLocation(props.location._id, formData, () => {
        setEditMode(false);

        setAddress(formData.address);
        setCity(formData.city);
        setState(formData.state);
        setZip(formData.zip);
        setPhone(formData.phone);
        setHours(formData.hours);
        setName(formData.name);
        setLatitude(formData.lat);
        setLongitude(formData.long);
      })
    );
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-panel-container">
        <div className="action-links-container">
          {!editMode && props.location && (
            <button className="edit-button" onClick={() => setEditMode(true)}>
              Edit
            </button>
          )}
          <button className="cancel-button" onClick={() => handleCancel()}>
            Cancel
          </button>
          {props.location && (
            <button
              className="delete-button"
              onClick={() => handleDeleteLocation()}
            >
              Delete
            </button>
          )}
        </div>

        <div id="app-location">
          <form className="location-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-input">
                <label htmlFor="name">Name</label>
                {!editMode && props.location && (
                  <p className="current-value">{name}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="name"
                    ref={register}
                    defaultValue={name}
                  />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="address">Address</label>
                {!editMode && props.location && (
                  <p className="current-value">{address}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="address"
                    ref={register}
                    defaultValue={address}
                  />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="city">City</label>
                {!editMode && props.location && (
                  <p className="current-value">{city}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="city"
                    ref={register}
                    defaultValue={city}
                  />
                )}
              </div>

              <div className="form-input">
                <label htmlFor="state">State</label>
                {!editMode && props.location && (
                  <p className="current-value">{state}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="state"
                    ref={register}
                    defaultValue={state}
                  />
                )}
              </div>

              <div className="form-input">
                <label htmlFor="zip">Zip</label>
                {!editMode && props.location && (
                  <p className="current-value">{zip}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="zip"
                    ref={register}
                    defaultValue={zip}
                  />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="phone">Phone</label>
                {!editMode && props.location && (
                  <p className="current-value">{phone}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="phone"
                    ref={register}
                    defaultValue={phone}
                  />
                )}
              </div>

              <div className="form-input">
                <label htmlFor="hours">Hours</label>
                {!editMode && props.location && (
                  <p className="current-value">{hours}</p>
                )}
                {editMode && props.location && (
                  <input
                    type="text"
                    name="hours"
                    ref={register}
                    defaultValue={hours}
                  />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-input">
                <label htmlFor="lat">Latitude</label>
                {!editMode && props.location && (
                  <p className="current-value">{latitude}</p>
                )}

                {editMode && props.location && (
                  <input
                    type="text"
                    name="lat"
                    ref={register}
                    defaultValue={latitude}
                  />
                )}
              </div>

              <div className="form-input">
                <label htmlFor="long">Longitude</label>
                {!editMode && props.location && (
                  <p className="current-value">{longitude}</p>
                )}

                {editMode && props.location && (
                  <input
                    type="text"
                    name="long"
                    ref={register}
                    defaultValue={longitude}
                  />
                )}
              </div>
            </div>

            {editMode && (
              <div className="action-button-container">
                <input
                  className="submit-button bg-green"
                  type="submit"
                  value="Save"
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
  };
};

export default connect(mapStateToProps)(Location);
