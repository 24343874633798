import axios from "axios";
import { clearPaymentError } from "./payment";

import {
  GET_USER,
  AUTHORIZE_USER,
  UNAUTHORIZE_USER,
  AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
  VERIFY_EMAIL,
  RESET_LOCAL_ORDER_STATE,
  RESET_EDIT_ORDER_STATE,
  UPDATE_ACCOUNT,
  AUTHORIZE_GUEST,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const login = ({ email, password }, callback) => {
  return (dispatch) => {
    return axios
      .post("/api/login", { email, password })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        localStorage.setItem("token", data.token);
        dispatch(clearPaymentError());
        dispatch({ type: RESET_EDIT_ORDER_STATE });
        dispatch({ type: RESET_LOCAL_ORDER_STATE });
        dispatch({ type: AUTHORIZE_USER, user: data.user });
        callback();

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Incorrect email or password."));
        }
      });
  };
};

// Gets user for oauth
export const getCurrentUser = (callback) => {
  return (dispatch) => {
    return axios
      .get(`/api/currentuser`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch({
          type: AUTHORIZE_USER,
          user: data,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("There was an error getting customer details."));
        }
      });
  };
};

export const signup = ({ email, password }, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/signup`, { email, password })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        localStorage.setItem("token", data.token);
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch({ type: AUTHORIZE_USER, user: data.user });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError("Sorry, there was an error creating your account.")
          );
        }
      });
  };
};

export const forgotPassword = ({ email }, callback) => {
  return function(dispatch) {
    return axios
      .post(`/api/forgotpassword`, { email })
      .then((response) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError("There was an error requesting a password reset.")
          );
        }
      });
  };
};

export const checkToken = (token, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/resetpassword/checktoken`, token, {
        headers: { Authorization: "JWT " + token },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("There was a user token error."));
        }
      });
  };
};

export const resetPassword = ({ password, token }, callback) => {
  return (dispatch) => {
    return axios
      .post(
        `/api/resetpassword`,
        { password },
        {
          headers: { Authorization: "JWT " + token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("There was an error resetting this password."));
        }
      });
  };
};

export const verifyEmail = ({ email, token }, callback) => {
  let tokenPattern = /(\w)*\\(?!\\)(\w)*\\(?!\\)(\w)*(?!\\)/g;

  return (dispatch) => {
    if (!tokenPattern.test(token)) {
      dispatch({
        type: AUTH_ERROR,
        payload: "Sorry, this verification link is not valid.",
      });
    }

    return axios
      .post(
        `/api/verifyemail`,
        { email },
        {
          headers: { Authorization: "JWT " + token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch({ type: UNAUTHORIZE_USER });
        dispatch({
          type: VERIFY_EMAIL,
          message: "Thank you for verifying your email address!",
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Email verification error."));
        }
      });
  };
};

export const updateAccount = (userId, formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/user/${userId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch(updateAccountSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("There was an error updating this account."));
        }
      });
  };
};

export const deleteAccount = (userId, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/user/${userId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Error deleting account."));
        }
      });
  };
};

export const disconnectGoogle = (userId) => {
  return (dispatch) => {
    return axios
      .post(`/api/user/${userId}/disconnectgoogle`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch(updateAccountSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Error disconnecting Google."));
        }
      });
  };
};

export const disconnectFacebook = (userId) => {
  return (dispatch) => {
    return axios
      .post(`/api/user/${userId}/disconnectfacebook`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        dispatch(updateAccountSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Error disconnecting Facebook."));
        }
      });
  };
};

export const authorizeGuest = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/authorizeguest`, formData)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        dispatch(clearAuthMessage());
        localStorage.setItem("token", data.token);
        dispatch(authorizeGuestSuccess(data.authorized));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("Error authorizing guest."));
        }
      });
  };
};

export function authorizeGuestSuccess(data) {
  return {
    type: AUTHORIZE_GUEST,
    authorized: data,
  };
}

export function getUserSuccess(data) {
  return {
    type: GET_USER,
    user: data,
  };
}

export function unauthorizeUser(callback) {
  localStorage.removeItem("token");

  return {
    type: UNAUTHORIZE_USER,
  };
}

export function authMessage(message) {
  return {
    type: AUTH_MESSAGE,
    payload: message,
  };
}

export function clearAuthMessage() {
  return {
    type: CLEAR_AUTH_MESSAGE,
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}

export function updateAccountSuccess(data) {
  return {
    type: UPDATE_ACCOUNT,
    user: data,
  };
}
