import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

library.add([faTrash, faPlus]);

function Tailoring({ checkoutView, remove, order }) {
  return (
    <li className="order-item">
      <div className="item-name">
        <div className="service-name">
          {!checkoutView && (
            <button onClick={() => remove()}>
              <FontAwesomeIcon icon="trash" />
            </button>
          )}
          <NavLink to={`/app/order/services/tailoring`} className="order-label">
            Tailoring
          </NavLink>
        </div>
        <div className="service-quote">
          ${parseFloat(order.quote).toFixed(2)}
        </div>
      </div>
      <div className="item-details">
        {order.items.map((item, index) => {
          return (
            <div>
              <p>
                <span>{item.label ? item.label : item.type}</span>
                <>
                  {order.services &&
                    order.services[index].map((service) => {
                      return (
                        <span>
                          {service.charAt(0).toUpperCase() + service.slice(1)}
                        </span>
                      );
                    })}
                </>
              </p>
              <p>{order.instructions ? order.instructions[index] : ""}</p>
            </div>
          );
        })}
      </div>
    </li>
  );
}

export default Tailoring;
