import axios from "axios";
import {
  GET_SETTINGS_FLORIDA,
  SETTINGS_ERROR_FLORIDA,
  CLEAR_SETTINGS_ERROR_FLORIDA,
  ADD_PRODUCT_FLORIDA,
  ADD_SERVICE_FLORIDA,
  UPDATE_SETTINGS_FLORIDA,
  UPDATE_PRODUCT_FLORIDA,
  UPDATE_SERVICE_FLORIDA,
  DELETE_SERVICE_FLORIDA,
  DELETE_PRODUCT_FLORIDA,
  SET_FLORIDA_STATUS,
} from "./types";
import config from "../config/keys";

// Get all specials
export const setFloridaStatus = (status) => ({
  type: SET_FLORIDA_STATUS,
  payload: status,
});

export const getSettingsFlorida = () => {
  return (dispatch) => {
    return axios
      .get(`/api/settings_florida`)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          //  dispatch(getSettingsError(response.data.error));
        } else {
          dispatch(getSettingsSuccess(response.data));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_FLORIDA,
  payload: data,
  florida: true,
});

export const updateDisableOrdering = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/disableordering`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateTimeWindows = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/timewindows`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateMinDelivery = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/mindelivery`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateMinDeliveryDays = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/mindeliverydays`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateWashFoldSettings = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/washfoldsettings`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateOrganicWashFoldSettings = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/organicwashfoldsettings`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateSettingsSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateProduct = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/product`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateProductSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addProduct = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/product/new`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(addProductSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteProduct = (id, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/settings_florida/product/${id}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(deleteProductSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateWashPress = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/washpress`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addWashPress = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/washpress/new`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(addServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateDryClean = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/dryclean`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addDryClean = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/dryclean/new`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(addServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateTailoring = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/tailoring`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addTailoring = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/tailoring/new`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(addServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteService = (id, callback) => {
  return (dispatch) => {
    return axios
      .delete(`/api/settings_florida/service/${id}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log(response.data);
          dispatch(deleteServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateComforter = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`/api/settings_florida/comforter`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          dispatch(updateServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addProductSuccess = (data) => ({
  type: ADD_PRODUCT_FLORIDA,
  product: data,
  florida: true,
});

export const addServiceSuccess = (data) => ({
  type: ADD_SERVICE_FLORIDA,
  service: data,
  florida: true,
});

export const updateProductSuccess = (data) => ({
  type: UPDATE_PRODUCT_FLORIDA,
  product: data,
  florida: true,
});

export const updateServiceSuccess = (data) => ({
  type: UPDATE_SERVICE_FLORIDA,
  service: data,
  florida: true,
});

export const deleteServiceSuccess = (data) => ({
  type: DELETE_SERVICE_FLORIDA,
  service: data,
  florida: true,
});

export const deleteProductSuccess = (data) => ({
  type: DELETE_PRODUCT_FLORIDA,
  productId: data,
  florida: true,
});

export const updateSettingsSuccess = (data) => ({
  type: UPDATE_SETTINGS_FLORIDA,
  settings: data,
  florida: true,
});

export const settingsError = (data) => ({
  type: SETTINGS_ERROR_FLORIDA,
  payload: data,
  florida: true,
});

export const clearSettingsError = (data) => ({
  type: CLEAR_SETTINGS_ERROR_FLORIDA,
  payload: data,
  florida: true,
});
