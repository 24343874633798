import React from "react";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";

import {
  updateDryClean,
  deleteService,
} from "../../../actions/settings_florida";

function DryCleanForm(props) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    props.dispatch(
      updateDryClean(formData, () => {
        props.close();
      })
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    props.dispatch(
      deleteService(props.product._id, () => {
        props.close();
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="product">
        <button className="delete" onClick={onDelete}>
          X
        </button>
        <input
          type="text"
          name="item"
          ref={register}
          defaultValue={props.product.item}
        />
        <input
          type="hidden"
          name="id"
          ref={register}
          defaultValue={props.product._id}
        />
        <div>
          <label>Price:</label>
          <input
            className="price-field"
            type="text"
            name="price"
            ref={register}
            defaultValue={props.product.options.base}
          />
        </div>
        <div>
          <label>Press Only:</label>
          <input
            className="price-field"
            type="text"
            name="pressOnly"
            ref={register}
            defaultValue={
              props.product.options.press
                ? props.product.options.press.pressOnly
                : ""
            }
          />
        </div>

        <div className="form-checkbox-group">
          <div className="option-item">
            <input
              defaultChecked={props.product.options.crease !== null}
              type="checkbox"
              name="options.crease"
              ref={register}
            />
            <div htmlFor="options.crease">Crease</div>
          </div>
          <div className="option-item">
            <input
              defaultChecked={props.product.options.starch !== null}
              type="checkbox"
              name="options.starch"
              ref={register}
            />
            <div htmlFor="options.starch">Starch</div>
          </div>
        </div>

        <button className="submit-button update" type="submit">
          Update
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locations: state.locations,
    users: state.users,
  };
};

export default connect(mapStateToProps)(DryCleanForm);
