import React from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import "./Footer.scss";

function Footer(props) {
  return (
    <div id="footer">
      <div className="footer-logo">
        <img src="/images/green-earth-organic-logo.png" />
      </div>

      <div className="footer-text">
        <div className="footer-left">
          {props.florida ? "Florida" : "New York City"} has never
          <br />
          been cleaner or greener.
        </div>
        <div className="footer-right">
          <div className="phone">
            (212) 928-WASH <span>(9274)</span>
          </div>
          <div>
            {props.locations && props.locations.locations && (
              <span>
                {props.locations.locations[0] &&
                  props.locations.locations[0].hours}{" "}
                -{" "}
              </span>
            )}{" "}
            7 days a week
          </div>
        </div>
      </div>

      <div id="footer-menu">
        <NavLink to="/pricing">
          <p>Services & Prices</p>
        </NavLink>
        <NavLink to="/specials">
          <p>Specials</p>
        </NavLink>
        <NavLink to="/locations">
          <p>Locations & Self Service</p>
        </NavLink>
        <NavLink to="/promise">
          <p>Our Promise</p>
        </NavLink>
        <NavLink to="/privacy-policy">
          <p>Privacy Policy</p>
        </NavLink>
      </div>

      <div className="footer-copyright">
        Copyright &#169; {new Date().getFullYear()} EMSG Inc.
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations,
    florida: state.settingsFlorida.floridaSession,
  };
};

export default connect(mapStateToProps)(Footer);
