import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-brands-svg-icons";

// Redux Actions
import { login, clearAuthError, getCurrentUser } from "../../actions/auth";
import { resetEditOrderState } from "../../actions/editOrder";

// Form
import { useForm, ErrorMessage } from "react-hook-form";

library.add(fab);

function Login(props) {
  // Get history object for passing as a prop for action redirects
  let history = useHistory();
  const dispatch = useDispatch();

  // Loading animation
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);

    if (props.auth && props.auth.authenticated) {
      history.push("/app/order");
      props.dispatch(resetEditOrderState());
    }
  }, []);

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  // Dispatch action
  const onSubmit = (formData) => {
    dispatch(login(formData, () => history.push("/app/dashboard")));
  };

  const clearError = () => {
    dispatch(clearAuthError());
  };

  return (
    <div
      id="login"
      className={`${loading ? "view-container loading" : "view-container"}`}
    >
      <h2>Log in to your account.</h2>

      <div id="continue-as-guest">
        <Link to="/app/order">Or continue as a Guest</Link>
      </div>

      <div className="social-login-buttons">
        <a id="google" className="social-login" href={`/api/google`}>
          <button>
            <span>
              <FontAwesomeIcon icon={["fab", "google"]} />
            </span>
            <span>Continue with Google</span>
          </button>
        </a>
      </div>

      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        {props.auth.message && <p className="message">{props.auth.message}</p>}
        {props.auth && props.auth.error && <p>{props.auth.error}</p>}
        <div className="form-line">
          <input
            name="email"
            placeholder="Email"
            ref={register({ required: "Please enter your email address." })}
          />
          <ErrorMessage errors={errors} name="email">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>
        <div className="form-line">
          <input
            type="password"
            name="password"
            placeholder="Password"
            ref={register}
          />
          <ErrorMessage errors={errors} name="password">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value="Log In"
          />
        </div>

        <div className="forgot-password-container">
          <Link to="/forgot-password" onClick={clearError}>
            Forgot password
          </Link>
        </div>
        <div className="signup-container">
          <Link to="/signup" onClick={clearError}>
            Don't have an account? Sign up
          </Link>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Login);
