import {
  SUBMIT_PAYMENT,
  GET_PAYMENT_METHODS,
  PAYMENT_ERROR,
  CLEAR_PAYMENT_ERROR,
  SETUP_INTENT,
  PAYMENT_INTENT,
} from "../actions/types";

export default function(state = { error: null, authenticated: false }, action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return { ...state, cards: action.cards };
    case SUBMIT_PAYMENT:
      return { ...state, authenticated: true, user: action.user };
    case CLEAR_PAYMENT_ERROR:
      return { ...state, error: null };
    case SETUP_INTENT:
      return { ...state, secret: action.secret };
    case PAYMENT_INTENT:
      return { ...state, intent: action.intent };
    case PAYMENT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
